import {Field, Form, Formik} from "formik";
import s from "../../../../Admin.module.css";
import Button from "@mui/material/Button";
import React, {useEffect,useState} from "react";
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import { getCountry} from "../../../../../../redux/location_Reducer";
import {
    getLocationsActivate,
    getLocationsBlock,
    getLocationsCreateUpdate,
    getLocationsView
} from "../../../../../../redux/adminGeo_Reducer";

import StatusSelect from "../../../../../../assets/common/StatusSelect/StatusSelect";
import ChangeLocationAssistant from "../../../../../../assets/common/ChangeLocationAssist/ChangeLocationAssistent";
import style from "../../../../Admin.module.css";


const GeoLocationCreate = () => {
    let params = useParams();
    let locationId = params.locationId;
    const dispatch = useDispatch();
    const [done, setDone] = useState(false);
const  location =useSelector((state)=>state.location );
const  directory =useSelector((state)=>state.directory );
const getLocationInfo = useSelector(()=>getLocationsView);
const getCountries = useSelector(()=>getCountry);
/*const getCityList = useSelector(()=>getCity);
const getRegionList = useSelector(()=>getRegion);*/
const getActive = useSelector(()=>getLocationsActivate);
const getBlock = useSelector(()=>getLocationsBlock);
const getCreate = useSelector(()=>getLocationsCreateUpdate);

  useEffect(() => {
    if(locationId){
      dispatch(getLocationInfo(locationId))
    }
    dispatch(getCountries())
  }, [locationId]);
  const locationsView =useSelector((state)=>state.geo.locationsView);
    //const region =location.region.find(r=>r.id ===+locationsView.region_id);
  let initialValues;
  if(locationId){
    initialValues= {
      name:locationsView?.name || "",
      slug:locationsView?.link || "",
      latitude:locationsView?.latitude || "",
      longitude:locationsView?.longitude || "",
      region_id:locationsView?.region_id || "",
      region_name:locationsView?.region_name || "",
      country_id:locationsView?.country_id || "",
      country_name:locationsView?.country_name || "",
      city_id:locationsView?.city_id || "",
      city_name:locationsView?.city_name || "",
      seo_title:locationsView?.seo_title || "",
      seo_description:locationsView?.seo_description || "",
      seo_image:locationsView?.seo_image || "",
      index_status:locationsView?.index_status || 1,
      // switcher_status: locationsView?.switcher_status || false,
    }
  }
  else {
    initialValues= {
      name: "",
        slug:"",
      latitude: "",
      longitude: "",
      region_id: "",
      city_id: "",
      seo_title: "",
      seo_description: "",
      seo_image: "",
      index_status: 1,
    }
  }
    return <div className={s.formContainer}>
        <div className={s.wrapper}>
            <h4>{locationId ? "Update" : "Create"} place</h4>
            <Formik enableReinitialize initialValues={initialValues}
                    onSubmit={(values, submitProps) => {
                            dispatch(getCreate(values, submitProps.setStatus, setDone,locationId));
                    }}>
                {({values, status, setFieldValue}) => {
                    if (done) {
                        return <div className={s.createComplete}>
                            Successful!
                        </div>
                    } else {
                        return (
                            <Form>
                                <div className={s.formContainer}>
                                  <div className={s.createBlocks}>
                                    <div className={s.serviceCreateItem}>
                                    <label>Place name
                                    {status && status.error.name &&
                                        <div className={s.error}>{status.error.name}</div>}
                                    <Field name={"name"} type="text" placeholder={"name"} value={values.name}/></label>
                                    </div>
                                    <div className={s.serviceCreateItem}>
                                        {locationId &&
                                      <label>Slug
                                        <Field disabled name={"slug"}  value={values.slug} /></label>}
                                    </div>
                                    <div className={s.serviceCreateItem}>
                                        {locationId &&
                                            <div className={s.serviceCreateItem}>
                                                <StatusSelect active={getActive} block={getBlock} elementInfo={locationsView}/>
                                            </div>
                                        }
                                    </div>
                                  </div>
                                  <div className={s.createBlocks}>
                                    <div className={s.serviceCreateItem}>
                                      <span>latitude</span>
                                      {status && status.error.latitude &&
                                          <div className={s.error}>{status.error.latitude}</div>}
                                      <Field name={"latitude"} type="text" placeholder={"latitude"}
                                             value={values.latitude}/>
                                    </div>
                                    <div className={s.serviceCreateItem}>
                                      <span>longitude</span>
                                      {status && status.error.longitude &&
                                          <div className={s.error}>{status.error.longitude}</div>}
                                      <Field name={"longitude"} type="text" placeholder={"longitude"}
                                             value={values.longitude}/>
                                    </div>
                                  </div>
                                    <div className={s.createBlocks}>
                                    <div className={style.serviceCreateItem}>
                                        <ChangeLocationAssistant status={status}
                                                                 setFieldValue={setFieldValue}
                                                                 city_id={values.city_id}
                                                                 city_name={values.city_name}
                                                                 region_id={values.region_id}
                                                                 region_name={values.region_name}
                                                                 country_id={values.country_id}
                                                                 country_name={values.country_name}
                                                                 isActivityLocations={false}
                                                                //  switcher_status={values.switcher_status}
                                        />
                                    </div>
                                    </div>
                                  <div className={s.createBlocks}>
                                    <div className={s.serviceCreateItem}>
                                      <span>Title</span>
                                      {status && status.error.seo_title &&
                                          <div className={s.error}>{status.error.seo_title}</div>}
                                      <Field name={"seo_title"} type="text" placeholder={"seo_title"}/>
                                    </div>
                                    <div className={s.serviceCreateItem}>
                                      <span>Description</span>
                                      {status && status.error.seo_description &&
                                          <div className={s.error}>{status.error.seo_description}</div>}
                                      <Field name={"seo_description"} type="text" placeholder={"seo_description"}/>
                                    </div>
                                  </div>
                                  <div className={s.createBlocks}>
                                    <div className={s.serviceCreateItem}>
                                      <span>Image</span>
                                      {status && status.error.seo_image &&
                                          <div className={s.error}>{status.error.seo_image}</div>}
                                      <input name="seo_image"
                                             type="file"
                                             accept=".png,.jpeg,.jpg"
                                             onChange={(event) => {
                                               setFieldValue("seo_image", event.currentTarget.files[0]);
                                             }}/>
                                    </div>
                                    <div className={style.serviceCreateItem}>
                                          {locationId &&
                                              <div className={style.serviceCreateItemImg}>
                                                  <img src={locationsView?.seo_image?.thumb} alt="thumb"/>
                                              </div>}
                                      </div>
                                  </div>
                                    <div className={s.createBlocks}>
                                    <div className={s.serviceCreateItem}>
                                      <span>Index Status</span>
                                      {status && status.error.index_status &&
                                          <div className={s.error}>{status.error.index_status}</div>}
                                      <select defaultValue={1} onChange={(event) => {
                                        setFieldValue("index_status", +event.currentTarget.value);
                                      }} name={"index_status"}>
                                        <option>Select status</option>
                                        {directory.indexStatus.map(i =>
                                            <option key={i.id} value={i.id}>{i.name}</option>)}
                                      </select>

                                  </div>
                                    </div>
                                  <div className={s.createBlocks}>
                                    <div className={s.serviceCreateItem}>
                                      <Button type={"submit"} size="small"
                                              sx={{width:"20%",
                                                backgroundColor:" #295673",
                                                color:" white"}}>
                                        {locationId?"Update":"Create"}</Button>
                                    </div>
                                  </div>
                                </div>
                            </Form>)
                    }
                }}
            </Formik>
        </div>
    </div>
}
export default GeoLocationCreate