import React, {useEffect, useState} from 'react';
import AdvertisingServiceCardAvailableList from './AdvertisingServiceCardAvailableList';
import AdvertisingActivityBottomAvailableList from './AdvertisingActivityBottomAvailableList';
import { useSelector, useDispatch } from 'react-redux'
import { getAdvertisingList, getDeleteAdvertising, getAllAdvertising, getAdvertisingListServiceCard, getAdvertisingListActivityButtom } from '../../../../redux/advertising_Reducer';
import s from '../../../../Profiles/Admin/Admin.module.css';
import { Button } from '@mui/material';
const AdvertisingAvailable = () => {
    const [isActiveTab, setIsActiveTab] = useState(true);
    const createNavLinkCardService = '/admin_cabinet/advertising/advertising_create/service_card'
    const createNavLinkActivityButtom = '/admin_cabinet/advertising/advertising_create/activity_bottom'
    const adminAdvertising = useSelector(state => state.adminAdvertising)
    const getAdvertising = useSelector(() => getAdvertisingList)
    const getAdvertisingCardService = useSelector(() => getAdvertisingListServiceCard)
    const getAdvertisingActivityButtom = useSelector(() => getAdvertisingListActivityButtom)
    const getDelete = useSelector(() => getDeleteAdvertising)
    const allAdvertising = useSelector(state => state.adminAdvertising.advertisingList)
    const allAdvertisingList = useSelector(state => state.adminAdvertising.allAdvertisingList)
    const getAllAdvertisingList = useSelector(() => getAllAdvertising)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllAdvertisingList())
      }, [allAdvertising.length])

    return (
        <div>
        <button className={`${s.buttonTab} ${isActiveTab ? s.buttonActive : s.buttonInactive}`} onClick={() => setIsActiveTab(true)}>
            269 X 445
        </button>
        <button className={`${s.buttonTab} ${!isActiveTab ? s.buttonActive : s.buttonInactive}`} onClick={() => setIsActiveTab(false)}>
            728 X 90
        </button>
            {isActiveTab && 
            <AdvertisingServiceCardAvailableList
                    rowsList={adminAdvertising.advertisingListServiceCard}
                    getServices={getAdvertisingCardService}
                    deleteButton={getDelete}
                    createNavLink={createNavLinkCardService}
                    pageTotalCount={adminAdvertising.adminAdvertisingMeta.pagination.total} //*please send total pages
                    type={'advertising'}
                    allSearchList={allAdvertisingList}
                    isShowSearchBlock={true}
                    typeBanner={"1"}
                    />
            }
            {!isActiveTab && 
            <AdvertisingActivityBottomAvailableList
                    rowsList={adminAdvertising.advertisingListActivityButtom}
                    getServices={getAdvertisingActivityButtom}
                    deleteButton={getDelete}
                    createNavLink={createNavLinkActivityButtom}
                    pageTotalCount={adminAdvertising.adminAdvertisingMeta.pagination.total} //*please send total pages
                    type={'advertising'}
                    allSearchList={allAdvertisingList}
                    isShowSearchBlock={true}
                    typeBanner={"2"}
                    />
            }
        </div>
    );
};

export default AdvertisingAvailable;
