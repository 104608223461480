import { useEffect } from 'react'
import AdminTableAvailable from "../../../../AdminTableAvailable";
import {useSelector, useDispatch} from "react-redux";
import {getRegionsDelete, getRegionsList, getAllRegions} from "../../../../../../redux/adminGeo_Reducer";

const GeoRegionsAvailable = () => {
  const dispatch = useDispatch()
  const editNavLink="/admin_cabinet/locations/regions_create";
  const createNavLink = "/admin_cabinet/locations/regions_create";

  const adminRegions = useSelector((state) =>state.geo.regionsList);
  const pagination = useSelector((state) =>state.geo.regionsListMeta.pagination);
  const getRegions = useSelector(() => getRegionsList);
  const getDeleteRegions = useSelector(() => getRegionsDelete);

  const allRegions = useSelector(state => state.geo.allRegionsList)
  const getAllRegionsList = useSelector(() => getAllRegions)

  useEffect(() => {
    dispatch(getAllRegionsList())
  }, [allRegions.length])
  return <div>
    <AdminTableAvailable rowsList={adminRegions}
                         current_page={pagination.current_page}
                         per_page={pagination.per_page}
                         pageTotalCount={pagination.total}
                         editNavLink={editNavLink}
                         deleteButton={getDeleteRegions}
                         createNavLink={createNavLink}
                         getServices={getRegions}
                         type={"locations"}
                         isShowSearchBlock={true}
                         allSearchList={allRegions}
    />
  </div>
}
export default GeoRegionsAvailable