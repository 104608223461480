import {partnerService} from "../api/apiPartner";


const SET_PARTNER_SERVICES_DATA = "tour2sky/partner/services/SET_SERVICES_DATA";
const SET_PARTNER_SERVICES_VIEW = "tour2sky/partner/services/SET_SERVICES_VIEW";




const initialState = {

    partnerServicesData: [
        {
            "id": 1,
            "user_id": 1,
            "name": "Concerts",
            "link": "concerts",
            "city_id": 1,
            "region_id": 1,
            "location_id": 1,
            "order_count": 12,
            "created_at": "2021-12-25 09:30:25",
            "images": {
                "data": [
                    {
                        "id": 1,
                        "name": "concert-1.jpeg",
                        "mime_type": "image/jpeg",
                        "link": "",
                        "thumb":""
                    }
                ]
            },
            "country": {
                "id": 1,
                "continent_id": 1,
                "name": "Ukraine",
                "link": "ukraine",
                "status": 1,
                "created_at": "2021-12-25 09:30:25"
            },
            "region": {
                "id": 1,
                "country_id": 1,
                "name": "Одесская область",
                "link": "district-odessa",
                "status": 1,
                "created_at": "2021-12-25 09:30:25"
            },
            "city": {
                "id": 1,
                "region_id": 1,
                "name": "Одесса",
                "link": "odessa",
                "status": 1,
                "seo_title": "Concerts",
                "seo_description": "Concerts",
                "index_status": 1,
                "seo_image": {
                    "id": 1,
                    "name": "concert-1.jpeg",
                    "mime_type": "image/jpeg",
                    "link": "",
                    "thumb": ""
                },
                "created_at": "2021-12-25 09:30:25"
            },
            "location": {
                "id": 1,
                "name": "Аркадия",
                "link": "arcadia",
                "latitude": 46.430869,
                "longitude": 30.761649,
                "region_id": 1,
                "city_id": 1,
                "status": 1,
                "seo_title": "Concerts",
                "seo_description": "Concerts",
                "index_status": 1,
                "seo_image": {
                    "id": 1,
                    "name": "concert-1.jpeg",
                    "mime_type": "image/jpeg",
                    "link": "",
                    "thumb": ""
                },
                "created_at": "2021-12-25 09:30:25"
            }
        }
    ],
    partnerServicesMeta:{pagination:{total:1,current_page:1}},
    partnerServicesView:{
        "id": 1,
        "user_id": 1,
        "name": "Concerts",
        "link": "concerts",
        "created_at": "2021-12-25 09:30:25",
        "price": 30,
        "duration": 120,
        "status": 1,
        "description": "Some service description",
        "rating": 4.5,
        "seo_title": "Concerts",
        "seo_description": "Concerts",
        "index_status": 1,
        "seo_image": {
            "id": 1,
            "name": "concert-1.jpeg",
            "mime_type": "image/jpeg",
            "link": "",
            "thumb": ""
        },
        "attributes": {
            "data": [
                {
                    "id": 1,
                    "service_id": 2,
                    "attribute_id": 3,
                    "name": "Wi-Fi",
                    "value": "exist",
                    "description": "Some description text"
                }
            ]
        },
        "categories": {
            "data": [
                {
                    "id": 1,
                    "service_id": 2,
                    "category_id": 3,
                    "name": "Concerts"
                }
            ]
        },
        "images": {
            "data": [
                {
                    "id": 1,
                    "name": "concert-1.jpeg",
                    "mime_type": "image/jpeg",
                    "link": "",
                    "thumb": ""
                }
            ]
        },
        "country": {
            "id": 1,
            "continent_id": 1,
            "name": "Ukraine",
            "link": "ukraine",
            "status": 1,
            "created_at": "2021-12-25 09:30:25"
        },
        "region": {
            "id": 1,
            "country_id": 1,
            "name": "Одесская область",
            "link": "district-odessa",
            "status": 1,
            "created_at": "2021-12-25 09:30:25"
        },
        "city": {
            "id": 1,
            "region_id": 1,
            "name": "Одесса",
            "link": "odessa",
            "status": 1,
            "seo_title": "Concerts",
            "seo_description": "Concerts",
            "index_status": 1,
            "seo_image": {
                "id": 1,
                "name": "concert-1.jpeg",
                "mime_type": "image/jpeg",
                "link": "",
                "thumb": ""
            },
            "created_at": "2021-12-25 09:30:25"
        },
        "location": {
            "id": 1,
            "name": "Аркадия",
            "link": "arcadia",
            "latitude": 46.430869,
            "longitude": 30.761649,
            "region_id": 1,
            "city_id": 1,
            "status": 1,
            "seo_title": "Concerts",
            "seo_description": "Concerts",
            "index_status": 1,
            "seo_image": {
                "id": 1,
                "name": "concert-1.jpeg",
                "mime_type": "image/jpeg",
                "link": "",
                "thumb": ""
            },
            "created_at": "2021-12-25 09:30:25"
        },
        "currency": {
            "id": 1,
            "name": "US Dollar",
            "code": "USD",
            "rate": 28.1,
            "status": 1
        }
    },
}


const partnerServices_Reducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_PARTNER_SERVICES_DATA: {
            return {
                ...state,
                partnerServicesData: action.data.data,
                }
            }
        case SET_PARTNER_SERVICES_VIEW: {
            return {
                partnerServicesView: action.data,
            }
        }
        default:
            return state
    }
}

export const setServicesData = (data) => ({type: SET_PARTNER_SERVICES_DATA, data});
export const setServicesView = (data) => ({type: SET_PARTNER_SERVICES_VIEW, data});




/*This is Thunk*/

export const getServicesList = (page) => async (dispatch) => {
    let response = await partnerService.getPartnerServices(page);
    dispatch(setServicesData(response.data));
}
export const getPartnerServicesView = (serviceId) => async (dispatch) => {
    let response = await partnerService.getPartnerServicesView (serviceId);
    dispatch(setServicesView(response.data));
}
export const getDeletePartnerServices = (serviceId) => async (dispatch) => {

    let response = await partnerService.getDelete(serviceId);
    dispatch(setServicesView(response.data));
}
export const getBlockPartnerServices = (serviceId) => async (dispatch) => {

    let response = await partnerService.getBlock(serviceId);
    dispatch(setServicesView(response.data));
}
export const getActivatePartnerServices = (serviceId) => async (dispatch) => {
    let response = await partnerService.getActivate(serviceId);
    dispatch(setServicesView(response.data));
}
export const getCreatePartnerServices = (formData) => async (dispatch) => {
    let response = await partnerService.getCreate(formData);
    dispatch(setServicesView(response.data));
}
export default partnerServices_Reducer