import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import {
	createBookingSoftware,
	getBookingSoftwareView,
} from '../../../../redux/booking-software.reducer'
import style from '../../Admin.module.css'

const BookingSoftwareCreate = () => {
	const params = useParams()
	console.log('params', params)
	let softwareId = params.softwareId
	const getBookingSoftware = useSelector(() => getBookingSoftwareView)
	// const bookingSoftwareList = useSelector(
	// 	state => state.bookingSoftware.bookingSoftwareList
	// )
	const bookingSoftwareView = useSelector(
		state => state.bookingSoftware.bookingSoftwareView
	)

	const [done, setDone] = useState(false)
	const dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(true)
	// const getBookingSoftware = useSelector(() => getBookingSoftwareList)

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true)
			if (softwareId) {
				await dispatch(getBookingSoftware(+softwareId))
			}
			setIsLoading(false)
		}
		fetchData()
	}, [softwareId, dispatch])

	let initialValues
	if (softwareId) {
		initialValues = {
			title: bookingSoftwareView.title || '',
			_method: 'PUT',
		}
	} else {
		initialValues = {
			title: '',
		}
	}

	console.log('bookingSoftwareView', bookingSoftwareView)

	if (isLoading) {
		return (
			<div className={style.loadingContainer}>
				<CircularProgress />
				<p>Loading...</p>
			</div>
		)
	}

	return (
		<div className={style.formContainer}>
			<h4>{softwareId ? 'Update' : 'Create'} booking software</h4>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={(values, submitProps) => {
					const formData = new FormData()
					for (let key in values) {
						formData.append(key, values[key])
					}
					console.log('formData', formData)
					console.log('values', values)
					dispatch(
						createBookingSoftware(
							formData,
							submitProps.setStatus,
							setDone,
							softwareId
						)
					)
				}}
			>
				{({ status, values, setFieldValue }) => {
					if (done) {
						return (
							<div className={style.createComplete}>
								{softwareId ? 'Update' : 'Create'} successful!
							</div>
						)
					} else {
						return (
							<Form>
								<div className={style.createBlocks}>
									<div className={style.serviceCreateItem}>
										<label>
											Name
											{status && status.error.title && (
												<div className={style.error}>{status.error.title}</div>
											)}
											<Field name={'title'} type='text' value={values.title} />
										</label>
									</div>
								</div>
								<div className={style.createBlocks}>
									<div className={style.serviceCreateItem}>
										<Button
											sx={{
												width: '20%',
												backgroundColor: ' #295673',
												color: ' white',
											}}
											type={'submit'}
											size='small'
										>
											{softwareId ? `Update` : `Create`}
										</Button>
									</div>
								</div>
							</Form>
						)
					}
				}}
			</Formik>
		</div>
	)
}
export default BookingSoftwareCreate
