import AdminTableAvailable from "../../../AdminTableAvailable";
import {useSelector} from "react-redux";
import {getCategoryDelete, getCategoryList} from "../../../../../redux/adminCategory_Reducer";






const AdminCategoryAvailable =() => {
    const createNavLink = "/admin_cabinet/category/category_create"
    const adminCategory = useSelector((state) =>state.adminCategory.adminCategoryData);
    const pagination = useSelector((state) =>state.adminCategory.adminCategoryMeta.pagination);
    const location = useSelector((state) =>state.location);
    const directory = useSelector((state) =>state.directory);
    const getCategory = useSelector(() => getCategoryList);
    const getDelete = useSelector(() => getCategoryDelete);
    return <div>
        <AdminTableAvailable rowsList={adminCategory}
                             current_page={pagination.current_page}
                             per_page={pagination.per_page}
                             pageTotalCount={pagination.total}
                             deleteButton={getDelete}
                             createNavLink={createNavLink}
                             location={location}
                             getServices={getCategory}
                             directory={directory}


        />
        </div>
}
export default AdminCategoryAvailable

