import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { createPartner } from '../../../../redux/adminUsers&Partners_Reducer'
import { createAffiliateProgram, getAffiliateProgramView } from '../../../../redux/affiliate-program'
import style from '../../Admin.module.css'

const AffiliateCreate = () => {
	const params = useParams()
	console.log('params', params)
	let affiliateId = params.affiliateId
	const getAffiliateProgram = useSelector(() => getAffiliateProgramView)
	// const bookingSoftwareList = useSelector(
	// 	state => state.bookingSoftware.bookingSoftwareList
	// )
	const affiliateView = useSelector(state => state.affiliate.affiliateView)

	const [done, setDone] = useState(false)
	const dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(true)
	// const getBookingSoftware = useSelector(() => getBookingSoftwareList)

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true)
			if (affiliateId) {
				await dispatch(getAffiliateProgram(+affiliateId))
			}
			setIsLoading(false)
		}
		fetchData()
	}, [affiliateId, dispatch])

	let initialValues
	if (affiliateId) {
		initialValues = {
			name: affiliateView.name || '',
			_method: 'PUT',
		}
	} else {
		initialValues = {
			name: '',
		}
	}

	console.log('affiliateView', affiliateView)

	if (isLoading) {
		return (
			<div className={style.loadingContainer}>
				<CircularProgress />
				<p>Loading...</p>
			</div>
		)
	}

	return (
		<div className={style.formContainer}>
			<h4>{affiliateId ? 'Update' : 'Create'} affiliate program</h4>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={(values, submitProps) => {
					const formData = new FormData()
					for (let key in values) {
						formData.append(key, values[key])
					}
					console.log('formData', formData)
					console.log('values', values)
					dispatch(
						createAffiliateProgram(formData, submitProps.setStatus, setDone, affiliateId)
					)
				}}
			>
				{({ status, values, setFieldValue }) => {
					if (done) {
						return (
							<div className={style.createComplete}>
								{affiliateId ? 'Update' : 'Create'} successful!
							</div>
						)
					} else {
						return (
							<Form>
								<div className={style.createBlocks}>
									<div className={style.serviceCreateItem}>
										<label>
											Name
											{status && status.error.name && (
												<div className={style.error}>{status.error.name}</div>
											)}
											<Field name={'name'} type='text' value={values.name} />
										</label>
									</div>
								</div>
								<div className={style.createBlocks}>
									<div className={style.serviceCreateItem}>
										<Button
											sx={{
												width: '20%',
												backgroundColor: ' #295673',
												color: ' white',
											}}
											type={'submit'}
											size='small'
										>
											{affiliateId ? `Update` : `Create`}
										</Button>
									</div>
								</div>
							</Form>
						)
					}
				}}
			</Formik>
		</div>
	)
}
export default AffiliateCreate
