import React from 'react';
import {useDispatch} from "react-redux";
import s from "../../../Profiles/Admin/Admin.module.css";
import Select from "react-select";

const StatusSelect = ({active,block,elementInfo}) => {
    const dispatch = useDispatch()
    const handleStatus = (e) =>{
        if(e.value===1){
           dispatch(active(elementInfo.id))
        } else if (e.value===0){
           dispatch(block(elementInfo.id))
        }
    }
    const statusSelectOptions = [
        {
            value:1,
            label:"Active"
        },{
            value:0,
            label:"Inactive"
        }
    ]
    return (
        <div className={s.serviceCreateItem}>
                <label>Status
                    <Select onChange={handleStatus} value={[{
                        value:elementInfo.status,
                        label:elementInfo.status_name,
                    }]} options={statusSelectOptions}/>
                    </label>
        </div>
    );
};

export default StatusSelect;