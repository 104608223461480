import React, {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import s from "../../../../Admin.module.css";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import {
    getCountriesActivate, getCountriesBlock,
    getCountriesCreateUpdate,
    getCountriesView
} from "../../../../../../redux/adminGeo_Reducer";
import {useParams} from "react-router";
import StatusSelect from "../../../../../../assets/common/StatusSelect/StatusSelect";


const GeoCountriesCreate = () => {
    let params = useParams();
    let countriesId = params.countryId;
    const dispatch = useDispatch();
    const getCreate = useSelector(() => getCountriesCreateUpdate);
    const [done, setDone] = useState(false);
    const getCountryView = useSelector(() => getCountriesView);
    const getActive = useSelector(() => getCountriesActivate);
    const getBlock = useSelector(() => getCountriesBlock);
    useEffect(() => {
        if (countriesId) {
            dispatch(getCountryView(countriesId))
        }
    }, [countriesId]);
    const countryView = useSelector((state) => state.geo.countriesView)
    let initialValues;
    if (countriesId) {
        initialValues = {
            name: countryView?.name || "",
            status: countryView?.status || "",

        }
    } else {
        initialValues = {
            name: "",
            status: "",
        }
    }

    return <div className={s.formContainer}>
        <div className={s.wrapper}>
            <h4>{countriesId ? "Update" : "Create"} country</h4>
            <Formik enableReinitialize initialValues={initialValues}
                    onSubmit={(values, submitProps) => {
                        dispatch(getCreate(values, submitProps.setStatus, setDone, countriesId));
                    }
                    }>
                {({values, status, setFieldValue}) => {
                    if (done) {
                        return <div className={s.createComplete}>
                            Successful!
                        </div>
                    } else {
                        return (
                            <Form>
                                <div className={s.formContainer}>
                                    <div className={s.createBlocks}>
                                        <div className={s.serviceCreateItem}>
                                            <label>Countries name
                                                {status && status.error.name &&
                                                    <div className={s.error}>{status.error.name}</div>}
                                                <Field value={values.name} name={"name"} type="text"
                                                       placeholder={"name"}/></label>
                                        </div>
                                        {countriesId && <>
                                        <div className={s.serviceCreateItem}>
                                            <label>Slug
                                                <Field name={"slug"} value={countryView?.link}/>
                                            </label>
                                        </div>
                                        <div className={s.serviceCreateItem}>
                                            <StatusSelect active={getActive} block={getBlock}
                                                          elementInfo={countryView}/>
                                        </div></>}
                                    </div>
                                    <div className={s.createBlocks}>
                                        <div className={s.serviceCreateItem}>
                                            <Button sx={{
                                                width: "20%",
                                                backgroundColor: " #295673",
                                                color: " white"
                                            }} type={"submit"} size="small">{countriesId ? "Update" : "Create"}</Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>)
                    }
                }}
            </Formik>
        </div>
    </div>
}
export default GeoCountriesCreate