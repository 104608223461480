import {adminAttribute} from "../api/apiAdmin";



const SET_ATTRIBUTES_DATA = "tour2sky/admin/attributes/SET_ATTRIBUTES_DATA";
const SET_ATTRIBUTES_VIEW = "tour2sky/admin/attributes/SET_ATTRIBUTES_VIEW";
const SET_DELETE_ATTRIBUTES = "tour2sky/admin/attributes/SET_DELETE_ATTRIBUTES";
const SET_LOADING_ON = "tour2sky/admin/attributes/SET_LOADING_ON";
const SET_LOADING_OFF = "tour2sky/admin/attributes/SET_LOADING_OFF";



const initialState = {

    attributeList:  [ {
        "id": 1,
        "name": "Wi-Fi",
        "status": "1",
        "status_name": "Active",
        "created_at": "2021-12-25 09:30:25"
    }],
    attributeListMeta:{pagination:{total:1,current_page:1,per_page:15}},
    attributeView:{},
    isDelete:false,
    isLoading:false,
}


const adminAttributes_Reducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_ATTRIBUTES_DATA: {

            return {
                ...state,
                attributeList:action.data.data,
                }
            }
        case SET_ATTRIBUTES_VIEW: {

            return {
                ...state,
                attributeView:action.data,
            }
        }
        case SET_DELETE_ATTRIBUTES: {

            return {
                ...state,
                attributeList:state.attributeList.filter(i=>i.id !==action.data)
            }
        }
        case SET_LOADING_ON: {
            return {
                ...state,
                isLoading:true,
            }
        }
        case SET_LOADING_OFF: {
            return {
                ...state,
                isLoading:false,
            }
        }
        default:
            return state
    }
}

export const setAttributesData = (data) => ({type: SET_ATTRIBUTES_DATA, data});
export const setAttributesView = (data) => ({type: SET_ATTRIBUTES_VIEW, data});
export const setDeleteAttributesData = (data) => ({type: SET_DELETE_ATTRIBUTES,data});
export const setIsLoadingOn = () => ({type: SET_LOADING_ON});
export const setIsLoadingOff = () => ({type: SET_LOADING_OFF});



/*This is Thunk*/

export const getAttributesList = (page) => async (dispatch) => {
    dispatch(setIsLoadingOn())
try {
    let response = await  adminAttribute.getList(page);
    dispatch(setAttributesData(response.data))
} catch (e) {

}
   dispatch(setIsLoadingOff())

}
export const getAttributesView = (categoryId) => async (dispatch) => {
    dispatch(setIsLoadingOn())
    try {
        let response = await  adminAttribute.getView(categoryId);
        dispatch(setAttributesView(response.data))
    } catch (e) {
    }
    dispatch(setIsLoadingOff())
}
export const getAttributesCreateUpdate = (categoryId,formData,setStatus,setDone) => async (dispatch) => {
    try {
        let response = await  adminAttribute.getCreateUpdate(categoryId,formData);
        dispatch(setAttributesView(response.data));
        setStatus(null);
        setDone(true);
    }
    catch (error) {
        let errors = error.response;
        if(errors.status===422){
            setStatus({error: errors.data.errors})}
    }
}
export const getAttributesActivate = (categoryId) => async (dispatch) => {
    let response = await  adminAttribute.getActivate(categoryId);
    dispatch(setAttributesView(response.data))
}
export const getAttributesBlock = (categoryId) => async (dispatch) => {
    let response = await  adminAttribute.getBlock(categoryId);
    dispatch(setAttributesView(response.data))
}
export const getAttributesDelete = (categoryId) => async (dispatch) => {
    try {
         await  adminAttribute.getDelete(categoryId);
        dispatch(setDeleteAttributesData(categoryId))
    } catch (error){
        console.log(error)
    }

}


export default adminAttributes_Reducer