import { adminService } from '../api/apiAdmin'

const SET_SERVICES_DATA = 'tour2sky/admin/services/SET_SERVICES_DATA'
const SET_SERVICES_VIEW = 'tour2sky/admin/services/SET_SERVICES_VIEW'
const SET_SERVICES_DELETE = 'tour2sky/admin/services/SET_SERVICES_DELETE'
const SET_ALL_SERVICES = 'tour2sky/admin/services/SET_ALL_SERVICES'
const SET_SERVICE_TYPES = 'tour2sky/admin/services/SET_SERVICE_TYPES'

const initialState = {
	adminServicesData: [
		{
			id: 1,
			user_id: 1,
			name: 'Concerts',
			link: 'concerts',
			created_at: '2021-12-25 09:30:25',
			status: 1,
			status_name: 'active',
		},
	],
	adminServicesMeta: { pagination: { total: 1, current_page: 1 } },
	adminServicesView: {
		id: '',
		user_id: 1,
		name: '',
		link: '',
		created_at: '',
		price: '',
		duration: '',
		status: '',
		description: '',
		rating: '',
		seo_title: '',
		seo_description: '',
		index_status: '',
		seo_image: {
			id: 1,
			name: 'concert-1.jpeg',
			mime_type: 'image/jpeg',
			link: '',
			thumb: '',
		},
		attributes: {
			data: [
				{
					id: 1,
					service_id: 2,
					attribute_id: 3,
					name: 'Wi-Fi',
					value: 'exist',
					description: 'Some description text',
				},
			],
		},
		categories: {
			data: [
				{
					id: 1,
					service_id: 2,
					category_id: 3,
					name: 'Concerts',
				},
			],
		},
		images: {
			data: [
				{
					id: 1,
					name: 'concert-1.jpeg',
					mime_type: 'image/jpeg',
					link: '',
					thumb: '',
				},
			],
		},
		country: {
			id: 1,
			continent_id: 1,
			name: 'Ukraine',
			link: 'ukraine',
			status: 1,
			created_at: '2021-12-25 09:30:25',
		},
		region: {
			id: 1,
			country_id: 1,
			name: 'Одесская область',
			link: 'district-odessa',
			status: 1,
			created_at: '2021-12-25 09:30:25',
		},
		city: {
			id: 1,
			region_id: 1,
			name: 'Одесса',
			link: 'odessa',
			status: 1,
			seo_title: 'Concerts',
			seo_description: 'Concerts',
			index_status: 1,
			seo_image: {
				id: 1,
				name: 'concert-1.jpeg',
				mime_type: 'image/jpeg',
				link: '',
				thumb: '',
			},
			created_at: '2021-12-25 09:30:25',
		},
		location: {
			id: 1,
			name: 'Аркадия',
			link: 'arcadia',
			latitude: 46.430869,
			longitude: 30.761649,
			region_id: 1,
			city_id: 1,
			status: 1,
			seo_title: 'Concerts',
			seo_description: 'Concerts',
			index_status: 1,
			seo_image: {
				id: 1,
				name: 'concert-1.jpeg',
				mime_type: 'image/jpeg',
				link: '',
				thumb: '',
			},
			created_at: '2021-12-25 09:30:25',
		},
		currency: {
			id: 1,
			name: 'US Dollar',
			code: 'USD',
			rate: 28.1,
			status: 1,
		},
		times: {
			data: [
				{
					id: 1,
					service_id: 2,
					date: '2022-01-31',
					time: '21:00:00',
					day_of_week: 4,
					day_of_month: 12,
					month: 3,
					status: 1,
				},
			],
		},
	},
	allServicesList: [],
	serviceTypes: [],
}

const adminServices_Reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_SERVICES_DATA: {
			return {
				...state,
				adminServicesData: action.data.data,
				adminServicesMeta: action.data.meta,
			}
		}
		case SET_SERVICES_VIEW: {
			return {
				...state,
				adminServicesView: action.data,
			}
		}
		case SET_SERVICES_DELETE: {
			return {
				...state,
				adminServicesData: state.adminServicesData.filter(
					item => item.id !== action.data
				),
			}
		}
		case SET_ALL_SERVICES: {
			return {
				...state,
				allServicesList: action.data,
			}
		}
		case SET_SERVICE_TYPES: {
			return {
				...state,
				serviceTypes: action.data,
			}
		}
		default:
			return state
	}
}

export const setServicesData = data => ({ type: SET_SERVICES_DATA, data })
export const setServicesView = data => ({ type: SET_SERVICES_VIEW, data })
export const setServicesDelete = data => ({ type: SET_SERVICES_DELETE, data })
export const setAllSrvices = data => ({ type: SET_ALL_SERVICES, data })
export const setServiceTypes = data => ({ type: SET_SERVICE_TYPES, data })

/*This is Thunk*/

export const getServicesList = (page, perPage, order, type, affiliateProgramName, partnerName) => async dispatch => {
	try {
		let response = await adminService.getAdminServices(page, perPage, order, type, affiliateProgramName, partnerName)
		dispatch(setServicesData(response.data))
	} catch (e) {
		console.log(e)
	}
}

export const getServicesView = serviceId => async dispatch => {
	let response = await adminService.getAdminServicesView(serviceId)

	dispatch(setServicesView({ ...response.data }))
}
export const getDeleteServices = serviceId => async dispatch => {
	try {
		await adminService.getDelete(serviceId)
		dispatch(setServicesDelete(serviceId))
	} catch (e) {}
}
export const getBlockServices = serviceId => async dispatch => {
	let response = await adminService.getBlock(serviceId)
	dispatch(setServicesView(response.data))
}
export const getActivateServices = serviceId => async dispatch => {
	let response = await adminService.getActivate(serviceId)
	dispatch(setServicesView(response.data))
}
export const getCreateUpdateServices =
	(formData, setStatus, setDone, serviceId) => async dispatch => {
		try {
			let response = await adminService.getCreateUpdate(formData, serviceId)
			dispatch(setServicesView(response.data))
			setStatus({})
			setDone(true)
		} catch (error) {
			let errors = error.response
			setStatus({ error: errors.data.errors })
		}
	}

export const getAllServices = () => async dispatch => {
	try {
		let response = await adminService.getAllServices()
		dispatch(setAllSrvices(response.data.data))
	} catch (error) {}
}

export const getAllServiceTypes = () => async dispatch => {
	let response = await adminService.getServiceTypes()
	dispatch(setServiceTypes(response.data.result))
}
export default adminServices_Reducer
