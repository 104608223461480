import Logo from './headerComponents/Logo'
import HeaderNavigation from './headerComponents/HeaderNavigation'
import HeaderAuthorization from './headerComponents/HeaderAuthorization'
import s from './Header.module.css'
import HeaderAutorized from './headerComponents/HeaderAutorized'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

const Header = () => {
  const isAuth = useSelector(state => state.profilePage.isAuth)

  useEffect(() => {

  }, [isAuth])

  return (
    <header id='myHeader' className=''>
      <div className='row header'>
        <div className='container'>
          <div className='row'>
            <div className={`${s.myHeader}`}>
              <Logo />
              {isAuth ? <HeaderAutorized /> : <HeaderAuthorization />}
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header
