import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { adminBookingSoftware } from '../../../../api/apiAdmin'
import {
	createPartner,
	getPartnerTours,
	getPartnerView,
} from '../../../../redux/adminUsers&Partners_Reducer'
import style from '../../Admin.module.css'

const PartnerCreate = () => {
	const params = useParams()
	console.log('params', params)
	let partnerId = params.itemId
	const getViewPartner = useSelector(() => getPartnerView)
	const getPartnerAllTours = useSelector(() => getPartnerTours)
	// const bookingSoftwareList = useSelector(
	// 	state => state.bookingSoftware.bookingSoftwareList
	// )
	const partnerTours = useSelector(state => state.admin.partnerTours)

	const partner = useSelector(state => state.admin.adminPartnerView)
	const [done, setDone] = useState(false)
	const dispatch = useDispatch()
	const [bookinglist, setBookinList] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const [originalLogo, setOriginalLogo] = useState(null)
	// const getBookingSoftware = useSelector(() => getBookingSoftwareList)

	const getBookingSoftwareList = async () => {
		try {
			let response = await adminBookingSoftware.getAdminUserBookingSoftware()
			console.log('response: ', response.data.data)
			setBookinList(response.data.data)
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true)
			if (partnerId) {
				await dispatch(getViewPartner(+partnerId))
				await dispatch(getPartnerAllTours(+partnerId))
			}
			await getBookingSoftwareList()
			setIsLoading(false)
		}
		fetchData()
	}, [partnerId, dispatch])

	useEffect(() => {
		getBookingSoftwareList()
	}, [dispatch])

	useEffect(() => {
		if (partner && partner.logo) {
			setOriginalLogo(partner.logo)
		}
	}, [partner])

	let initialValues
	if (partnerId) {
		initialValues = {
			name: partner.name || '',
			email: partner.email || '',
			phone: partner.phone || '',
			social_profiles: partner.social_profiles || '',
			booking_software_id: partner.booking_software_id || '',
			about: partner.about || '',
			logo: partner.logo,
			_method: 'PUT',
		}
	} else {
		initialValues = {
			name: '',
			email: '',
			phone: '',
			social_profiles: '',
			booking_software_id: '',
			about: '',
			logo: null,
		}
	}

	if (isLoading) {
		return (
			<div className={style.loadingContainer}>
				<CircularProgress />
				<p>Loading...</p>
			</div>
		)
	}

	return (
		<div className={style.formContainer}>
			<h4>{partnerId ? 'Update' : 'Create'} partner</h4>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={(values, submitProps) => {
					const formData = new FormData()
					for (let key in values) {
						if (key === 'logo') {
							if (values.logo instanceof File || values.logo !== originalLogo) {
								formData.append(key, values[key])
							}
						} else {
							formData.append(key, values[key])
						}
					}
					console.log('formData', formData)
					console.log('values', values)
					dispatch(
						createPartner(formData, submitProps.setStatus, setDone, partnerId)
					)
				}}
			>
				{({ status, values, setFieldValue }) => {
					if (done) {
						return (
							<div className={style.createComplete}>
								{partnerId ? 'Update' : 'Create'} successful!
							</div>
						)
					} else {
						return (
							<Form>
								<div className={style.createBlocks}>
									<div className={style.serviceCreateItem}>
										<label>
											Name
											{status && status.error.name && (
												<div className={style.error}>{status.error.name}</div>
											)}
											<Field name={'name'} type='text' value={values.name} />
										</label>
									</div>
									<div className={style.serviceCreateItem}>
										<label>
											Email
											{status && status.error.email && (
												<div className={style.error}>{status.error.email}</div>
											)}
											<Field name={'email'} type='email' value={values.email} />
										</label>
									</div>
									<div className={style.serviceCreateItem}>
										<label>
											Phone
											{status && status.error.phone && (
												<div className={style.error}>{status.error.phone}</div>
											)}
											<Field name={'phone'} type='text' value={values.phone} />
										</label>
									</div>
									<div className={style.serviceCreateItem}>
										<label>
											Website
											{status && status.error.social_profiles && (
												<div className={style.error}>
													{status.error.social_profiles}
												</div>
											)}
											<Field
												name={'social_profiles'}
												type='text'
												value={values.social_profiles}
											/>
										</label>
									</div>
									<div className={style.serviceCreateItem}>
										<label>
											Booking software
											{status && status.error.booking_software_id && (
												<div className={style.error}>
													{status.error.booking_software_id}
												</div>
											)}
											<Field
												as='select'
												name={'booking_software_id'}
												value={values.booking_software_id}
											>
												<option value='' label='Select booking software' />
												{bookinglist.map(software => (
													<option
														key={software.id}
														value={software.id}
														label={software.title}
													/>
												))}
											</Field>
										</label>
									</div>
									<div className={style.serviceCreateItem}>
										<label>
											Notes
											{status && status.error.about && (
												<div className={style.error}>{status.error.about}</div>
											)}
											<Field
												name={'about'}
												as='textarea'
												value={values.about}
											/>
										</label>
									</div>
									<div className={style.serviceCreateItem}>
										<label>
											Logo
											{status && status.error.logo && (
												<div className={style.error}>{status.error.logo}</div>
											)}
											<input
												name='logo'
												type='file'
												onChange={event => {
													const file = event.currentTarget.files[0]
													setFieldValue('logo', file)
												}}
											/>
										</label>
									</div>
									<div className={style.serviceCreateItem}>
										{partnerId && values.logo && (
											<div className={style.serviceCreateItemImg}>
												<img
													src={
														values.logo instanceof File
															? URL.createObjectURL(values.logo)
															: values.logo
													}
													alt='thumb'
												/>
											</div>
										)}
									</div>
									{partnerId && partnerTours.length ? (
										<div className={style.serviceCreateItem}>
											<h5>Partner tours</h5>
											{partnerTours.map((tour, i) => (
												<p>
													<Link
														to={`/admin_cabinet/service/service_create/${tour.id}`}
													>
														{i + 1}
														{'.'}
														{tour.name}
													</Link>
												</p>
											))}
										</div>
									) : null}
								</div>
								<div className={style.createBlocks}>
									<div className={style.serviceCreateItem}>
										<Button
											sx={{
												width: '20%',
												backgroundColor: ' #295673',
												color: ' white',
											}}
											type={'submit'}
											size='small'
										>
											{partnerId ? `Update` : `Create`}
										</Button>
									</div>
								</div>
							</Form>
						)
					}
				}}
			</Formik>
		</div>
	)
}
export default PartnerCreate
