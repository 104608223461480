import AdminTableAvailable from '../../../../AdminTableAvailable'
import { useSelector } from 'react-redux'
import {
  getCountriesDelete,
  getCountriesList,
} from '../../../../../../redux/adminGeo_Reducer'

const GeoCountriesAvailable = () => {
  const createNavLink = '/admin_cabinet/locations/countries_create'
  const adminCountries = useSelector(state => state.geo.countriesList)
  const pagination = useSelector(
    state => state.geo.countriesListMeta.pagination
  )
  const location = useSelector(state => state.location)
  const getCountry = useSelector(() => getCountriesList)
  const getDeleteCountry = useSelector(() => getCountriesDelete)

  return (
    <div>
      <AdminTableAvailable
        rowsList={adminCountries}
        current_page={pagination.current_page}
        per_page={pagination.per_page}
        pageTotalCount={pagination.total}
        deleteButton={getDeleteCountry}
        createNavLink={createNavLink}
        location={location}
        getServices={getCountry}
        type={'locations'}
      />
    </div>
  )
}
export default GeoCountriesAvailable
