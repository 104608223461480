import {productAPI} from "../api/api";



const SET_PRODUCT_ITEM_INFO = "tour2sky/productItemInfo/SET_PRODUCT_ITEM_INFO";
const SET_ERROR = "tour2sky/productItemInfo/SET_ERROR";


const initialState = {
        data:{},
        error:null,
};


const productItem_Reducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_PRODUCT_ITEM_INFO: {
            return {
                ...action.data
            }
        }
        case SET_ERROR: {
            return {
                ...state,
                error:action.data,
            }
        }
        default:
            return state
    }
}

export const setProductItemView = (data) => ({type: SET_PRODUCT_ITEM_INFO, data});
export const setError = (data) => ({type: SET_ERROR, data});


/*This is Thunk*/
export const getProductItemView = (itemId) => async (dispatch) => {
    try {
        let response = await productAPI.getServiceItemView(itemId)
            dispatch(setProductItemView(response.data))
        dispatch(setError(null))
    } catch (error) {
        let errors = error.response.status;
        dispatch(setError(errors))
    }
}
export const getProductItemViewLink = (itemLink) => async (dispatch) => {
    try {
        let response = await productAPI.getServiceItemViewLink(itemLink)
            dispatch(setProductItemView(response.data))
        dispatch(setError(null))
    } catch (error) {
        let errors = error.response.status;
        dispatch(setError(errors))
    }
}
export default productItem_Reducer