
import React, {useEffect} from "react";
import s from "./Dashboard.module.css"
import VerticalBarChart from "../../../../assets/common/Chart/VerticalBarChart";
import {useDispatch, useSelector} from "react-redux";
import {getCount_stat} from "../../../../redux/location_Reducer";



const Dashboard = () => {
    const dispatch = useDispatch();
    const getCounts = useSelector(()=>getCount_stat);
    const countsStat = useSelector((state)=>state.location.count_stat);

    useEffect(()=>{
        dispatch(getCounts())
    },[]);
return <div className={s.dashBoardContainer}>
    <div className={s.chartWrapper}>
        <VerticalBarChart  countsStat={countsStat} />
    </div>


</div>

}
export default Dashboard
