import React, { useState } from 'react'
import { CiMenuFries } from 'react-icons/ci'
import { IoIosCloseCircle } from 'react-icons/io'
import { Outlet } from 'react-router'
import { NavLink } from 'react-router-dom'
import '../../App.css'
import './Admin.css'
import s from './Admin.module.css'
const Admin = () => {
	const [isOpenAsideMenu, setIsOpenAsideMenu] = useState(false)
	return (
		<main className={s.adminMain}>
			<div className={s.adminContainer}>
				<div className={s.menuColumnLeftIconWrap}>
					<CiMenuFries
						onClick={() => setIsOpenAsideMenu(!isOpenAsideMenu)}
						className={s.menuColumnLeftIcon}
					/>
				</div>
				{isOpenAsideMenu && (
					<div className={s.menuBurgerColumnLeft}>
						<div className={s.menuBurgerLeftBlock}>
							<IoIosCloseCircle
								onClick={() => setIsOpenAsideMenu(!isOpenAsideMenu)}
								className={s.closeMenuBurgerIcon}
							/>
							<nav className={'adminPage'}>
								<ul>
									<li className={s.menuBurgerColumnLeft_Item}>
										<NavLink to='dashboard'>Dashboard</NavLink>
									</li>
									<li className={s.menuBurgerColumnLeft_Item}>
										<NavLink to='activity_location'>
											Activity in location
										</NavLink>
									</li>
									<li className={s.menuBurgerColumnLeft_Item}>
										<NavLink to='attributes'>Attributes</NavLink>
									</li>
									<li className={s.menuBurgerColumnLeft_Item}>
										<NavLink to='advertising'>Banners</NavLink>
									</li>
									<li className={s.menuBurgerColumnLeft_Item}>
										<NavLink to='blog'>Blog</NavLink>
									</li>
									<li className={s.menuBurgerColumnLeft_Item}>
										<NavLink to='category'>Category</NavLink>
									</li>
									<li className={s.menuBurgerColumnLeft_Item}>
										<NavLink to='criteria_evaluation'>
											Criteria for evaluation
										</NavLink>
									</li>
									<li className={s.menuBurgerColumnLeft_Item}>
										<NavLink to='feedback_rating'>Feedback/rating</NavLink>
									</li>
									<li className={s.menuBurgerColumnLeft_Item}>
										<NavLink to='letters'>Letters</NavLink>
									</li>
									<li className={s.menuBurgerColumnLeft_Item}>
										<NavLink to='locations'>Locations</NavLink>
									</li>
									<li className={s.menuBurgerColumnLeft_Item}>
										<NavLink to='orders/orders_available'>Orders</NavLink>
									</li>
									<li className={s.menuBurgerColumnLeft_Item}>
										<NavLink to='static_pages'>Pages</NavLink>
									</li>
									<li className={s.menuBurgerColumnLeft_Item}>
										<NavLink to='pages'>Pages SEO</NavLink>
									</li>
									<li className={s.menuBurgerColumnLeft_Item}>
										<NavLink to='partners/partner_view'>Partners</NavLink>
									</li>
									<li className={s.menuBurgerColumnLeft_Item}>
										<NavLink to='payment'>Payments</NavLink>
									</li>
									<li className={s.menuBurgerColumnLeft_Item}>
										<NavLink to='service'>Services</NavLink>
									</li>
									<li className={s.menuBurgerColumnLeft_Item}>
										<NavLink to='affiliate-programs-view'>
											Affiliate Programs
										</NavLink>
									</li>
									<li className={s.menuBurgerColumnLeft_Item}>
										<NavLink to='booking-software'>Booking Software</NavLink>
									</li>
									<li className={s.menuBurgerColumnLeft_Item}>
										<NavLink to='settings'>Settings</NavLink>
									</li>
									<li className={s.menuBurgerColumnLeft_Item}>
										<NavLink to='users'>Users</NavLink>
									</li>
								</ul>
							</nav>
						</div>
					</div>
				)}
				<div className={s.menuColumnLeft}>
					<div className={s.menuLeftNameItems}>
						<nav className={'adminPage'}>
							<ul>
								<li className={s.menuColumnLeft_Item}>
									<NavLink to='dashboard'>Dashboard</NavLink>
								</li>
								<li className={s.menuColumnLeft_Item}>
									<NavLink to='activity_location'>Activity in location</NavLink>
								</li>
								<li className={s.menuColumnLeft_Item}>
									<NavLink to='attributes'>Attributes</NavLink>
								</li>
								<li className={s.menuColumnLeft_Item}>
									<NavLink to='advertising'>Banners</NavLink>
								</li>
								<li className={s.menuColumnLeft_Item}>
									<NavLink to='blog'>Blog</NavLink>
								</li>
								<li className={s.menuColumnLeft_Item}>
									<NavLink to='category'>Category</NavLink>
								</li>
								<li className={s.menuColumnLeft_Item}>
									<NavLink to='criteria_evaluation'>
										Criteria for evaluation
									</NavLink>
								</li>
								<li className={s.menuColumnLeft_Item}>
									<NavLink to='feedback_rating'>Feedback/rating</NavLink>
								</li>
								<li className={s.menuColumnLeft_Item}>
									<NavLink to='letters'>Letters</NavLink>
								</li>
								<li className={s.menuColumnLeft_Item}>
									<NavLink to='locations'>Locations</NavLink>
								</li>
								<li className={s.menuColumnLeft_Item}>
									<NavLink to='orders/orders_available'>Orders</NavLink>
								</li>
								<li className={s.menuColumnLeft_Item}>
									<NavLink to='static_pages'>Pages</NavLink>
								</li>
								<li className={s.menuColumnLeft_Item}>
									<NavLink to='pages'>Pages SEO</NavLink>
								</li>
								<li className={s.menuColumnLeft_Item}>
									<NavLink to='partners/partner_view'>Partners</NavLink>
								</li>
								<li className={s.menuColumnLeft_Item}>
									<NavLink to='payment'>Payments</NavLink>
								</li>
								<li className={s.menuColumnLeft_Item}>
									<NavLink to='service'>Services</NavLink>
								</li>
								<li className={s.menuBurgerColumnLeft_Item}>
									<NavLink to='affiliate-programs-view'>Affiliate Programs</NavLink>
								</li>
								<li className={s.menuBurgerColumnLeft_Item}>
									<NavLink to='booking-software'>Booking Software</NavLink>
								</li>
								<li className={s.menuColumnLeft_Item}>
									<NavLink to='settings'>Settings</NavLink>
								</li>
								<li className={s.menuColumnLeft_Item}>
									<NavLink to='users'>Users</NavLink>
								</li>
							</ul>
						</nav>
					</div>
				</div>
				<div className={s.contentColumnRight}>
					<Outlet />
				</div>
			</div>
		</main>
	)
}
export default Admin
