import Dashboard from "./Dashboard";
import {useEffect} from "react";
import {connect} from "react-redux";
import {getIndexStatus, getOrderPayStatus, getOrderStatus, getServiceStatus} from "../../../../redux/directory_Reducer";
import {getUsertype} from "../../../../redux/auth_Reducer";



const DashboardContainer = (props) => {
    useEffect(()=>{
            props.getOrderStatus();
            props.getOrderPayStatus();
            props.getUserType();
            props.getIndexStatus();
            props.getServiceStatus();
    },)
    return <Dashboard {...props}/>
}
const mapStateToProps =(state)=>({
    location:state.location,
})
export default connect(mapStateToProps,{getOrderStatus,getOrderPayStatus,getUserType: getUsertype,
    getIndexStatus,getServiceStatus})(DashboardContainer)