import { myAccountAPI } from '../api/api'

const SET_WISHLIST = 'tour2sky/myAccount/SET_WISHLIST'
const SET_ADD_TO_FAVORITE = 'tour2sky/myAccount/SET_ADD_TO_FAVORITE'
const REMOVE_ITEM_FAVORITE = 'tour2sky/myAccount/REMOVE_ITEM_FAVORITE'

const initialState = {
  wishList: {
    data: [
      {
        id: 1,
        service_id: 123,
        user_id: 6543,
        name: 'Concert 12234',
        link: 'concert-12234',
        created_at: '2021-12-25 09:30:25',
        service: {
          id: 1,
          user_id: 1,
          name: 'Concerts',
          link: 'concerts',
          created_at: '2021-12-25 09:30:25',
          price: 30,
          duration: 120,
          status: 1,
          description: 'Some service description',
          rating: 4.5,
          seo_title: 'Concerts',
          seo_description: 'Concerts',
          index_status: 1,
          seo_image: {
            id: 1,
            name: 'concert-1.jpeg',
            mime_type: 'image/jpeg',
            link: '',
            thumb: '',
          },
          attributes: {
            data: [
              {
                id: 1,
                service_id: 2,
                attribute_id: 3,
                name: 'Wi-Fi',
                value: 'exist',
                description: 'Some description text',
              },
            ],
          },
          categories: {
            data: [
              {
                id: 1,
                service_id: 2,
                category_id: 3,
                name: 'Concerts',
              },
            ],
          },
          images: {
            data: [
              {
                id: 1,
                name: 'concert-1.jpeg',
                mime_type: 'image/jpeg',
                link: '',
                thumb: '',
              },
            ],
          },
          country: {
            id: 1,
            continent_id: 1,
            name: 'Ukraine',
            link: 'ukraine',
            status: 1,
            created_at: '2021-12-25 09:30:25',
          },
          region: {
            id: 1,
            country_id: 1,
            name: 'Одесская область',
            link: 'district-odessa',
            status: 1,
            created_at: '2021-12-25 09:30:25',
          },
          city: {
            id: 1,
            region_id: 1,
            name: 'Одесса',
            link: 'odessa',
            status: 1,
            seo_title: 'Concerts',
            seo_description: 'Concerts',
            index_status: 1,
            seo_image: {
              id: 1,
              name: 'concert-1.jpeg',
              mime_type: 'image/jpeg',
              link: '',
              thumb: '',
            },
            created_at: '2021-12-25 09:30:25',
          },
          location: {
            id: 1,
            name: 'Аркадия',
            link: 'arcadia',
            latitude: 46.430869,
            longitude: 30.761649,
            region_id: 1,
            city_id: 1,
            status: 1,
            seo_title: 'Concerts',
            seo_description: 'Concerts',
            index_status: 1,
            seo_image: {
              id: 1,
              name: 'concert-1.jpeg',
              mime_type: 'image/jpeg',
              link: '',
              thumb: '',
            },
            created_at: '2021-12-25 09:30:25',
          },
          currency: {
            id: 1,
            name: 'US Dollar',
            code: 'USD',
            rate: 28.1,
            status: 1,
          },
        },
      },
    ],
    meta: {
      pagination: {
        total: 30,
        count: 2,
        per_page: 15,
        current_page: 1,
        total_pages: 2,
        links: {},
      },
    },
    pagination: {},
  },
  isFavoriteItem: false,
}

const myAccount_Reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WISHLIST: {
      return {
        ...state,
        wishList: {
          data: action.data.data,
          meta: action.data.meta,
          pagination: action.data.meta.pagination,
        },
      }
    }
    case SET_ADD_TO_FAVORITE: {
      return {
        ...state,
      }
    }
    case REMOVE_ITEM_FAVORITE: {
      return {
        ...state,
        wishList: {
          data: state.wishList.data.filter(i => i.service_id !== action.data),
          meta: state.wishList.meta,
          pagination: state.wishList.meta.pagination,
        },
      }
    }
    default:
      return state
  }
}

export const setWishlist = data => ({ type: SET_WISHLIST, data })
export const setItemToFavorite = () => ({ type: SET_ADD_TO_FAVORITE })
export const delItemToFavorite = data => ({ type: REMOVE_ITEM_FAVORITE, data })

/*This is Thunk*/
export const addWishlist = serviceId => async dispatch => {
  try {
    await myAccountAPI.addServiceToFavorite(serviceId)
    dispatch(setItemToFavorite(serviceId))
  } catch (e) {}
}
export const removeWishlist = serviceId => async dispatch => {
  try {
    await myAccountAPI.removeServiceFromFavorite(serviceId)
    dispatch(delItemToFavorite(serviceId))
  } catch (e) {}
}
export const getWishList = () => async dispatch => {
  let response = await myAccountAPI.getFavoriteService()
  dispatch(setWishlist(response.data))
}

export default myAccount_Reducer
