import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AdminTableAvailable from '../../../AdminTableAvailable'

import {
	getAllServices,
	getDeleteServices,
	getServicesList,
} from '../../../../../redux/adminServices_Reducer'

const ServiceAvailable = () => {
	const dispatch = useDispatch()
	const createNavLink = '/admin_cabinet/service/service_create'
	const adminServices = useSelector(state => state.adminServices)
	const getServices = useSelector(() => getServicesList)
	const getDelete = useSelector(() => getDeleteServices)
	const allServices = useSelector(state => state.adminServices.allServicesList)
	const getAllServicesList = useSelector(() => getAllServices)

	useEffect(() => {
		dispatch(getAllServicesList())
	}, [allServices.length])

	return (
		<div>
			<AdminTableAvailable
				rowsList={adminServices.adminServicesData}
				getServices={getServices}
				deleteButton={getDelete}
				createNavLink={createNavLink}
				pageTotalCount={adminServices.adminServicesMeta.pagination.total} //*please send total pages
				type={'service'}
				isShowSearchBlock={true}
				allSearchList={allServices}
			/>
		</div>
	)
}
export default ServiceAvailable
