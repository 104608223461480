import AdminOrderView from "./AdminOrderView";
import {connect} from "react-redux";
import {
  getDeleteOrder,
  getOrderCancel,
  getOrderUpdate,
  getOrderUpdateCustomer
} from "../../../../../redux/adminOrders_Reducer";





const AdminOrderViewContainer = (props) => {

  return <AdminOrderView {...props}/>
}
const mapStateToProps = (state) => ({
  adminOrderView:state.adminOrders.adminOrderView,
})
export default connect(mapStateToProps,
    {
      getOrderCancel,
      getOrderUpdate,getOrderUpdateCustomer,
      getDeleteOrder})
(AdminOrderViewContainer)