import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunkMeddleWare from 'redux-thunk'
import activityLocation_Reducer from './activityLocation_Reducer'
import adminActivityLocation_Reducer from './adminActivityLocation_Reducer'
import adminAttributes_Reducer from './adminAttributes_Reducer'
import adminCategory_Reducer from './adminCategory_Reducer'
import adminGeo_Reducer from './adminGeo_Reducer'
import adminOrders_Reducer from './adminOrders_Reducer'
import adminServices_Reducer from './adminServices_Reducer'
import adminUsersPartners_Reducer from './adminUsers&Partners_Reducer'
import advertising_Reducer from './advertising_Reducer'
import auth_Reducer from './auth_Reducer'
import bookingSoftwareList from './booking-software.reducer'
import directory_Reducer from './directory_Reducer'
import location_Reducer from './location_Reducer'
import myAccount_Reducer from './myAccount_Reducer'
import page_Reducer from './page_Reducer'
import partnerServices_Reducer from './partnerServices_Reducer'
import productCards_Reducer from './productCards_Reducer'
import productItem_Reducer from './productItem_Reducer'
import profile_Reducer from './profile_Reducer'
import seo_Reducer from './seo_Reducer'
import affiliateProgramsReducer from './affiliate-program'

let reducers = combineReducers({
	profilePage: profile_Reducer,
	auth: auth_Reducer,
	productCards: productCards_Reducer,
	productItemView: productItem_Reducer,
	myAccount: myAccount_Reducer,
	admin: adminUsersPartners_Reducer,
	adminActivity: adminActivityLocation_Reducer,
	adminOrders: adminOrders_Reducer,
	adminServices: adminServices_Reducer,
	adminAdvertising: advertising_Reducer,
	adminCategory: adminCategory_Reducer,
	partnerServices: partnerServices_Reducer,
	seo: seo_Reducer,
	attributes: adminAttributes_Reducer,
	geo: adminGeo_Reducer,
	directory: directory_Reducer,
	location: location_Reducer,
	page: page_Reducer,
	activityLocation: activityLocation_Reducer,
	bookingSoftware: bookingSoftwareList,
	affiliate: affiliateProgramsReducer
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
	reducers,
	composeEnhancers(applyMiddleware(thunkMeddleWare))
)

export default store
