import { Field, Form, Formik } from 'formik'
import s from '../../Admin.module.css'
import { Button, FormControlLabel, Switch } from '@mui/material'
import cls from './index.module.css'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { DefaultEditor } from 'react-simple-wysiwyg'

import { instance } from '../../../../api/api'

const createUpdateStaticPage = async (page, page_id) => {
  try {
    if (page_id) {
      return await instance.post(`admin/page/${page_id}/update`, page)
    } else {
      delete page.slug
      return await instance.post('admin/page/create', page)
    }
  } catch ({ response }) {
    return { errors: response.data.errors }
  }
}

const getStaticPage = async id => {
  try {
    return (await instance.get(`admin/page/${id}`)).data
  } catch (error) {
    console.log(error)
  }
}

const CreateStaticPages = props => {
  const { id } = useParams()

  const [done, setDone] = useState(false)
  const [inFooter, setInFooter] = useState(false)
  const [initialValues, setInitialValues] = useState({
    name: '',
    seo_title: '',
    seo_description: '',
    slug: '',
    description: '',
  })

  useEffect(() => {
    if (id) {
      getStaticPage(id).then(data => {
        setInitialValues(data)
        setInFooter(data.in_footer)
      })
    }
  }, [])

  return (
    <>
      <div className={s.formContainer}>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={async (values, submitProps) => {
            const { data, errors } = await createUpdateStaticPage(
              {
                ...values,
                in_footer: Number(inFooter),
              },
              id
            )

            if (data) {
              setDone(true)
              setTimeout(() => setDone(false), 1000)
              if (!id) submitProps.resetForm()
            }
            if (errors) {
              submitProps.setStatus({ error: errors })
            }
          }}
        >
          {({ status, values, setFieldValue, setStatus }) => {
            if (done) {
              return (
                <div className={s.createComplete}>
                  {id ? 'Update successful!' : 'Create successful!'}
                </div>
              )
            } else {
              return (
                <Form
                  onChange={e => {
                    setStatus({
                      error: { ...status?.error, [e.target?.name]: null },
                    })
                  }}
                >
                  <div className={s.createBlocks}>
                    <div className={s.serviceCreateItem}>
                      <label>
                        Name
                        {status && status?.error?.name && (
                          <div className={s.error}>{status.error.name}</div>
                        )}
                        <Field name='name' type='text' value={values?.name} />
                      </label>
                    </div>
                    <div className={s.serviceCreateItem}>
                      <label>
                        Slug
                        {status && status?.error?.slug && (
                          <div className={s.error}>{status.error.slug}</div>
                        )}
                        <Field name='slug' type='text' value={values?.slug} />
                      </label>
                    </div>
                  </div>

                  <div className={s.createBlocks}>
                    <div className={s.serviceCreateItem}>
                      <label>
                        Seo title
                        {status && status?.error?.seo_title && (
                          <div className={s.error}>
                            {status.error.seo_title}
                          </div>
                        )}
                        <Field
                          name='seo_title'
                          type='text'
                          value={values?.seo_title}
                        />
                      </label>
                    </div>
                    <div className={s.serviceCreateItem}>
                      <label>
                        Seo description
                        {status && status?.error?.seo_description && (
                          <div className={s.error}>
                            {status.error.seo_description}
                          </div>
                        )}
                        <Field
                          name='seo_description'
                          type='text'
                          value={values?.seo_description}
                        />
                      </label>
                    </div>
                  </div>

                  <div className={s.createBlocks}>
                    <div className={cls.defaultEditor}>
                      <DefaultEditor
                        name='description'
                        style={{ height: '250px' }}
                        value={values.description}
                        onChange={e => {
                          setFieldValue('description', e.target.value)
                          setStatus({
                            error: { ...status?.error, [e.target?.name]: null },
                          })
                        }}
                      />
                    </div>
                    {status && status?.error?.description && (
                      <div className={s.error}>{status.error.description}</div>
                    )}
                  </div>

                  <div className={s.createBlocks}>
                    <div className={s.serviceCreateItem}>
                      <div className={cls.row}>
                        <Button
                          sx={{
                            width: '20%',
                            backgroundColor: ' #295673',
                            color: ' white',
                          }}
                          type={'submit'}
                          size='small'
                        >
                          {id ? `Update` : `Create`}
                        </Button>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={!!inFooter}
                              onChange={() => setInFooter(prev => !prev)}
                            />
                          }
                          label='In footer'
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              )
            }
          }}
        </Formik>
      </div>
    </>
  )
}

export default CreateStaticPages
