import * as React from 'react'
import { NavLink } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import editImg from '../../../../assets/img/pencil.png'
import delImg from '../../../../assets/img/delimg.png'

import clsAdmin from '../../Admin.module.css'
import cls from './index.module.css'
import { instance } from '../../../../api/api'

const getStaticPagesList = async () => {
  try {
    const response = await instance.get('admin/page')
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

const removeStaticPage = async page_id => {
  try {
    return await instance.delete(`admin/page/${page_id}/delete`)
  } catch (error) {
    console.log(error)
  }
}

const StaticPagesList = () => {
  const [pagesList, setPagesList] = React.useState([])

  React.useEffect(() => {
    getStaticPagesList().then(data => setPagesList(data))
  }, [])

  return (
    <>
      <div className={cls.wraperBtn}>
        <div className={clsAdmin.createButton}>
          <span>
            <NavLink to={`/admin_cabinet/static_pages/create`}>Create</NavLink>
          </span>
        </div>
      </div>

      <TableContainer component={Paper} className={cls.tableContainer}>
        <Table sx={{ width: 1180 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align='right'>Link</TableCell>
              <TableCell align='right'>id</TableCell>
              <TableCell align='right'>In footer</TableCell>
              <TableCell align='right'>Edit</TableCell>
              <TableCell align='left'>Remove</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pagesList.map(page => (
              <TableRow
                key={page.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component='th' scope='row'>
                  {page.name}
                </TableCell>
                <TableCell align='right'>{page.slug}</TableCell>
                <TableCell align='right'>{page.id}</TableCell>
                <TableCell align='right'>
                  {page.in_footer ? 'true' : 'false'}
                </TableCell>
                <TableCell align='right'>
                  <NavLink to={`/admin_cabinet/static_pages/create/${page.id}`}>
                    <img
                      className={clsAdmin.editDelImg}
                      src={editImg}
                      alt='edit'
                    />
                  </NavLink>
                </TableCell>
                <TableCell align='left'>
                  <a
                    href='#'
                    onClick={async () => {
                      const { data } = await removeStaticPage(page.id)
                      if (data) {
                        setPagesList(
                          pagesList.filter(item => item.id !== page.id)
                        )
                      }
                    }}
                  >
                    <img
                      className={clsAdmin.editDelImg}
                      src={delImg}
                      alt='edit'
                    />
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default StaticPagesList
