import { adminAdvertising } from '../api/apiAdmin'
const SET_ADVERTISING_LIST =
  'tour2sky-project/advertising/SET_ADVERTISING_LIST' 
const SET_ADVERTISING_LIST_SERVICE_CARD =
  'tour2sky-project/advertising/SET_ADVERTISING_LIST_SERVICE_CARD' 
const SET_ADVERTISING_LIST_ACTIVITY_BUTTOM =
  'tour2sky-project/advertising/SET_ADVERTISING_LIST_ACTIVITY_BUTTOM' 
const SET_ADVERTISING_VIEW =
  'tour2sky-project/advertising/SET_ADVERTISING_VIEW'
const SET_ADVERTISING_DELETE =
  'tour2sky-project/advertising/SET_ADVERTISING_DELETE'
const SET_ADVERTISING_NO_PAGE =
  'tour2sky-project/advertising/SET_ADVERTISING_NO_PAGE'
  const SET_ALL_ADVERTISING = "tour2sky/admin/advertising/SET_ALL_ADVERTISING";
  const SET_ALL_ADVERTISING_CARD_SERVICE = "tour2sky/admin/advertising/SET_ALL_ADVERTISING_CARD_SERVICE";
  const SET_ALL_ADVERTISING_ACTIVITY_BOTTOM = "tour2sky/admin/advertising/SET_ALL_ADVERTISING_ACTIVITY_BOTTOM";

let initialState = {
  advertisingList: [],
  advertisingListServiceCard: [],
  advertisingListActivityButtom: [],
  allAdvertisingList: [],
  allAdvertisingListCardService: [],
  allAdvertisingListActivityBottom: [],
  advertisingView: {},
  adminAdvertisingMeta: { pagination: { total: 1, current_page: 1 } },
  noPage: false,
}

const activityLocation_Reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADVERTISING_LIST: {
      return {
        ...state,
        advertisingList: action.data.data,
        adminAdvertisingMeta: action.data.meta,
      }
    }
    case SET_ADVERTISING_LIST_SERVICE_CARD: {
      return {
        ...state,
        advertisingListServiceCard: action.data.data,
        adminAdvertisingMeta: action.data.meta,
      }
    }
    case SET_ADVERTISING_LIST_ACTIVITY_BUTTOM: {
      return {
        ...state,
        advertisingListActivityButtom: action.data.data,
        adminAdvertisingMeta: action.data.meta,
      }
    }
    case SET_ADVERTISING_DELETE: {
      return {
        ...state,
        advertisingList: state.advertisingList.filter(
          item => item.id !== action.data
        ),
      }
    }
    case SET_ADVERTISING_VIEW: {
      return {
        ...state,
        advertisingView: action.data,
      }
    }
    case SET_ALL_ADVERTISING: {
      return {
          ...state,
          allAdvertisingList: action.data,
      }
    }
    case SET_ALL_ADVERTISING_CARD_SERVICE: {
      return {
          ...state,
          allAdvertisingListCardService: action.data,
      }
    }
    case SET_ALL_ADVERTISING_ACTIVITY_BOTTOM: {
      return {
          ...state,
          allAdvertisingListActivityBottom: action.data,
      }
    }
    case SET_ADVERTISING_NO_PAGE: {
      return {
        ...state,
        noPage: true,
      }
    }

    default:
      return state
  }
}

export const setAdvertisingDate = data => ({
  type: SET_ADVERTISING_LIST,
  data,
})
export const setAdvertisingDateServiceCard = data => ({
  type: SET_ADVERTISING_LIST_SERVICE_CARD,
  data,
})
export const setAdvertisingDateActivityButtom = data => ({
  type: SET_ADVERTISING_LIST_ACTIVITY_BUTTOM,
  data,
})
export const setAdvertisingView = data => ({
  type: SET_ADVERTISING_VIEW,
  data,
})
export const setAdvertisingDelete = data => ({ type: SET_ADVERTISING_DELETE, data })
export const setActivityLocationNoPage = data => ({
  type: SET_ADVERTISING_NO_PAGE,
  data,
})
export const setAllAdvertising = (data) => ({type: SET_ALL_ADVERTISING, data});
export const setAllAdvertisingCardService = (data) => ({type: SET_ALL_ADVERTISING_CARD_SERVICE, data});
export const setAllAdvertisingActivityBottom = (data) => ({type: SET_ALL_ADVERTISING_ACTIVITY_BOTTOM, data});

/*This is Thunk*/

export const getCreateUpdateAdvertising =
  (formData, setStatus, setDone, advertisingId) => async dispatch => {
    try {
      let response = await adminAdvertising.getCreateUpdate(formData, advertisingId)
      dispatch(setAdvertisingView(response.data))
      setStatus({})
      setDone(true)
    } catch (error) {
      let errors = error.response
      setStatus({ error: errors.data.errors })
    }
  }

  export const getAdvertisingView = advertisingId => async dispatch => {
    let response = await adminAdvertising.getAdminAdvertisingView(advertisingId)
  
    dispatch(setAdvertisingView({ ...response.data }))
  }

  export const getAdvertisingList = (page, perPage, order) => async dispatch => {
    try {
      let response = await adminAdvertising.getAdminAdvertising(page, perPage, order)
      dispatch(setAdvertisingDate(response.data))
    } catch (e) {
      console.log(e)
    }
  }

  export const getAdvertisingListServiceCard = (page, perPage, order) => async dispatch => {
    try {
      let response = await adminAdvertising.getAdminAdvertisingServiceCard(page, perPage, order)
      dispatch(setAdvertisingDateServiceCard(response.data))
    } catch (e) {
      console.log(e)
    }
  }

  export const getAdvertisingListActivityButtom = (page, perPage, order) => async dispatch => {
    try {
      let response = await adminAdvertising.getAdminAdvertisingActivityButtom(page, perPage, order)
      dispatch(setAdvertisingDateActivityButtom(response.data))
    } catch (e) {
      console.log(e)
    }
  }

  export const getDeleteAdvertising = advertisingId => async dispatch => {
    try {
      await adminAdvertising.getDelete(advertisingId)
      dispatch(setAdvertisingDelete(advertisingId))
    } catch (e) {}
  }

  export const getAllAdvertising = () => async (dispatch) => {
    try {
        let response = await adminAdvertising.getAllAdvertising();
        dispatch(setAllAdvertising(response.data.result));

    } catch (error) {
    }
}
  export const getAllAdvertisingCardService = () => async (dispatch) => {
    try {
        let response = await adminAdvertising.getAllAdvertisingCardService();
        dispatch(setAllAdvertisingCardService(response.data.result));

    } catch (error) {
    }
}
  export const getAllAdvertisingActivityBottom = () => async (dispatch) => {
    try {
        let response = await adminAdvertising.getAllAdvertisingActivityBottom();
        dispatch(setAllAdvertisingActivityBottom(response.data.result));

    } catch (error) {
    }
}

export default activityLocation_Reducer
