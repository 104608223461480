import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getOutClientProfile } from '../../../redux/profile_Reducer'

const HeaderAutorized = () => {
  const navigation = useNavigate()
  const dispatch = useDispatch()
  const getOut = useSelector(() => getOutClientProfile)
  const role = useSelector(state => state.profilePage.role)

  return (
    <div className='header_login'>
      <div className='login_drop'>
        <span>
          <i>
            <FontAwesomeIcon icon='fa-solid fa-user' />
          </i>
          {role === 'admin' ? (
            <NavLink to='/admin_cabinet'>Admin account</NavLink>
          ) : (
            <NavLink to='/myAccount/personal_info'>My account</NavLink>
          )}
        </span>
        <ul>
          {role === 'client' || role === 'partner' ? (
            <>
              <li>
                <NavLink to='/myAccount/bookings'>Bookings</NavLink>
              </li>
              <li>
                <NavLink to='/myAccount/wishlist'>Wishlist</NavLink>
              </li>
            </>
          ) : (
            ''
          )}
          <li>
            <Link to='/authorization'>Login as user</Link>
            <Link
              to='/authorization'
              onClick={() => {
                dispatch(getOut())
              }}
            >
              Sign Out
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}
export default HeaderAutorized
