import SeoPagesList from "./SeoPagesList";
import {connect} from "react-redux";
import {getSeoPagesList} from "../../../../../redux/seo_Reducer";
import {useEffect} from "react";

const SeoPagesListContainer = ({getSeoPagesList,seoPagesList}) => {
    useEffect(()=> {
        getSeoPagesList()
    },[])
    return <div>
        <SeoPagesList seoPagesList={seoPagesList} />
    </div>
}
const mapStateToProps = (state)=>({
    seoPagesList:state.seo.seoPagesList,
})

export default connect(mapStateToProps,{getSeoPagesList})(SeoPagesListContainer)