import * as axios from 'axios'

export const adminToken = localStorage.getItem('jwt')
// export const adminInstance = axios.create({
//   withCredentials: true,
//   baseURL: 'https://api.dev.tour2sky.com/api/admin/',
//   headers: { Authorization: adminToken, "Content-Type:" "application/json" },
// })
// adminInstance.interceptors.request.use(config => {
//   config.headers.Authorization = localStorage.getItem('jwt')
//   return config
// })

export const adminInstance = axios.create({
  withCredentials: true,
  baseURL: 'https://api.tour2sky.com/api/admin/',
  headers: { Authorization: adminToken },
})
adminInstance.interceptors.request.use(config => {
	config.headers.Authorization = localStorage.getItem('jwt')
	return config
})

export const adminActivityLocationPage = {
	getActivityLocationList(page = 0, perPage = 15, order = 'asc') {
		return adminInstance.get(
			`activity-location?page=${page}&sort%5Bname%5D=${order}&per_page=${perPage}`
		)
	},
	getActivityLocationPageView(activityLocationId) {
		return adminInstance.get(`activity-location/${activityLocationId}`)
	},
	getDelete(activityId) {
		return adminInstance.delete(`activity-location/${activityId}/delete`)
	},
	getCreateUpdate(formData, id) {
		if (id) {
			return adminInstance.post(
				`activity-location/${id}/update?activity_location_id=${id}`,
				formData,
				{ headers: { 'Content-Type': 'multipart/form-data' } }
			)
		} else {
			return adminInstance.post(`activity-location/create`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
		}
	},
	getActivate(id) {
		return adminInstance.post(`activity-location/${id}/activate`, {})
	},
	getBlock(id) {
		return adminInstance.post(`activity-location/${id}/block`, {})
	},
	getAllActivity() {
		return adminInstance.get(`activity-location/get-as-pair`)
	},
}
export const adminAttribute = {
	getList(page = 1, perPage = 15, order = 'asc') {
		return adminInstance.get(
			`attribute?sort%5Bname%5D=${order}&page=${page}&per_page=${perPage}`
		)
	},
	getView(id) {
		return adminInstance.get(`attribute/${id}`)
	},
	getCreateUpdate(id, formData) {
		if (id) {
			return adminInstance.post(
				`attribute/${id}/update?attribute_id=${id}`,
				formData
			)
		} else {
			return adminInstance.post(`attribute/create`, formData)
		}
	},

	getDelete(id) {
		return adminInstance.delete(`attribute/${id}/delete`)
	},
	getActivate(id) {
		return adminInstance.post(`attribute/${id}/activate`, {})
	},
	getBlock(id) {
		return adminInstance.post(`attribute/${id}/block`, {})
	},
}
export const adminCategory = {
	getCategoryList(page = 0, perPage = 150, order = 'asc') {
		return adminInstance.get(
			`category?page=${page}&sort%5Bname%5D=${order}&per_page=${perPage}`
		)
	},
	getCategoryView(categoryId) {
		return adminInstance.get(`category/${categoryId}`)
	},
	getDelete(categoryId) {
		return adminInstance.delete(`category/${categoryId}/delete`)
	},
	getCreateUpdate(formData, categoryId) {
		if (categoryId) {
			return adminInstance.post(
				`category/${categoryId}/update?category_id=${categoryId}`,
				formData,
				{ headers: { 'Content-Type': 'multipart/form-data' } }
			)
		} else {
			return adminInstance.post(`category/create`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
		}
	},
	getActivate(categoryId) {
		return adminInstance.post(`category/${categoryId}/activate`, {})
	},
	getBlock(categoryId) {
		return adminInstance.post(`category/${categoryId}/block`, {})
	},
}
export const adminGeo = {
	cities: {
		getCitiesList(page = 0, perPage = 15, order = 'asc') {
			return adminInstance.get(
				`geo/city?sort%5Bname%5D=${order}&page=${page}&per_page=${perPage}`
			)
		},
		getCitiesView(id) {
			return adminInstance.get(`geo/city/${id}`)
		},
		getDeleteCity(id) {
			return adminInstance.delete(`geo/city/${id}/delete`)
		},
		getCreateUpdateCity(formData, id) {
			if (id) {
				return adminInstance.post(
					`geo/city/${id}/update?city_id=${id}`,
					formData,
					{ headers: { 'Content-Type': 'multipart/form-data' } }
				)
			} else {
				return adminInstance.post(`geo/city/create`, formData, {
					headers: { 'Content-Type': 'multipart/form-data' },
				})
			}
		},

		getActivateCity(id) {
			return adminInstance.post(`geo/city/${id}/activate`, {})
		},
		getBlockCity(id) {
			return adminInstance.post(`geo/city/${id}/block`, {})
		},
		getAllCities() {
			return adminInstance.get(`geo/location/list-geo?entity=cities`)
		},
	},
	continents: {
		getContinentsList(page = 0, perPage = 15, order = 'asc') {
			return adminInstance.get(
				`geo/continent?sort%5Bname%5D=${order}&page=${page}&per_page=${perPage}`
			)
		},
		getContinentsView(id) {
			return adminInstance.get(`geo/continent/${id}`)
		},
		getDeleteContinents(id) {
			return adminInstance.delete(`geo/continent/${id}/delete`)
		},
		getCreateContinents(formData) {
			return adminInstance.post(`geo/continent/create`, formData)
		},
		getUpdateContinents(id, formData) {
			return adminInstance.post(
				`geo/continent/${id}/update?continent_id=${id}`,
				formData
			)
		},
		getActivateContinents(id) {
			return adminInstance.post(`geo/continent/${id}/activate`, {})
		},
		getBlockContinents(id) {
			return adminInstance.post(`geo/continent/${id}/block`, {})
		},
	},
	countries: {
		getCountriesList(page = 0, perPage = 200, order = 'asc') {
			return adminInstance.get(
				`geo/country?sort%5Bname%5D=${order}&page=${page}&per_page=${perPage}`
			)
		},
		getCountriesView(id) {
			return adminInstance.get(`geo/country/${id}`)
		},
		getDeleteCountries(id) {
			return adminInstance.delete(`geo/country/${id}/delete`)
		},
		getCreateUpdateCountries(formData, id) {
			if (id) {
				return adminInstance.post(
					`geo/country/${id}/update?country_id=${id}`,
					formData
				)
			} else {
				return adminInstance.post(`geo/country/create`, formData)
			}
		},
		getActivateCountries(id) {
			return adminInstance.post(`geo/country/${id}/activate`, {})
		},
		getBlockCountries(id) {
			return adminInstance.post(`geo/country/${id}/block`, {})
		},
	},
	locations: {
		getLocationsList(page = 0, perPage = 15, order = 'asc') {
			return adminInstance.get(
				`geo/location?sort%5Bname%5D=${order}&page=${page}&per_page=${perPage}`
			)
		},
		getLocationsView(id) {
			return adminInstance.get(`geo/location/${id}`)
		},
		getDeleteLocations(id) {
			return adminInstance.delete(`geo/location/${id}/delete`)
		},
		getCreateUpdateLocations(formData, id) {
			if (id) {
				return adminInstance.post(
					`geo/location/${id}/update?location_id=${id}`,
					formData,
					{ headers: { 'Content-Type': 'multipart/form-data' } }
				)
			} else {
				return adminInstance.post(`geo/location/create`, formData, {
					headers: { 'Content-Type': 'multipart/form-data' },
				})
			}
		},
		getActivateLocations(id) {
			return adminInstance.post(`geo/location/${id}/activate`, {})
		},
		getBlockLocations(id) {
			return adminInstance.post(`geo/location/${id}/block`, {})
		},
		getAllLocations() {
			return adminInstance.get(`geo/location/list-geo?entity=locations`)
		},
	},
	regions: {
		getRegionsList(page = 0, perPage = 15, order = 'asc') {
			return adminInstance.get(
				`geo/region?sort%5Bname%5D=${order}&page=${page}&per_page=${perPage}`
			)
		},
		getRegionsView(id) {
			return adminInstance.get(`geo/region/${id}`)
		},
		getDeleteRegions(id) {
			return adminInstance.delete(`geo/region/${id}/delete`)
		},
		getCreateUpdateRegions(formData, id) {
			if (id) {
				return adminInstance.post(
					`geo/region/${id}/update?region_id=${id}`,
					formData
				)
			} else {
				return adminInstance.post(`geo/region/create`, formData)
			}
		},
		getActivateRegions(id) {
			return adminInstance.post(`geo/region/${id}/activate`, {})
		},
		getBlockRegions(id) {
			return adminInstance.post(`geo/region/${id}/block`, {})
		},
		getAllRegions() {
			return adminInstance.get(`geo/location/list-geo?entity=regions`)
		},
	},
}
export const adminOrder = {
	getAdminOrders(page = 0) {
		return adminInstance.get(`order?page=${page}&sort%5Bname%5D=asc`)
	},
	getOrderView(ordersId) {
		return adminInstance.get(`order/${ordersId}`)
	},
	getCancelOrder(orderId) {
		return adminInstance.post(`order/${orderId}/cancel`, {})
	},
	getUpdateOrder(orderId, updateInfo) {
		return adminInstance.post(`order/${orderId}/update`, updateInfo)
	},
	getUpdateCustomerOrder(orderId, updateInfo) {
		return adminInstance.post(`order/${orderId}/update-customer`, updateInfo)
	},
	getDeleteOrder(orderId) {
		return adminInstance.delete(`order/${orderId}/delete`)
	},
}
export const adminPartner = {
	getAdminPartnersList(page = 1, perPage = 15, order = 'asc') {
		return adminInstance.get(
			`partner?page=${page}&sort%5Bname%5D=${order}&per_page=${perPage}`
		)
	},
	getAdminPartnersView(partnerId) {
		return adminInstance.get(`partner/${partnerId}`)
	},
	createPartner(formData, id) {
		console.log('FORMDATA', formData.get('name'))
		if (id) {
			return adminInstance.post(`partner/${id}`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
		} else {
			return adminInstance.post(`partner`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
		}
	},
	deletePartnder(id) {
		return adminInstance.delete(`partner/${id}`)
	},
	getAdminPartnerActivate(partnerId) {
		return adminInstance.post(`partner/${partnerId}/activate`, {})
	},
	getAdminPartnerBlock(partnerId) {
		return adminInstance.post(`partner/${partnerId}/block`, {})
	},
	getForSelect() {
		return adminInstance.get('partner/get-for-select')
	},
	getPartnerTours(partnerId) {
		return adminInstance.get(`partner/${partnerId}/get-tours`)
	},
}
export const adminSEO = {
	getPagesList() {
		return adminInstance.get(`seo`)
	},
	getSettings(id) {
		return adminInstance.get(`seo/${id}`)
	},
	getUpdate(id, formData) {
		return adminInstance.post(`seo/${id}/update`, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		})
	},
}
export const adminService = {
	deleteServiceImage(imageId, serviceId) {
		console.log('id', imageId)
		return adminInstance.delete(
			`service/service-image-delete?imageId=${imageId}&serviceId=${serviceId}`
		)
	},
	addServiceImage(data) {
		return adminInstance.post(`service/add-service-image`, data)
	},
	getAdminServices(
		page = 0,
		perPage = 15,
		order = 'asc',
		typeIds = [],
		affiliateProgramName = '',
		partnerName = ''
	) {
		console.log('typeIds', typeIds)
		return adminInstance.get('service', {
			params: {
				page: page,
				per_page: perPage,
				sort: { name: order },
				type: typeIds.length > 0 ? typeIds : undefined,
				affiliate_program_name: affiliateProgramName || undefined,
				partner_name: partnerName || undefined, // Add this line
			},
			paramsSerializer: params => {
				const searchParams = new URLSearchParams()

				// Handle page and per_page
				searchParams.append('page', params.page)
				searchParams.append('per_page', params.per_page)

				// Handle sort as an array
				if (params.sort) {
					Object.keys(params.sort).forEach(key => {
						searchParams.append(`sort[${key}]`, params.sort[key])
					})
				}

				// Handle type_ids as an array
				if (params.type) {
					params.type.forEach(id => {
						searchParams.append('type[]', id)
					})
				}

				if (params.affiliate_program_name) {
					searchParams.append(
						'affiliate_program_name',
						params.affiliate_program_name
					)
				}

				if (params.partner_name) {
					searchParams.append('partner_name', params.partner_name)
				}

				return searchParams.toString()
			},
		})
	},
	getAdminServicesView(serviceId) {
		return adminInstance.get(`service/${serviceId}`)
	},
	getDelete(serviceId) {
		return adminInstance.delete(`service/${serviceId}/delete`, {})
	},
	getBlock(serviceId) {
		return adminInstance.post(`service/${serviceId}/block`, {})
	},
	getActivate(serviceId) {
		return adminInstance.post(`service/${serviceId}/activate`, {})
	},
	getCreateUpdate(formData, serviceId) {
		if (!serviceId) {
			return adminInstance.post(`service/create`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
		} else {
			return adminInstance.post(
				`service/${serviceId}/update?service_id=${serviceId}`,
				formData,
				{ headers: { 'Content-Type': 'multipart/form-data' } }
			)
		}
	},
	getAllServices() {
		return adminInstance.get(`geo/location/list-geo?entity=services`)
	},
	getServiceTypes() {
		return adminInstance.get(`service/get-types`)
	},
}
export const adminAdvertising = {
	getAllAdvertising() {
		return adminInstance.get(`banner/get-for-select?type=1`)
	},
	getAllAdvertisingCardService() {
		return adminInstance.get(`banner/get-for-select?type=1`)
	},
	getAllAdvertisingActivityBottom() {
		return adminInstance.get(`banner/get-for-select?type=2`)
	},
	getDelete(advertisingId) {
		return adminInstance.delete(`banner/${advertisingId}`)
	},
	getAdminAdvertising(page = 0, perPage = 15, order = 'asc') {
		return adminInstance.get(
			`banner?page=${page}&per_page=${perPage}&sort%5Bname%5D=${order}&type=${1}`
		)
	},
	getAdminAdvertisingServiceCard(page = 0, perPage = 15, order = 'asc') {
		return adminInstance.get(
			`banner?page=${page}&per_page=${perPage}&sort%5Bname%5D=${order}&type=${1}`
		)
	},
	getAdminAdvertisingActivityButtom(page = 0, perPage = 15, order = 'asc') {
		return adminInstance.get(
			`banner?page=${page}&per_page=${perPage}&sort%5Bname%5D=${order}&type=${2}`
		)
	},
	getAdminAdvertisingView(serviceId) {
		return adminInstance.get(`banner/${serviceId}`)
	},
	getCreateUpdate(formData, advertisingId) {
		if (!advertisingId) {
			return adminInstance.post(`banner`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
		} else {
			return adminInstance.post(`banner/${advertisingId}`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
		}
	},
	getAllAdvertisings() {
		return adminInstance.get(`geo/location/list-geo?entity=services`)
	},
}
export const adminUser = {
	getAdminUsersList(page = 1, perPage = 15, sortFullName = 'asc') {
		return adminInstance.get(
			`user?page=${page}&per_page=${perPage}&sort%5Bfull_name%5D=${sortFullName}`
		)
	},
	getAdminUserView(userId) {
		return adminInstance.get(`user/${userId}`)
	},
	getAdminUserActivate(userId) {
		return adminInstance.post(`user/${userId}/activate`, {})
	},
	getAdminUserBlock(userId) {
		return adminInstance.post(`user/${userId}/block`, {})
	},
	getAdminUserSetAdmin(userId) {
		return adminInstance.post(`user/${userId}/set-admin`, {})
	},
	getAdminUserDelAdmin(userId) {
		return adminInstance.post(`user/${userId}/delete-admin`, {})
	},
}
export const adminBookingSoftware = {
	async getAdminUserBookingSoftware() {
		return await adminInstance.get('booking-software')
	},
	createUpdateBookingSoftware(formData, bookingSoftwareId) {
		if (!bookingSoftwareId) {
			return adminInstance.post(`booking-software`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
		} else {
			return adminInstance.post(`booking-software/${bookingSoftwareId}`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
		}
	},
	getBookingSoftwareView(bookingSoftwareId) {
		return adminInstance.get(`booking-software/${bookingSoftwareId}`)
	},
	getDelete(bookingSoftwareId) {
		return adminInstance.delete(`booking-software/${bookingSoftwareId}`, {})
	},
	getAdminUserBookingSoftwareForSelect() {
		return adminInstance.get(`booking-software/get-for-select`, {})
	},
}

export const affiliatePrograms = {
	getAffiliatePrograms(
		page = 0,
		perPage = 15,
		order_by = 'id',
		order_direction = 'desc'
	) {
		return adminInstance.get(
			`affiliate-program?page=${page}&per_page=${perPage}&order_by=${order_by}&order_direction=${order_direction}`
		)
	},
	createUpdateAffiliateProgram(formData, affiliateId) {
		if (!affiliateId) {
			return adminInstance.post(`affiliate-program`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
		} else {
			return adminInstance.post(`affiliate-program/${affiliateId}`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
		}
	},
	getAffiliateView(affiliateId) {
		return adminInstance.get(`affiliate-program/${affiliateId}`)
	},
	getDelete(affiliateId) {
		return adminInstance.delete(`affiliate-program/${affiliateId}`, {})
	},
	getForSelect() {
		return adminInstance.get(`affiliate-program/get-for-select`, {})
	},
}
