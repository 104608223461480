import {Outlet} from "react-router";




const AdminCategory = () => {
    return <div>
        <Outlet>Available, View, Create</Outlet>
    </div>
}
export default AdminCategory