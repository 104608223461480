import { useEffect } from 'react'
import AdminTableAvailable from "../../../../AdminTableAvailable";
import {useSelector, useDispatch} from "react-redux";
import {getCitiesDelete, getCitiesList, getAllCities} from "../../../../../../redux/adminGeo_Reducer";




const GeoCitiesAvailable = () => {
  const dispatch = useDispatch()
  const createNavLink = "/admin_cabinet/locations/cities_create";
  const adminCities = useSelector((state) =>state.geo.citiesList);
  const pagination = useSelector((state) =>state.geo.citiesListMeta.pagination);
  const getCities = useSelector(() => getCitiesList);
  const getDelete = useSelector(() => getCitiesDelete);

  const allCities = useSelector(state => state.geo.allCitiesList)
  const getAllRegionsList = useSelector(() => getAllCities)

  useEffect(() => {
    dispatch(getAllRegionsList())
  }, [allCities.length])
  return <div>
    <AdminTableAvailable rowsList={adminCities}
                         current_page={pagination.current_page}
                         per_page={pagination.per_page}
                         pageTotalCount={pagination.total}
                         deleteButton={getDelete}
                         createNavLink={createNavLink}
                         getServices={getCities}
                         type={"locations"}
                         isShowSearchBlock={true}
                         allSearchList={allCities}
    />
  </div>
}
export default GeoCitiesAvailable