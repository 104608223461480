import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
	getBookingSoftwareList,
	getDeleteBookingSoftware,
} from '../../../../redux/booking-software.reducer'
import TableBookingSoftware from './TableBookingSoftware'

const BookingSoftwareView = () => {
	const dispatch = useDispatch()
	const createNavLink =
		'/admin_cabinet/booking-software/booking-software_create'
	const getDelete = useSelector(() => getDeleteBookingSoftware)
	const allPrograms = useSelector(
		state => state.bookingSoftware.bookingSoftwareList
	)
	// const metaPrograms = useSelector(state => state.bookingSoftware.metaPrograms)
	const getAllPrograms = useSelector(() => getBookingSoftwareList)

	useEffect(() => {
		dispatch(getAllPrograms())
	}, [allPrograms.length]) // allPrograms.length

	return (
		<div>
			<TableBookingSoftware
				rowsList={allPrograms}
				getServices={getAllPrograms}
				deleteButton={getDelete}
				createNavLink={createNavLink}
				// pageTotalCount={metaPrograms?.pagination?.total}
				type={'service'}
				isShowSearchBlock={true}
				allSearchList={allPrograms}
			/>
		</div>
	)
}
export default BookingSoftwareView
