import * as React from 'react'
import { useSelector } from 'react-redux'
import EnhancedTable from '../../../../../assets/common/TableSort&Selecting/EnhancedTable'
import {
	getAdminUserSetAdmin,
	getUsersList,
} from '../../../../../redux/adminUsers&Partners_Reducer'

const AvailableUsers = () => {
	const adminUsersList = useSelector(state => state.admin.adminUsersListData)
	const pagination = useSelector(
		state => state.admin.adminUsersListMeta.pagination
	)
	const getUsers = useSelector(() => getUsersList)
	const setAdmin = useSelector(() => getAdminUserSetAdmin)

	return (
		<EnhancedTable
			rows={adminUsersList}
			perPage={pagination?.per_page}
			currentPage={pagination?.current_page}
			totalItemCount={pagination?.total}
			getUsersList={getUsers}
			pagination={pagination}
			getAdminUserSetAdmin={setAdmin}
		/>
	)
}
export default AvailableUsers
