import { Field, Form, Formik } from 'formik'
import style from '../../../Admin.module.css'
import Button from '@mui/material/Button'
import React, { useEffect, useState } from 'react'
import { Checkbox } from '@mui/material'
import { DefaultEditor } from 'react-simple-wysiwyg'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCategoryActivate,
  getCategoryBlock,
  getCategoryCreateUpdate,
  getCategoryView,
} from '../../../../../redux/adminCategory_Reducer'
import { getIndexStatus } from '../../../../../redux/directory_Reducer'
import Select from 'react-select'
import StatusSelect from '../../../../../assets/common/StatusSelect/StatusSelect'

const CreateCategory = () => {
  const params = useParams()
  let categoryId = params.itemId
  const adminCategoryView = useSelector(
    state => state.adminCategory.adminCategoryView
  )
  const dispatch = useDispatch()
  const [done, setDone] = useState(false)
  const directory = useSelector(state => state.directory)
  const getCategory = useSelector(() => getCategoryView)
  const getIndexS = useSelector(() => getIndexStatus)
  const getCategoryCr = useSelector(() => getCategoryCreateUpdate)
  const getActive = useSelector(() => getCategoryActivate)
  const getBlock = useSelector(() => getCategoryBlock)

  useEffect(() => {
    if (categoryId) {
      dispatch(getCategory(categoryId))
    }
    dispatch(getIndexS())
  }, [categoryId])
  let initialValues
  if (categoryId) {
    initialValues = {
      name: adminCategoryView?.name || '',
      seo_title: adminCategoryView?.seo_title || '',
      seo_description: adminCategoryView?.seo_description || '',
      seo_image: adminCategoryView?.seo_image || '',
      index_status: adminCategoryView?.index_status || 1,
      title: adminCategoryView?.title || '',
      description: adminCategoryView?.description || '',
      in_menu: +adminCategoryView?.in_menu || 0,
      header_1: adminCategoryView?.header_1 || '',
      header_2: adminCategoryView?.header_2 || '',
      header_3: adminCategoryView?.header_3 || '',
    }
  } else {
    initialValues = {
      name: '',
      seo_title: '',
      seo_description: '',
      seo_image: '',
      index_status: 1,
      title: '',
      description: '',
      in_menu: 0,
      header_1: '',
      header_2: '',
      header_3: '',
    }
  }

  return (
    <div className={style.formContainer}>
      <div className={style.wrapper}>
        <h4>{categoryId ? 'Update' : 'Create'} category</h4>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values, submitProps) => {
            dispatch(
              getCategoryCr(values, submitProps.setStatus, setDone, categoryId)
            )
          }}
        >
          {({ status, setFieldValue, values }) => {
            if (done) {
              return (
                <div className={style.createComplete}>
                  <h3>Successful!</h3>
                </div>
              )
            } else {
              return (
                <div className={style.formContainer}>
                  <Form>
                    <div className={style.createBlocks}>
                      <div className={style.serviceCreateItem}>
                        <label>
                          Category name
                          {status?.error.name && (
                            <div className={style.error}>
                              {status.error.name}
                            </div>
                          )}
                          <Field
                            required
                            name={'name'}
                            type='text'
                            placeholder={'name'}
                          />
                        </label>
                      </div>
                      <div className={style.serviceCreateItem}>
                        <label>
                          Add to nav menu
                          {status && status.error.in_menu && (
                            <div className={style.error}>
                              {status.error.in_menu}
                            </div>
                          )}
                          <Checkbox
                            checked={values.in_menu === 1 && true}
                            onChange={(event, checked) => {
                              if (checked) {
                                setFieldValue('in_menu', 1)
                              } else {
                                setFieldValue('in_menu', 0)
                              }
                            }}
                          />
                        </label>
                      </div>
                      <div className={style.serviceCreateItem}>
                        {categoryId && (
                          <StatusSelect
                            active={getActive}
                            block={getBlock}
                            elementInfo={adminCategoryView}
                          />
                        )}
                      </div>
                    </div>
                    <div className={style.createBlocks}>
                      <div className={style.serviceCreateItem}>
                        <label>
                          Title
                          {status?.error.seo_title && (
                            <div className={style.error}>
                              {status.error.seo_title}
                            </div>
                          )}
                          <Field
                            name={'title'}
                            type='text'
                            placeholder={'Title'}
                          />
                        </label>
                      </div>
                      <div className={style.serviceCreateItem}>
                        <span>Description</span>
                        {status && status.error.description && (
                          <div className={style.error}>
                            {status.error.description}
                          </div>
                        )}
                        <DefaultEditor
                          value={values.description}
                          onChange={e => {
                            setFieldValue('description', e.target.value)
                          }}
                        />
                      </div>
                    </div>
                    <div className={style.createBlocks}>
                      <div className={style.serviceCreateItem}>
                        <label>
                          SEO Title
                          {status && status.error.seo_title && (
                            <div className={style.error}>
                              {status.error.seo_title}
                            </div>
                          )}
                          <Field
                            name={'seo_title'}
                            type='text'
                            placeholder={'Title'}
                          />
                        </label>
                      </div>
                      <div className={style.serviceCreateItem}>
                        <label>
                          SEO Description
                          {status && status.error.seo_description && (
                            <div className={style.error}>
                              {status.error.seo_description}
                            </div>
                          )}
                          <Field
                            name={'seo_description'}
                            type='text'
                            placeholder={'Description'}
                          />
                        </label>
                      </div>
                    </div>
                    <div className={style.createBlocks}>
                      <label>
                        Image, formats png, jpeg, jpg.
                        {status && status.error.seo_image && (
                          <div className={style.error}>
                            {status.error.images}
                          </div>
                        )}
                        <input
                          id='images'
                          name='seo_image'
                          type='file'
                          accept='.png,.jpeg,.jpg'
                          onChange={event => {
                            setFieldValue(
                              'seo_image',
                              event.currentTarget.files[0]
                            )
                          }}
                        />
                      </label>

                      <div className={style.serviceCreateItem}>
                        {categoryId && (
                          <div className={style.serviceCreateItemImg}>
                            <img
                              src={adminCategoryView.seo_image?.thumb}
                              alt='thumb'
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={style.createBlocks}>
                      <div className={style.serviceCreateItem}>
                        <label>
                          Header 1
                          {status?.error.header_1 && (
                            <div className={style.error}>
                              {status.error.header_1}
                            </div>
                          )}
                          <Field
                            required
                            name={'header_1'}
                            type='text'
                            placeholder={'Header 1'}
                          />
                        </label>
                      </div>
                      <div className={style.serviceCreateItem}>
                        <label>
                          Header 2
                          {status?.error.header_2 && (
                            <div className={style.error}>
                              {status.error.header_2}
                            </div>
                          )}
                          <Field
                            required
                            name={'header_2'}
                            type='text'
                            placeholder={'Header 2'}
                          />
                        </label>
                      </div>
                      <div className={style.serviceCreateItem}>
                        <label>
                          Header 3
                          {status?.error.header_3 && (
                            <div className={style.error}>
                              {status.error.header_3}
                            </div>
                          )}
                          <Field
                            required
                            name={'header_3'}
                            type='text'
                            placeholder={'Header 3'}
                          />
                        </label>
                      </div>
                    </div>
                    <div className={style.createBlocks}>
                      <label>
                        Change status
                        {status && status.error.index_status && (
                          <div className={style.error}>
                            {status.error.index_status}
                          </div>
                        )}
                        <select
                          defaultValue={1}
                          onChange={event => {
                            setFieldValue(
                              'index_status',
                              +event.currentTarget.value
                            )
                          }}
                        >
                          {/* <option>Index status</option>*/}
                          {directory.indexStatus.map(i => (
                            <option key={i.id} value={i.id}>
                              {i.name}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>
                    <div className={style.createBlocks}>
                      <Button
                        sx={{
                          width: '20%',
                          backgroundColor: ' #295673',
                          color: ' white',
                        }}
                        type={'submit'}
                        size='small'
                      >
                        {categoryId ? `Update` : `Create`}
                      </Button>
                    </div>
                  </Form>
                </div>
              )
            }
          }}
        </Formik>
      </div>
    </div>
  )
}
export default CreateCategory
