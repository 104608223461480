import AdminUsersView from "./AdminUsersView";
import {connect} from "react-redux";
import {
    getActivateUser,
    getAdminUserDelAdmin,
    getAdminUserSetAdmin, getAdminUserView,
    getBlockUser
} from "../../../../../redux/adminUsers&Partners_Reducer";
import {getUsersType} from "../../../../../redux/directory_Reducer";
import Preloader from "../../../../../assets/Preloader/Preloader";
import {useEffect,useState} from "react";
import {useParams} from "react-router";



const AdminUsersViewContainer = ({adminUserView,getAdminUserView,getUserType,...props}) => {

    const [role, setRole] = useState(adminUserView.role);
    const params = useParams()
    let userId = params.userId

    useEffect(() => {
        getAdminUserView(userId)
        getUserType()
    }, [role,userId])

    if (props.isLoading) {
        return <Preloader/>
    }
    return <AdminUsersView {...props} setRole={setRole} role={role} adminUserView={adminUserView} />

}
const mapStateToProps = (state) => ({
    adminUserView: state.admin.adminUserView,
    directory: state.directory,
    isLoading: state.admin.isLoading,
})
export default connect(mapStateToProps, {
    getActivateUser, getAdminUserView, getBlockUser, getAdminUserSetAdmin,
    getAdminUserDelAdmin,getUserType: getUsersType
})(AdminUsersViewContainer)