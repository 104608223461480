import React, {useState, useEffect} from 'react';
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { NavLink } from 'react-router-dom'
import Switch from '@mui/material/Switch'
import s from '../../../../Profiles/Admin/Admin.module.css';
import editImg from '../../../../assets/img/pencil.png'
import delImg from '../../../../assets/img/delimg.png'
import { adminInstance } from '../../../../api/apiAdmin';
const AdvertisingAvailableListItem = ({
  row, 
  isItemSelected, 
  labelId, 
  type, 
  createNavLink, 
  handleClickOpen,
  isGlobalBannerId,
  setIsGlobalBannerId}) => {
    const [isActiveSwitcher, setIsActiveSwitcher] = useState(false);

    const handleChangeglobalBanner = async (id) => {
      setIsGlobalBannerId(id)
      if(id == row.id) {
        setIsActiveSwitcher(!isActiveSwitcher)
      } else {
        setIsActiveSwitcher(false)
      }
        await adminInstance.post(`banner/toggle-global/${row.id}`, {
          is_global: "1",
          type: "1"
        })
    }

    useEffect(() => {
      if(isGlobalBannerId !== row.id) {
        setIsActiveSwitcher(false)
      }
    },[isGlobalBannerId])

    useEffect(() => {
      if(row.is_global == "1") {
        setIsActiveSwitcher(true)
      }
    },[])

    return (
            <TableRow
              hover
              role='checkbox'
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.id}
              selected={isItemSelected}
            >
              <TableCell
                component='th'
                id={labelId}
                scope='row'
                padding='normal'
              >
                <NavLink to={`/${row.link}`}>{row.id}</NavLink>
              </TableCell>
              <TableCell
                component='th'
                id={labelId}
                scope='row'
                padding='normal'
              >
                <div style={{width: '50px', height: '50px'}}>
                <img style={{width: '100%', height:'100%', objectFit:'scale-down'}} src={row.banner_image}/>
                </div>
              </TableCell>
              <TableCell align='left'>
                {type === 'service' ? (
                  <NavLink
                    to={`/${row?.category_link}/${
                      row.city_link !== ''
                        ? row.city_link
                        : row.location_link
                    }/${row?.link}`}
                  >
                    {row.name}
                  </NavLink>
                ) : type === 'locations' ? (
                  <NavLink to={``}>{row.name}</NavLink>
                ) : (
                  <NavLink
                    to={`/${row?.link}/${
                      row.city_link ?? row.location_link ?? ''
                    }`}
                  >
                    {row.name}
                  </NavLink>
                )}
              </TableCell>
              <TableCell align='right'>{row.link}</TableCell>
              {/* <TableCell align='right'>
                {row.count_cities ? row.count_cities : ''}
              </TableCell> */}
              <TableCell align='right'>
                {/* {row.status === 1 ? 'active' : 'block'} */}
                {/*{row.status_name}*/}
                <Switch checked={isActiveSwitcher} onChange={() => handleChangeglobalBanner(row.id)}/>
              </TableCell>
              <TableCell align='right'>
                <NavLink to={createNavLink + `/${row.id}`}>
                  <img
                    className={s.editDelImg}
                    src={editImg}
                    alt='edit'
                  />
                </NavLink>
                <NavLink to={'#'}>
                  <img
                    onClick={() => {
                      handleClickOpen(row.id)
                    }}
                    className={s.editDelImg}
                    src={delImg}
                    alt='del'
                  />
                </NavLink>
              </TableCell>
            </TableRow>
    );
};

export default AdvertisingAvailableListItem;