import * as axios from 'axios'

const instancePartner = axios.create({
  withCredentials: true,
  baseURL: 'https://api.tour2sky.com/api/partner-cabinet/',
  headers: { Authorization: localStorage.getItem('jwt') },
})
instancePartner.interceptors.request.use(config => {
  config.headers.Authorization = localStorage.getItem('jwt')
  return config
})
export const partnerProfile = {
  getUpdatePartnerInfo(updateOption) {
    return instancePartner.post(`profile/update`, updateOption)
  },
}
export const partnerService = {
  getPartnerServices(token, page = 1) {
    return instancePartner.get(
      `service?sort%5Bservice_name%5D=asc&page=${page}`
    )
  },
  getPartnerServicesView(serviceId) {
    return instancePartner.get(`service/${serviceId}`)
  },
  getDelete(serviceId) {
    return instancePartner.delete(`service/${serviceId}/delete`, {})
  },
  getBlock(serviceId) {
    return instancePartner.post(`service/${serviceId}/block`, {})
  },
  getActivate(serviceId) {
    return instancePartner.post(`service/${serviceId}/activate`, {})
  },
  getCreate(formData) {
    return instancePartner.post(`service/create`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
}
