import { adminGeo } from '../api/apiAdmin'

const SET_LOADING_ON = 'tour2sky-project/admin/geo/city/SET_LOADING_ON'
const SET_LOADING_OFF = 'tour2sky-project/admin/geo/city/SET_LOADING_OFF'

const SET_GEO_CITIES_LIST =
	'tour2sky-project/admin/geo/city/SET_GEO_CITIES_LIST'
const SET_GEO_CITIES_VIEW =
	'tour2sky-project/admin/geo/city/SET_GEO_CITIES_VIEW'
const SET_GEO_CITIES_DELETE =
	'tour2sky-project/admin/geo/city/SET_GEO_CITIES_DELETE'
const SET_ALL_GEO_CITIES = 'tour2sky-project/admin/geo/city/SET_ALL_GEO_CITIES'

const SET_GEO_CONTINENTS_LIST =
	'tour2sky-project/admin/geo/continents/SET_GEO_CONTINENTS_LIST'
const SET_GEO_CONTINENTS_VIEW =
	'tour2sky-project/admin/geo/continents/SET_GEO_CONTINENTS_VIEW'
const SET_GEO_CONTINENTS_DELETE =
	'tour2sky-project/admin/geo/continents/SET_GEO_CONTINENTS_DELETE'

const SET_GEO_COUNTRIES_LIST =
	'tour2sky-project/admin/geo/countries/SET_GEO_COUNTRIES_LIST'
const SET_GEO_COUNTRIES_VIEW =
	'tour2sky-project/admin/geo/countries/SET_GEO_COUNTRIES_VIEW'
const SET_GEO_COUNTRIES_DELETE =
	'tour2sky-project/admin/geo/countries/SET_GEO_COUNTRIES_DELETE'

const SET_GEO_LOCATIONS_LIST =
	'tour2sky-project/admin/geo/locations/SET_GEO_LOCATIONS_LIST'
const SET_GEO_LOCATIONS_VIEW =
	'tour2sky-project/admin/geo/locations/SET_GEO_LOCATIONS_VIEW'
const SET_GEO_LOCATIONS_DELETE =
	'tour2sky-project/admin/geo/locations/SET_GEO_LOCATIONS_DELETE'
const SET_ALL_GEO_LOCATIONS =
	'tour2sky-project/admin/geo/locations/SET_ALL_GEO_LOCATIONS'

const SET_GEO_REGIONS_LIST =
	'tour2sky-project/admin/geo/regions/SET_GEO_LOCATIONS_LIST'
const SET_GEO_REGIONS_VIEW =
	'tour2sky-project/admin/geo/regions/SET_GEO_LOCATIONS_VIEW'
const SET_GEO_REGIONS_DELETE =
	'tour2sky-project/admin/geo/regions/SET_GEO_LOCATIONS_DELETE'
const SET_ALL_GEO_REGIONS =
	'tour2sky-project/admin/geo/regions/SET_ALL_GEO_REGIONS'

let initialState = {
	isLoading: false,
	citiesList: [
		{
			id: 1,
			region_id: 1,
			name: '',
			link: '',
			status: 1,
			seo_title: '',
			seo_description: '',
			index_status: 1,
			seo_image: {
				id: 1,
				name: '',
				mime_type: '',
				link: '',
				thumb: '',
			},
			created_at: '',
		},
	],
	citiesListMeta: {
		pagination: {
			total: 30,
			count: 2,
			per_page: 15,
			current_page: 1,
			total_pages: 2,
			links: {},
		},
	},
	citiesView: {
		id: 1,
		region_id: 1,
		name: '',
		link: '',
		status: 1,
		seo_title: '',
		seo_description: '',
		index_status: 1,
		seo_image: {
			id: 1,
			name: '',
			mime_type: '',
			link: '',
			thumb: '',
		},
		created_at: '',
	},
	continentsList: [],
	continentsListMeta: {
		pagination: {
			total: 30,
			count: 2,
			per_page: 15,
			current_page: 1,
			total_pages: 2,
			links: {},
		},
	},
	continentsView: {
		id: 1,
		name: '',
		link: '',
		status: 1,
		created_at: '',
	},

	countriesList: [
		{
			id: 1,
			continent_id: 1,
			name: '',
			link: '',
			status: 1,
			created_at: '',
		},
	],
	countriesListMeta: {
		pagination: {
			total: 30,
			count: 2,
			per_page: 15,
			current_page: 1,
			total_pages: 2,
			links: {},
		},
	},
	countriesView: {
		id: 1,
		continent_id: 1,
		name: '',
		link: '',
		status: 1,
		created_at: '',
	},

	locationsList: [
		{
			id: 1,
			name: '',
			link: '',
			latitude: 46.430869,
			longitude: 30.761649,
			region_id: 1,
			city_id: 1,
			status: 1,
			seo_title: '',
			seo_description: '',
			index_status: 1,
			seo_image: {
				id: 1,
				name: '',
				mime_type: '',
				link: '',
				thumb: '',
			},
			created_at: '',
		},
	],
	locationsListMeta: {
		pagination: {
			total: 30,
			count: 2,
			per_page: 15,
			current_page: 1,
			total_pages: 2,
			links: {},
		},
	},
	locationsView: {},

	regionsList: [
		{
			id: 1,
			country_id: 1,
			name: '',
			link: '',
			status: 1,
			created_at: '',
		},
	],
	regionsListMeta: {
		pagination: {
			total: 30,
			count: 2,
			per_page: 15,
			current_page: 1,
			total_pages: 2,
			links: {},
		},
	},
	regionsView: {
		id: 1,
		country_id: 1,
		name: '',
		link: '',
		status: 1,
		created_at: '',
	},
	allRegionsList: [],
	allLocationsList: [],
	allCitiesList: [],
}

const adminGeo_Reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_LOADING_ON: {
			return {
				...state,
				isLoading: true,
			}
		}
		case SET_LOADING_OFF: {
			return {
				...state,
				isLoading: false,
			}
		}
		case SET_GEO_CITIES_LIST: {
			return {
				...state,
				citiesList: action.data.data,
				citiesListMeta: action.data.meta,
			}
		}
		case SET_GEO_CITIES_VIEW: {
			return {
				...state,
				citiesView: action.data,
			}
		}
		case SET_GEO_CITIES_DELETE: {
			return {
				...state,
				citiesList: state.citiesList.filter(i => i.id !== action.data),
			}
		}
		case SET_ALL_GEO_CITIES: {
			return {
				...state,
				allCitiesList: action.data,
			}
		}

		case SET_GEO_CONTINENTS_LIST: {
			return {
				...state,
				continentsList: action.data.data,
				continentsListMeta: action.data.meta,
			}
		}
		case SET_GEO_CONTINENTS_VIEW: {
			return {
				...state,
				continentsView: action.data,
			}
		}
		case SET_GEO_CONTINENTS_DELETE: {
			return {
				...state,
				continentsList: state.continentsList.filter(i => i.id !== action.data),
			}
		}

		case SET_GEO_COUNTRIES_LIST: {
			return {
				...state,
				countriesList: action.data.data,
				countriesListMeta: action.data.meta,
			}
		}
		case SET_GEO_COUNTRIES_VIEW: {
			return {
				...state,
				countriesView: action.data,
			}
		}
		case SET_GEO_COUNTRIES_DELETE: {
			return {
				...state,
				countriesList: state.countriesList.filter(i => i.id !== action.data),
			}
		}

		case SET_GEO_LOCATIONS_LIST: {
			return {
				...state,
				locationsList: action.data.data,
				locationsListMeta: action.data.meta,
			}
		}
		case SET_GEO_LOCATIONS_VIEW: {
			return {
				...state,
				locationsView: action.data,
			}
		}
		case SET_GEO_LOCATIONS_DELETE: {
			return {
				...state,
				locationsList: state.locationsList.filter(i => i.id !== action.data),
			}
		}
		case SET_ALL_GEO_LOCATIONS: {
			return {
				...state,
				allLocationsList: action.data,
			}
		}
		case SET_GEO_REGIONS_LIST: {
			return {
				...state,
				regionsList: action.data.data,
				regionsListMeta: action.data.meta,
			}
		}
		case SET_GEO_REGIONS_VIEW: {
			return {
				...state,
				regionsView: action.data,
			}
		}
		case SET_GEO_REGIONS_DELETE: {
			return {
				...state,
				regionsList: state.regionsList.filter(i => i.id !== action.data),
			}
		}
		case SET_ALL_GEO_REGIONS: {
			return {
				...state,
				allRegionsList: action.data,
			}
		}
		default:
			return state
	}
}
export const setLoadingOn = () => ({ type: SET_LOADING_ON })
export const setLoadingOff = () => ({ type: SET_LOADING_OFF })

export const setDeleteOn = () => ({ type: SET_LOADING_OFF })
export const setDeleteOff = () => ({ type: SET_LOADING_OFF })

export const setCitiesList = data => ({ type: SET_GEO_CITIES_LIST, data }) //this is Action Creator
export const setCitiesView = data => ({ type: SET_GEO_CITIES_VIEW, data })
export const setCitiesDelete = data => ({ type: SET_GEO_CITIES_DELETE, data })
export const setAllCities = data => ({ type: SET_ALL_GEO_CITIES, data })
/*==============================================================================================================*/
export const setContinentsList = data => ({
	type: SET_GEO_CONTINENTS_LIST,
	data,
}) //this is Action Creator
export const setContinentsView = data => ({
	type: SET_GEO_CONTINENTS_VIEW,
	data,
})
export const setContinentsDelete = data => ({
	type: SET_GEO_CONTINENTS_DELETE,
	data,
})

/*============================================================================================================*/
export const setCountriesList = data => ({ type: SET_GEO_COUNTRIES_LIST, data }) //this is Action Creator
export const setCountriesView = data => ({ type: SET_GEO_COUNTRIES_VIEW, data })
export const setCountriesDelete = data => ({
	type: SET_GEO_COUNTRIES_DELETE,
	data,
})
/*============================================================================================================*/

export const setLocationsList = data => ({ type: SET_GEO_LOCATIONS_LIST, data }) //this is Action Creator
export const setLocationsView = data => ({ type: SET_GEO_LOCATIONS_VIEW, data })
export const setLocationsDelete = data => ({
	type: SET_GEO_LOCATIONS_DELETE,
	data,
})
export const setAllLocations = data => ({ type: SET_ALL_GEO_LOCATIONS, data })

/*========================================================================================*/
export const setRegionsList = data => ({ type: SET_GEO_REGIONS_LIST, data }) //this is Action Creator
export const setRegionsView = data => ({ type: SET_GEO_REGIONS_VIEW, data })
export const setRegionsDelete = data => ({ type: SET_GEO_REGIONS_DELETE, data })
export const setAllRegions = data => ({ type: SET_ALL_GEO_REGIONS, data })

/*This is Thunk*/
export const getCitiesList = (page, perPage, order) => async dispatch => {
	try {
		dispatch(setLoadingOn())
		let response = await adminGeo.cities.getCitiesList(page, perPage, order)
		dispatch(setCitiesList(response.data))
		dispatch(setLoadingOff())
	} catch (error) {}
}
export const getCitiesView = id => async dispatch => {
	dispatch(setLoadingOn())
	try {
		let response = await adminGeo.cities.getCitiesView(id)
		dispatch(setCitiesView(response.data))
	} catch (error) {}
	dispatch(setLoadingOff())
}
export const getCitiesDelete = id => async dispatch => {
	try {
		await adminGeo.cities.getDeleteCity(id)
		dispatch(setCitiesDelete(id))
	} catch (error) {}
}
export const getCitiesActivate = id => async dispatch => {
	let response = await adminGeo.cities.getActivateCity(id)
	dispatch(setCitiesView(response.data))
}
export const getCitiesBlock = id => async dispatch => {
	let response = await adminGeo.cities.getBlockCity(id)
	dispatch(setCitiesView(response.data))
}
export const getCitiesCreateUpdate =
	(formData, setStatus, setDone, id) => async dispatch => {
		try {
			let response = await adminGeo.cities.getCreateUpdateCity(formData, id)
			dispatch(setCitiesView(response.data))
			setStatus(null)
			setDone(true)
		} catch (error) {
			let errors = error.response
			if (errors.status === 422) {
				setStatus({ error: errors.data.errors })
			}
		}
	}

export const getAllCities = () => async dispatch => {
	try {
		let response = await adminGeo.cities.getAllCities()
		dispatch(setAllCities(response.data.data))
	} catch (error) {}
}

/*=============================================================================*/

export const getContinentsList = (page, perPage, order) => async dispatch => {
	dispatch(setLoadingOn())
	try {
		let response = await adminGeo.continents.getContinentsList(
			page,
			perPage,
			order
		)
		dispatch(setContinentsList(response.data))
	} catch (error) {}
	dispatch(setLoadingOff())
}
export const getContinentsView = id => async dispatch => {
	dispatch(setLoadingOn())
	try {
		let response = await adminGeo.continents.getContinentsView(id)
		dispatch(setContinentsView(response.data))
	} catch (error) {}
	dispatch(setLoadingOff())
}
export const getContinentsDelete = id => async dispatch => {
	try {
		await adminGeo.continents.getDeleteContinents(id)
		dispatch(setContinentsDelete(id))
	} catch (e) {}
}
export const getContinentsActivate = id => async dispatch => {
	let response = await adminGeo.continents.getActivateContinents(id)
	dispatch(setContinentsView(response.data))
}
export const getContinentsBlock = id => async dispatch => {
	let response = await adminGeo.continents.getBlockContinents(id)
	dispatch(setContinentsView(response.data))
}
export const getContinentsCreate =
	(formData, setStatus, setDone) => async dispatch => {
		try {
			let response = await adminGeo.continents.getCreateContinents(formData)
			dispatch(setContinentsView(response.data))
			setDone(true)
			setStatus(null)
		} catch (error) {
			let errors = error.response
			if (errors.status === 422) {
				setStatus({ error: errors.data.errors })
			}
		}
	}
export const getContinentsUpdate =
	(id, formData, setStatus, setDone) => async dispatch => {
		try {
			let response = await adminGeo.continents.getUpdateContinents(id, formData)
			dispatch(setContinentsView(response.data))
			setDone(true)
			setStatus(null)
		} catch (error) {
			let errors = error.response
			if (errors.status === 422) {
				setStatus({ error: errors.data.errors })
			}
		}
	}

/*=============================================================================*/

/*This is Thunk*/
export const getCountriesList = (page, perPage, order) => async dispatch => {
	dispatch(setLoadingOn())
	try {
		let response = await adminGeo.countries.getCountriesList(
			page,
			perPage,
			order
		)
		dispatch(setCountriesList(response.data))
	} catch (error) {}
	dispatch(setLoadingOff())
}
export const getCountriesView = id => async dispatch => {
	dispatch(setLoadingOn())
	try {
		let response = await adminGeo.countries.getCountriesView(id)
		dispatch(setCountriesView(response.data))
	} catch (error) {}
	dispatch(setLoadingOff())
}
export const getCountriesDelete = id => async dispatch => {
	try {
		await adminGeo.countries.getDeleteCountries(id)
		dispatch(setCountriesDelete(id))
	} catch (error) {}
}
export const getCountriesActivate = id => async dispatch => {
	dispatch(setLoadingOn())
	try {
		let response = await adminGeo.countries.getActivateCountries(id)
		dispatch(setCountriesView(response.data))
	} catch (error) {}
	dispatch(setLoadingOff())
}
export const getCountriesBlock = id => async dispatch => {
	dispatch(setLoadingOn())
	try {
		let response = await adminGeo.countries.getBlockCountries(id)
		dispatch(setCountriesView(response.data))
	} catch (error) {}
	dispatch(setLoadingOff())
}
export const getCountriesCreateUpdate =
	(formData, setStatus, setDone, id) => async dispatch => {
		try {
			let response = await adminGeo.countries.getCreateUpdateCountries(
				formData,
				id
			)
			dispatch(setCountriesView(response.data))
			setDone(true)
			setStatus(null)
		} catch (error) {
			let errors = error.response
			if (errors.status === 422) {
				setStatus({ error: errors.data.errors })
			}
		}
	}

/*=============================================================================*/
/*This is Thunk*/
export const getLocationsList = (page, perPage, order) => async dispatch => {
	dispatch(setLoadingOn())
	try {
		let response = await adminGeo.locations.getLocationsList(
			page,
			perPage,
			order
		)
		dispatch(setLocationsList(response.data))
	} catch (error) {}
	dispatch(setLoadingOff())
}
export const getLocationsView = id => async dispatch => {
	dispatch(setLoadingOn())
	try {
		let response = await adminGeo.locations.getLocationsView(id)
		dispatch(setLocationsView(response.data))
	} catch (error) {}
	dispatch(setLoadingOff())
}
export const getLocationsDelete = id => async dispatch => {
	try {
		await adminGeo.locations.getDeleteLocations(id)
		dispatch(setLocationsDelete(id))
	} catch (error) {}
}
export const getLocationsActivate = id => async dispatch => {
	let response = await adminGeo.locations.getActivateLocations(id)
	dispatch(setLocationsView(response.data))
}
export const getLocationsBlock = id => async dispatch => {
	let response = await adminGeo.locations.getBlockLocations(id)
	dispatch(setLocationsView(response.data))
}
export const getLocationsCreateUpdate =
	(formData, setStatus, setDone, id) => async dispatch => {
		try {
			let response = await adminGeo.locations.getCreateUpdateLocations(
				formData,
				id
			)
			dispatch(setLocationsView(response.data))
			setDone(true)
			setStatus(null)
		} catch (error) {
			let errors = error.response
			if (errors.status === 422) {
				setStatus({ error: errors.data.errors })
			}
		}
	}

export const getAllLocations = () => async dispatch => {
	try {
		let response = await adminGeo.locations.getAllLocations()
		dispatch(setAllLocations(response.data.data))
	} catch (error) {}
}

/*=============================================================================*/
/*This is Thunk*/
export const getRegionsList = (page, perPage, order) => async dispatch => {
	dispatch(setLoadingOn())
	try {
		let response = await adminGeo.regions.getRegionsList(page, perPage, order)
		dispatch(setRegionsList(response.data))
	} catch (error) {}
	dispatch(setLoadingOff())
}
export const getRegionsView = id => async dispatch => {
	dispatch(setLoadingOn())
	try {
		let response = await adminGeo.regions.getRegionsView(id)
		dispatch(setRegionsView(response.data))
	} catch (error) {}
	dispatch(setLoadingOff())
}
export const getRegionsDelete = id => async dispatch => {
	try {
		await adminGeo.regions.getDeleteRegions(id)
		dispatch(setRegionsDelete(id))
	} catch (error) {}
}
export const getRegionsActivate = id => async dispatch => {
	let response = await adminGeo.regions.getActivateRegions(id)
	dispatch(setRegionsView(response.data))
}
export const getRegionsBlock = id => async dispatch => {
	let response = await adminGeo.regions.getBlockRegions(id)
	dispatch(setRegionsView(response.data))
}
export const getRegionsCreateUpdate =
	(formData, setStatus, setDone, id) => async dispatch => {
		try {
			let response = await adminGeo.regions.getCreateUpdateRegions(formData, id)
			dispatch(setRegionsView(response.data))
			setDone(true)
			setStatus(null)
		} catch (error) {
			let errors = error.response
			if (errors.status === 422) {
				setStatus({ error: errors.data.errors })
			}
		}
	}

export const getAllRegions = () => async dispatch => {
	try {
		let response = await adminGeo.regions.getAllRegions()
		dispatch(setAllRegions(response.data.data))
	} catch (error) {}
}

/*=============================================================================*/

export default adminGeo_Reducer
