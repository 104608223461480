
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    BarElement,
    Legend,
    BarController,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
    BarController
);

export const options  = {
    plugins: {
        legend: {
            display: false
        }
    },
    elements: {
        bar: {
            borderRadius: 10
        }
    },
    scales: {
        x: {
            beginAtZero: true,
            grid: {
                display: false
            }
        },
        y: {
            beginAtZero: true,
            grid: {
                display: false
            }
        }
    },
}
const labels = ["Cities", "Countries", "Locations", "Regions"];









const VerticalBarChart = ({countsStat}) => {
let values = Object.values(countsStat)
    const data = {
        labels,
        datasets: [{
            type: 'bar',
            label: 'total',
            data: [70, 70, 70, 70],
            backgroundColor: ['#2980b9'],
            borderWidth: 0,
            tension: 0.4,
            grouped: false,
            order: 3
        }, {
            type: 'bar',
            label: 'All',
            data: values,
            backgroundColor: ['#33cccc'],
            borderColor: ['#2980b9'],
            borderWidth: 0,
            tension: 0.4,
        }]
    }
return  <Line options={options} data={data} type={"line"} />
};

export default VerticalBarChart;