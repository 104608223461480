import AvailableOrders from "./AvailableOrders";
import {connect} from "react-redux";
import {useEffect} from "react";
import {getOrdersList} from "../../../../../redux/adminOrders_Reducer";




const AvailableOrdersContainer = ({getOrdersList,pagination,ordersListData}) => {
useEffect(()=>{
    getOrdersList(pagination.current_page)
},[pagination.current_page])
    return <AvailableOrders ordersListData={ordersListData} />
}
const mapStateToProps = (state) => ({
    pagination:state.adminOrders.adminOrdersMeta.pagination,
    ordersListData:state.adminOrders.adminOrdersData,
})

export default connect(mapStateToProps,{getOrdersList})(AvailableOrdersContainer)