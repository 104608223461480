import * as React from 'react'
import PropTypes from 'prop-types'
import { alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import DeleteIcon from '@mui/icons-material/Delete'
import FilterListIcon from '@mui/icons-material/FilterList'
import { visuallyHidden } from '@mui/utils'
import { NavLink } from 'react-router-dom'
import s from '../../Profiles/Admin/Admin.module.css'
import editImg from '../../assets/img/pencil.png'
import delImg from '../../assets/img/delimg.png'
import { useState, useEffect } from 'react'
import TablePagination from '@mui/material/TablePagination'
import { useDispatch, useSelector } from 'react-redux'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Select from 'react-select'
import { getAllServiceTypes } from '../../redux/adminServices_Reducer'
import { getForSelect } from '../../redux/affiliate-program'
import { getForSelectPartner } from '../../redux/adminUsers&Partners_Reducer'

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'id',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'slug',
    numeric: true,
    disablePadding: false,
    label: 'slug',
  },
  {
    id: 'counts',
    numeric: true,
    disablePadding: false,
    label: 'counts',
  },
  {
    id: 'Status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'edit',
    numeric: true,
    disablePadding: false,
    label: '',
  },
]

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding='normal'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const EnhancedTableToolbar = props => {
  const { numSelected, isShowSearchBlock, createNavLink, allSearchList, allServiceTypes, selectedServiceTypes, setSelectedServiceTypes, allAffiliateSelect, handleAffiliateProgramChange, selectPartners, handlePartnerChange } = props;

  console.log('allServiceTypes', allServiceTypes)

    let allSearchOptions;
    if (isShowSearchBlock) {
      allSearchOptions = allSearchList.map(c => ({
        value: `${c.id}; ${c.name}`,
        label: <NavLink to={`${createNavLink}/${c.id}`}>{`Id: ${c.id}; ${c.name}`}</NavLink>,
      }));
    }

    const serviceTypeOptions = allServiceTypes.map(type => ({
      value: type.id,
      label: type.label,
    }));

   	const allAffiliateSelectOptions = allAffiliateSelect.map(aL => {
		return { value: aL.id, label: aL.name }
	})

    const updateForSelectPartner = selectPartners.map(selectPartner => ({
		value: selectPartner.id,
		label: selectPartner.name,
	}))

    const handleServiceTypeChange = selectedOptions => {
      setSelectedServiceTypes(selectedOptions || [])
    }

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: theme =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
            <div className={s.topPanel}>
              <div className={s.createButton}>
                <NavLink to={`${createNavLink}`}>Create</NavLink>
              </div>
              {isShowSearchBlock && (
                <div className={s.select}>
                  <Select
                    placeholder={'Search...'}
                    options={allSearchOptions}
                  />
                </div>
              )}
              <div className={s.select}>
                <Select
                  placeholder={'Select Service Types...'}
                  options={serviceTypeOptions}
                  isMulti
                  isClearable
                  isSearchable
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={selectedServiceTypes}
                  onChange={handleServiceTypeChange}
                />
              </div>
              <div className={s.select}>
                <Select
                    placeholder={'Select Affiliate Program...'}
                    options={allAffiliateSelectOptions}
                    onChange={handleAffiliateProgramChange} // Add this handler
                    isClearable
                    isSearchable
                />
              </div>
              <div className={s.select}>
                <Select
                    placeholder={'Select Partner...'}
                    options={updateForSelectPartner}
                    onChange={handlePartnerChange} // Add this handler
                    isClearable
                    isSearchable
                />
              </div>
            </div>
        )}
      </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}

export default function AdminTableAvailable({
  rowsList,
  getServices,
  ...props
}) {
  const dispatch = useDispatch()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [dense, setDense] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [openDialog, setOpenDialog] = useState(false)
  const [deleteItemId, setDeleteItemId] = React.useState('')
  const allServiceTypes = useSelector(state => state.adminServices.serviceTypes)
  const allAffiliateSelect = useSelector(
		state => state.affiliate.affiliateSelect
	)
  const selectPartners = useSelector(state => state.admin.selectPartners)
  const [selectedServiceTypes, setSelectedServiceTypes] = useState([])
  const [affiliateProgramName, setAffiliateProgramName] = useState('');
  const [partnerName, setPartnerName] = useState('');

  const handleClickOpen = id => {
    setOpenDialog(true)
    setDeleteItemId(id)
  }
  const handleClose = () => {
    setOpenDialog(false)
  }

  useEffect(() => {
    const typeIds = selectedServiceTypes.map(type => type.value)
    dispatch(getServices(page, rowsPerPage, order, typeIds, affiliateProgramName, partnerName));
  }, [page, rowsPerPage, order, selectedServiceTypes, affiliateProgramName, partnerName]);

  useEffect(() => {
    dispatch(getAllServiceTypes())
    dispatch(getForSelect())
    dispatch(getForSelectPartner())
  }, [dispatch])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    dispatch(getServices(page, rowsPerPage, isAsc ? 'desc' : 'asc', selectedServiceTypes.map(type => type.value), affiliateProgramName, partnerName))
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rowsList.map(n => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    dispatch(getServices(newPage, rowsPerPage, order, selectedServiceTypes.map(type => type.value), affiliateProgramName, partnerName))
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    dispatch(getServices(page, parseInt(event.target.value, 10), order, selectedServiceTypes.map(type => type.value), affiliateProgramName, partnerName))
  }

  const handleAffiliateProgramChange = (selectedOption) => {
    setAffiliateProgramName(selectedOption ? selectedOption.label : '');
    dispatch(getServices(page, rowsPerPage, order, selectedServiceTypes.map(type => type.value), selectedOption ? selectedOption.label : '', partnerName));
  };

  const handlePartnerChange = (selectedOption) => {
    setPartnerName(selectedOption ? selectedOption.label : '');
    dispatch(getServices(page, rowsPerPage, order, selectedServiceTypes.map(type => type.value), affiliateProgramName, selectedOption ? selectedOption.label : ''));
  };

  const handleChangeDense = event => {
    setDense(event.target.checked)
  }

  const isSelected = name => selected.indexOf(name) !== -1

  return (
    <Box sx={{ width: '100%' }}>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Really delete?'}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button
            onClick={() => {
              setOpenDialog(false)
              dispatch(props.deleteButton(deleteItemId))
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Paper sx={{ width: '100%', mb: 2, mt: 2 }}>
        <EnhancedTableToolbar
          selected={selected}
          numSelected={selected.length}
          createNavLink={props.createNavLink}
          isShowSearchBlock={props.isShowSearchBlock}
          allSearchList={props.allSearchList}
          allServiceTypes={allServiceTypes}
          selectedServiceTypes={selectedServiceTypes}
          setSelectedServiceTypes={setSelectedServiceTypes}
          allAffiliateSelect={allAffiliateSelect}
          handleAffiliateProgramChange={handleAffiliateProgramChange}
          selectPartners={selectPartners}
          handlePartnerChange={handlePartnerChange}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby='tableTitle'
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rowsList?.length}
            />
            <TableBody>
              {rowsList?.map((row, index) => {
                const isItemSelected = isSelected(row.id)
                const labelId = `enhanced-table-checkbox-${index}`
                return (
                  <TableRow
                    hover
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell
                      component='th'
                      id={labelId}
                      scope='row'
                      padding='normal'
                    >
                      <NavLink to={`/${row.link}`}>{row.id}</NavLink>
                    </TableCell>
                    <TableCell align='left'>
                      {props?.type === 'service' ? (
                        <NavLink
                          to={`/${row?.category_link}/${
                            row.city_link !== ''
                              ? row.city_link
                              : row.location_link
                          }/${row?.link}`}
                        >
                          {row.name}
                        </NavLink>
                      ) : props?.type === 'locations' ? (
                        <NavLink to={``}>{row.name}</NavLink>
                      ) : (
                        <NavLink
                          to={`/${row?.link}/${
                            row.city_link ?? row.location_link ?? ''
                          }`}
                        >
                          {row.name}
                        </NavLink>
                      )}
                    </TableCell>
                    <TableCell align='right'>{row.link}</TableCell>
                    <TableCell align='right'>
                      {row.count_cities ? row.count_cities : ''}
                    </TableCell>
                    <TableCell align='right'>
                      {row.status === 1 ? 'active' : 'block'}
                      {/*{row.status_name}*/}
                    </TableCell>
                    <TableCell align='right'>
                      <NavLink to={props.createNavLink + `/${row.id}`}>
                        <img
                          className={s.editDelImg}
                          src={editImg}
                          alt='edit'
                        />
                      </NavLink>
                      <NavLink to={'#'}>
                        <img
                          onClick={() => {
                            handleClickOpen(row.id)
                          }}
                          className={s.editDelImg}
                          src={delImg}
                          alt='del'
                        />
                      </NavLink>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          count={props.pageTotalCount}
          rowsPerPageOptions={[5, 15, 50, 100]}
          component='div'
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label='Dense padding'
      />
    </Box>
  )
}
