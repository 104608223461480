import AdminTableAvailable from '../../../AdminTableAvailable'
import { useSelector } from 'react-redux'
import {
  getAttributesDelete,
  getAttributesList,
} from '../../../../../redux/adminAttributes_Reducer'

const AttributeList = () => {
  const createNavLink = '/admin_cabinet/attributes/attributes_create'
  const attributeList = useSelector(state => state.attributes.attributeList)
  const pagination = useSelector(
    state => state.attributes.attributeListMeta.pagination
  )
  const getAttribute = useSelector(() => getAttributesList)
  const getDelete = useSelector(() => getAttributesDelete)

  return (
    <div>
      <AdminTableAvailable
        rowsList={attributeList}
        current_page={pagination.current_page}
        per_page={pagination.per_page}
        deleteButton={getDelete}
        createNavLink={createNavLink}
        pageTotalCount={pagination.total}
        getServices={getAttribute}
      />
    </div>
  )
}
export default AttributeList
