import {Field, Form, Formik} from "formik";
import * as React from "react";
import Box from "@mui/material/Box";
import {CardActions, Checkbox, Switch, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {useState} from "react";
import s from "../AdminUsers.module.css"



const AdminUsersView = ({setRole,role,...props}) => {

    const [editMode, setEditMode] = useState(false);


    const activateUser = () => {
        props.getActivateUser(props.adminUserView.id)
    }
    const blockUser = () => {
        props.getBlockUser(props.adminUserView.id)
    }

    const handleAdminRole = (e) => {
        if (!e.currentTarget.checked) {

           props.getAdminUserDelAdmin(props.adminUserView.id)
            setRole(!role)

        } else {

           props.getAdminUserSetAdmin(props.adminUserView.id,)
            setRole(!role)
        }
    }

    return <Box sx={{width: "500px", backgroundColor: "white"}}>
        <React.Fragment>
            <Formik initialValues={{
                name:props.adminUserView.name,
                surname: props.adminUserView.surname,
                email: props.adminUserView.email,
                phone:props.adminUserView.phone,
                create_at:props.adminUserView.created_at,
                statusName: props.adminUserView.status_name,
                subscribe_news:props.adminUserView.subscribe_news,
                userType:props.adminUserView.typeObject ? props.adminUserView.typeObject.name : ""
            }}
                    onSubmit={(values) => {

                    }}>
                {({values, setFieldValue}) => (

                    <Form className={s.userViewForm}>
                        <h3>User {editMode ? "update" : "info"}</h3>
                        <Typography sx={{fontSize: 14}} gutterBottom>
                            Name
                        </Typography>
                        <Typography sx={{fontSize: 18}}  gutterBottom>
                            {!editMode && props.adminUserView.name}
                        </Typography>
                        {editMode &&
                            <Field disabled={true}
                                   type={"text"}
                                   name={"name"}
                                   value={values.name}/>}
                        {/*=========================================================================================*/}
                        <Typography sx={{fontSize: 14}} gutterBottom>
                            Surname
                        </Typography>
                        <Typography sx={{fontSize: 18}} gutterBottom>
                            {!editMode && props.adminUserView.surname}
                        </Typography>
                        {editMode &&
                            <Field disabled={true}
                                   type={"text"}
                                   name={"surname"}
                                   value={values.surname}/>}
                        {/*=========================================================================================*/}
                        <Typography sx={{fontSize: 14}} gutterBottom>
                            Email
                        </Typography>
                        <Typography sx={{fontSize: 18}} variant="h5" component="div" gutterBottom>
                            {!editMode && props.adminUserView.email}
                        </Typography>
                        {editMode &&
                            <Field disabled={true}
                                   type={"text"}
                                   name={"email"}
                                   value={values.email}/>}
                        {/*=========================================================================================*/}
                        <Typography sx={{fontSize: 14}} gutterBottom>
                            Phone
                        </Typography>
                        <Typography sx={{mb: 1.5, fontSize: 18}} gutterBottom>
                            {!editMode && props.adminUserView.phone}
                        </Typography>
                        {editMode &&
                            <Field disabled={true}
                                type={"phone"}
                                name={"phone"}
                                value={values.phone}/>}
                        {/*=========================================================================================*/}
                        <Typography sx={{fontSize: 14}} gutterBottom>
                            Status
                        </Typography>
                        <Typography sx={{mb: 1.5, fontSize: 18}} variant="body2">
                            {!editMode && props.adminUserView.statusObject.name}
                        </Typography>
                        {editMode && <select  name={"statusName"}
                            onChange={(e) => {

                                if (+e.currentTarget.value === 2) {
                                    blockUser(+e.currentTarget.value)

                                } else {
                                    activateUser(+e.currentTarget.value)

                                }
                            }}
                        >
                            <option>Change status</option>
                            {props.directory.indexStatus.map(status => <option
                                key={status.id} value={status.id}>
                                {status.name}
                            </option>)
                            }
                        </select>}

                        {/*=========================================================================================*/}
                        <Typography sx={{fontSize: 14}} gutterBottom>
                            Subscribe news?
                            {!editMode &&
                                <Checkbox checked={props.adminUserView.subscribe_news}/>}
                            {editMode &&
                                <Checkbox onChange={(e) => {
                                    setFieldValue("subscribe_news", e.currentTarget.checked)
                                }}
                                          name={"subscribeNews"} />}
                        </Typography>
                        {/*<Typography  sx={{ mb: 1.5,fontSize: 18 }} variant="body2">
                            {adminUserView.subscribe_news || "none"}
                        </Typography>*/}
                        {/*{ editMode &&
                            <Field
                                placeholder={adminUserView.statusObject.name}
                                type={"text"}
                                name={"subscribe_news"}
                                value={values.subscribe_news}/>}*/}
                        {/*=========================================================================================*/}
                        <Typography sx={{fontSize: 14}} gutterBottom>
                            User type
                        </Typography>
                        {!editMode &&
                            <Typography sx={{mb: 1.5, fontSize: 18}} variant="body2">
                                {props.adminUserView.typeObject.name || "none"}
                            </Typography>}
                        {editMode &&
                            <select>{props.directory.userType.map(type => <option key={type.id} value={type.id}>
                                {type.name}
                            </option>)
                            }
                            </select>}
                        <Typography>
                            Make admin?
                            <Switch checked={props.adminUserView.role==="admin" && true} onChange={handleAdminRole}/>
                        </Typography>

                        {/* { editMode &&
                            <Field
                                placeholder={adminUserView.typeObject.name}
                                type={"text"}
                                name={"userType"}
                                value={values.userType}/>}*/}
                        {/*=========================================================================================*/}
                        <Typography sx={{fontSize: 14}} gutterBottom>
                            Create at
                        </Typography>
                        <Typography sx={{mb: 1.5, fontSize: 18}} variant="body2">
                            {!editMode && props.adminUserView.created_at}
                        </Typography>
                        {editMode &&
                            <Field disabled={true}
                                   placeholder={props.adminUserView.created_at}
                                   type={"data"}
                                   name={"created_at"}
                                   value={values.created_at}/>}
                        {/*=========================================================================================*/}


                        {/*=========================================================================================*/}
                        {!editMode && <div>
                            {<Button size="small" type="submit" onClick={() => setEditMode(!editMode)}>Edit</Button>}
                            {/*<Button disabled={adminUserView.status===2 && true} size="small" type="submit" onClick={activateUser}>Activate user</Button>
                            <Button disabled={adminUserView.status!==2 && true} size="small" type="submit" onClick={blockUser}>Block user</Button>*/}
                            {/*<Button size="small" type="submit" onClick={handleAdminRole}>{
                                role
                            }</Button>*/}

                        </div>
                        }
                        {editMode && <div>
                            <Button size="small" type="submit" onClick={() => setEditMode(!editMode)}>Cancel</Button>
                            <Button size="small" type="submit" onClick={() => setEditMode(!editMode)}>Save</Button>
                        </div>}
                    </Form>)}
            </Formik>
            <CardActions>

            </CardActions>
        </React.Fragment>
    </Box>


}
export default AdminUsersView
