import {Paper, TableCell, TableHead, TableRow} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import editImg from "../../../../../assets/img/pencil.png";
import {NavLink} from "react-router-dom";
import s from "../../../Admin.module.css"

const SeoPagesList = ({seoPagesList}) => {

    return <div>
        <TableContainer component={Paper}>
            <Table sx={{width: '1180px'}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Link</TableCell>
                        <TableCell align="right">id</TableCell>
                        <TableCell align="right">Code</TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell align="right">Edit</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {seoPagesList.map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell>Link</TableCell>
                            <TableCell align="right">{row.id}</TableCell>
                            <TableCell align="right">{row.code}</TableCell>
                            <TableCell align="right">{row.status}</TableCell>
                            <TableCell align="right">
                                <NavLink className={s.editImage} to={`/admin_cabinet/pages/pages_view/${row.id}`}>
                                <img src={editImg} alt="edit"/></NavLink>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

    </div>
}
export default SeoPagesList