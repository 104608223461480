import Card from "@mui/material/Card";
import {CardActions, CardContent, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {useState} from "react";


const AdminOrderView = (props) => {
    const [editMode, setEditMode] = useState(false)
    const cancelOrder = () => {
        props.getOrderCancel(props.adminOrderView.id)
    }
    const updateOrder = (event) => {
        let values = event.currentTarget.form
        let updateInfo = {
            "date": values[0].value,
            "time": values[1].value,
            "status": values[2].value,
            "pay_status": values[3].value
        }
        props.getOrderUpdate(props.adminOrderView.id, updateInfo)
    }
    const updateCustomerOrder = (event) => {
      let values = event.currentTarget.form
        let updateInfo = {
          "surname": values[0].value,
          "name": values[1].value,
          "phone": values[2].value,
          "email":values[3].value,
        }
        props.getOrderUpdateCustomer(props.adminOrderView.id, updateInfo)
    }
    const deleteOrder = () => {
        props.getDeleteOrder(props.adminOrderView.id)
    }
    return <div>
        <Card sx={{minWidth: 275}}>
            <CardContent>
                <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                    Name <br/>
                    {props.adminOrderView.service.name}
                </Typography>
                <Typography variant="h5" component="div">
                    Create at <br/>
                    {props.adminOrderView.created_at}
                </Typography>
                <Typography sx={{mb: 1.5}} color="text.secondary">
                    Service date <br/>
                    {props.adminOrderView.service_date}
                </Typography>
                <Typography variant="body2">
                    Status
                    <br/>
                    {props.adminOrderView.statusObject.name}
                </Typography>
            </CardContent>
            <CardActions>
                <Button onClick={cancelOrder} size="small">Cancel order</Button>
                <Button onClick={deleteOrder} size="small">Delete</Button>
                <Button onClick={()=> setEditMode(!editMode)} size="small">Edit</Button>

            </CardActions>
          <CardActions>
            {editMode && <form>
                    <input name={"date"} type="date"/>
                    <input name={"time"} type="time"/>
                    <input name={"status"} type="number"/>
                    <input name={"pay_status"} type="number"/>
                    <Button onClick={updateOrder} type={"submit"} size="small">Update order</Button>
                </form>}
              {editMode && <form>
                <input name={"surname"} type="text" placeholder={"surname"}/>
                <input name={"name"} type="text" placeholder={"name"}/>
                <input name={"phone"} type="phone" placeholder={"phone"}/>
                <input name={"email"} type="text" placeholder={"email"}/>
                <Button onClick={updateCustomerOrder} type={"submit"} size="small">Update order customer</Button>
              </form>}
            </CardActions>
        </Card>
    </div>
}
export default AdminOrderView