import React from "react";
import {Outlet} from "react-router";
import "./AdminUsers.css"




const AdminUsers = () => {

    return  <Outlet/>
}
export default AdminUsers