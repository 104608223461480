import { affiliatePrograms } from '../api/apiAdmin'

const SET_AFFILIATE_PROGRAM = 'tour2sky-project/affiliate/SET_AFFILIATE_PROGRAM'
const SET_AFFILIATE_META = 'tour2sky-project/affiliate/SET_AFFILIATE_META'
const SET_AFFILIATE_VIEW = 'tour2sky/admin/affiliate/SET_AFFILIATE_VIEW'
const SET_AFFILIATE_DELETE = 'tour2sky/admin/affiliate/SET_AFFILIATE_DELETE'
const SET_AFFILIATE_SELECT = 'tour2sky/admin/affiliate/SET_AFFILIATE_SELECT'

let initialState = {
	programs: [],
	metaPrograms: {},
	affiliateView: {},
	affiliateSelect: [],
}

export const setAffiliateProgram = data => ({
	type: SET_AFFILIATE_PROGRAM,
	data,
})
export const setAffiliateMeta = data => ({
	type: SET_AFFILIATE_META,
	data,
})
export const setAffiliateView = data => ({
	type: SET_AFFILIATE_VIEW,
	data,
})
export const setAffiliateDelete = data => ({ type: SET_AFFILIATE_DELETE, data })
export const setAffiliateSelect = data => ({ type: SET_AFFILIATE_SELECT, data })

const affiliateProgramsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_AFFILIATE_PROGRAM: {
			return {
				...state,
				programs: action.data,
			}
		}
		case SET_AFFILIATE_META: {
			return {
				...state,
				metaPrograms: action.data,
			}
		}
		case SET_AFFILIATE_VIEW: {
			return {
				...state,
				affiliateView: action.data,
			}
		}
		case SET_AFFILIATE_DELETE: {
			return {
				...state,
				programs: state.programs.filter(item => item.id !== action.data),
			}
		}
		case SET_AFFILIATE_SELECT: {
			return {
				...state,
				affiliateSelect: action.data,
			}
		}
		default:
			return state
	}
}

export const getAffiliatePrograms =
	(page, perPage, order_by, order_direction) => async dispatch => {
		try {
			let response = await affiliatePrograms.getAffiliatePrograms(
				page,
				perPage,
				order_by,
				order_direction
			)
			console.log('response: ', response)
			dispatch(setAffiliateProgram(response.data.data))
			dispatch(setAffiliateMeta(response.data.meta))
		} catch (e) {
			console.log(e)
		}
	}

export const createAffiliateProgram =
	(formData, setStatus, setDone, affiliateId) => async dispatch => {
		try {
			let response = await affiliatePrograms.createUpdateAffiliateProgram(
				formData,
				affiliateId
			)
			dispatch(setAffiliateProgram(response.data))
			setStatus({})
			setDone(true)
		} catch (error) {
			let errors = error.response
			setStatus({ error: errors.data.errors })
		}
	}

export const getAffiliateProgramView = affiliateId => async dispatch => {
	let response = await affiliatePrograms.getAffiliateView(affiliateId)
	dispatch(setAffiliateView(response.data))
}

export const getDeleteAffiliate = affiliateId => async dispatch => {
	try {
		await affiliatePrograms.getDelete(affiliateId)
		dispatch(setAffiliateDelete(affiliateId))
	} catch (e) {}
}
export const getForSelect = () => async dispatch => {
	try {
		let response = await affiliatePrograms.getForSelect()
		dispatch(setAffiliateSelect(response.data.result))
	} catch (e) {}
}

export default affiliateProgramsReducer
