import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import { Field, FieldArray, Form, Formik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { NavLink } from 'react-router-dom'
import Select from 'react-select'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { adminService } from '../../../../../api/apiAdmin'
import StatusSelect from '../../../../../assets/common/StatusSelect/StatusSelect'
import delImg from '../../../../../assets/img/delimg.png'
import { findActivityLocationList } from '../../../../../redux/activityLocation_Reducer'
import { getAttributesList } from '../../../../../redux/adminAttributes_Reducer'
import {
	getActivateServices,
	getAllServiceTypes,
	getBlockServices,
	getCreateUpdateServices,
	getDeleteServices,
	getServicesView,
} from '../../../../../redux/adminServices_Reducer'
import { getForSelectPartner } from '../../../../../redux/adminUsers&Partners_Reducer'
import { getForSelect } from '../../../../../redux/affiliate-program'
import style from '../../../../AuthProfiles.module.css'
import s from '../../../Admin.module.css'
import styleS from '../AdminService.module.css'


const CreateService = () => {
	let params = useParams()
	let serviceId = params.serviceId
	let initialValues
	const dragStartIndex = useRef(null)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const getActivityLocationsList = useSelector(() => findActivityLocationList)
	const getServiceTypes = useSelector(() => getAllServiceTypes)
	const activityLocation = useSelector(
		state => state.activityLocation.activityLocationList
	)
	const selectPartners = useSelector(state => state.admin.selectPartners)
	const allServiceTypes = useSelector(state => state.adminServices.serviceTypes)
	const allAffiliateSelect = useSelector(
		state => state.affiliate.affiliateSelect
	)

	const getCreateAdmin = useSelector(() => getCreateUpdateServices)
	const getService = useSelector(() => getServicesView)
	const getAttributes = useSelector(() => getAttributesList)
	const getForSelectAffiliatePrograms = useSelector(() => getForSelect)
	const [done, setDone] = useState(false)
	const [dragblImage, setDragblImage] = useState([])
	const [newImage, setNewImage] = useState([])
	const [loadingActivityLocations, setLoadingActivityLocations] =
		useState(false)

	const [openDialog, setOpenDialog] = useState(false)
	const [deleteItemId, setDeleteItemId] = useState('')

	const getActive = useSelector(() => getActivateServices)
	const getBlock = useSelector(() => getBlockServices)
	const getDelete = useSelector(() => getDeleteServices)
	const adminServicesView = useSelector(
		state => state.adminServices.adminServicesView
	)
	const getForSelectAllPartner = useSelector(() => getForSelectPartner)

	const activitySelectOptions = activityLocation.map(aL => {
		return { value: aL.id, label: aL.name }
	})
	const allAffiliateSelectOptions = allAffiliateSelect.map(aL => {
		return { value: aL.id, label: aL.name }
	})

	const sellerTagOptions = [
		{ value: '', label: 'Nothing' },
		{ value: '1', label: 'Best seller' },
		{ value: '2', label: 'High demand' },
	]
	const [offset, setOffset] = useState(0)

	// Обрахунок максимальної кількості зсувів
	const maxOffset = dragblImage && dragblImage.length - 4

	useEffect(() => {
		setDragblImage(adminServicesView.images?.data)
	}, [adminServicesView.images?.data])

	useEffect(() => {
		dispatch(getForSelectAllPartner())
		dispatch(getServiceTypes())
		dispatch(getForSelectAffiliatePrograms())
	}, [])

	const handleClickOpen = id => {
		setOpenDialog(true)
		setDeleteItemId(id)
	}
	const handleClose = () => {
		setOpenDialog(false)
	}

	const onChangeInputSelect = value => {
		setLoadingActivityLocations(true)

		if (value.length >= 3) {
			dispatch(getActivityLocationsList(value)).then(() => {
				setLoadingActivityLocations(false)
			})
		} else {
			setLoadingActivityLocations(false)
		}
	}

	let dayOfMonthOptions = []
	const dayOptionsCreator = options => {
		for (let i = 1; i <= 31; i++) {
			options.push({ value: i, label: i })
		}
		return options
	}
	dayOptionsCreator(dayOfMonthOptions)

	useEffect(() => {
		if (serviceId) {
			dispatch(getService(serviceId))
		}
		dispatch(getAttributes())
	}, [])
	const attributesList = useSelector(state => state.attributes.attributeList)
	let location_name = activityLocation.find(
		i => i.id === adminServicesView.activity_location_id
	)

	const updateForSelectPartner = [
		{ id: '', name: 'Choose a partner' },
		...selectPartners,
	].map(selectPartner => ({
		value: selectPartner.id,
		label: selectPartner.name,
	}))

	if (serviceId) {
		initialValues = {
			user_id: '',
			name: adminServicesView?.name || '',
			slug: adminServicesView?.link || '',
			duration: adminServicesView?.duration || '',
			price: adminServicesView?.price || '',
			currency_id: adminServicesView?.currency_id || 1,
			activity_location_id: adminServicesView?.activity_location_id || '',
			activity_location_name:
				location_name?.name || adminServicesView.activity_location_name || '',
			description: adminServicesView?.description || '',
			cancel_info: adminServicesView?.cancel_info || '',
			members_count: adminServicesView?.members_count || '',
			images: '',
			booking_link: adminServicesView?.booking_link || '',
			maps_link: adminServicesView?.maps_link || '',
			//times:adminServicesView?.times?.data || [],
			attributes: adminServicesView?.attributes?.data || [],
			seo_title: adminServicesView?.seo_title || '',
			seo_description: adminServicesView?.seo_description || '',
			seo_image: adminServicesView?.seo_image || '',
			seller_tag: adminServicesView?.seller_tag || '',
			partner_id: adminServicesView?.partner_id || '',
			type: adminServicesView?.type || '',
			affiliate_program_id: adminServicesView?.affiliate_program_id || '',
		}
	} else {
		initialValues = {
			user_id: '',
			name: '',
			duration: '',
			price: '',
			slug: '',
			currency_id: 1,
			activity_location_id: '',
			activity_location_name: '',
			description: '',
			cancel_info: '',
			members_count: '',
			images: '',
			booking_link: '',
			maps_link: '',
			//times:[],
			attributes: [],
			seo_title: '',
			seo_description: '',
			seo_image: '',
			seller_tag: '',
			partner_id: '',
			type: '',
			affiliate_program_id: '',
		}
	}

	const handleDrop = (child, blockIndex) => {
		const dropId = child.id
		const toIndex = dragblImage.findIndex(block => block.id === dropId) // Змінено на пошук у всьому списку зображень
		const toBlock = dragblImage[toIndex]

		if (dragStartIndex.current !== null && dragStartIndex.current !== toIndex) {
			const fromIndex = dragStartIndex.current
			const fromBlock = dragblImage[fromIndex]

			let updateArray = [...dragblImage] // Копія основного масиву
			updateArray[fromIndex] = toBlock // Міняємо місцями блоки
			updateArray[toIndex] = fromBlock
			setDragblImage(updateArray) // Оновлюємо стан
			dragStartIndex.current = null // Скидаємо індекс
		}
	}

	const handleDragStart = index => {
		dragStartIndex.current = index
	}

	const handlePrev = () => {
		if (offset > 0) {
			setOffset(offset - 1)
		}
	}

	const handleNext = () => {
		if (offset < maxOffset) {
			setOffset(offset + 1)
		}
	}

	const handelDeleteServiceImage = async (imageId, serviceId) => {
		const response = await adminService.deleteServiceImage(imageId, serviceId)

		const dublicateArray = [...dragblImage]
		if (response.status == 200) {
			const filterArray = dublicateArray.filter(item => item?.id !== imageId)
			setDragblImage(filterArray)
			handlePrev()
		}
	}

	useEffect(() => {
		const handleSendImage = async () => {
			if (!!newImage.length && serviceId) {
				const newFormData = new FormData()
				const arrImages = [...newImage]
				if (arrImages && arrImages.length > 0) {
					arrImages.forEach(file => {
						newFormData.append(`images[]`, file)
					})
				}
				newFormData.append(`serviceId`, serviceId)
				const response = await adminService.addServiceImage(newFormData)
				if (response.status == 200) {
					dispatch(getService(serviceId))
					setNewImage([])
				}
			}
		}

		handleSendImage()
	}, [newImage])

	console.log('allServiceTypes', allServiceTypes)

	const serviceTypesArray = allServiceTypes.map(value => {
		return { value: value.id, label: value.label }
	})

	console.log('serviceTypesArray', serviceTypesArray)

	if (done) {
		return <div className={s.createComplete}>Successful!</div>
	} else {
		return (
			<div className={s.formContainer}>
				<div className={s.wrapper}>
					<h4>{serviceId ? 'Update' : 'Create'} service</h4>
					<Formik
						enableReinitialize
						initialValues={initialValues}
						onSubmit={(values, submitProps) => {
							const newFormData = new FormData()
							newFormData.append('user_id', localStorage.getItem('user_id'))
							newFormData.append('name', values.name)
							newFormData.append('duration', values.duration)
							newFormData.append('price', values.price)
							newFormData.append('currency_id', values.currency_id)
							newFormData.append('partner_id', values.partner_id)
							newFormData.append('partner_id', values.partner_id)
							newFormData.append(
								'affiliate_program_id',
								values.affiliate_program_id
							)
							newFormData.append(
								'activity_location_id',
								values.activity_location_id
							)
							newFormData.append('description', values.description)
							newFormData.append('cancel_info', values.cancel_info)
							newFormData.append('booking_link', values.booking_link)
							newFormData.append('maps_link', values.maps_link)
							newFormData.append('members_count', values.members_count)
							newFormData.append('seo_description', values.seo_description)
							newFormData.append('seo_title', values.seo_title)
							newFormData.append('seller_tag', values.seller_tag)
							newFormData.append('type', values.type)
							if (serviceId && dragblImage && dragblImage.length > 0) {
								dragblImage.forEach((image, index) => {
									newFormData.append(
										`dragbl_images[${index}]`,
										JSON.stringify(image)
									)
								})
							}

							const arrImages = [...values.images]
							if (!serviceId && arrImages && arrImages.length > 0) {
								arrImages.forEach(file => {
									newFormData.append(`images[]`, file)
								})
							}

							/*values.times.map((times, index) => {
                                for (const [key, value] of Object.entries(times)) {
                                    if (key === "day_of_month") {
                                        newFormData.append(`times[${index}][day_of_month]`, +value)
                                    } else if (key === "month") {
                                        newFormData.append(`times[${index}][month]`, +value)
                                    } else if (key === "day_of_week") {
                                        newFormData.append(`times[${index}][day_of_week]`, +value)
                                    } else if (key === "date") {
                                        newFormData.append(`times[${index}][date]`, value)
                                    } else if (key === "time") {
                                        newFormData.append(`times[${index}][time]`, value)
                                    }
                                }
                            })*/
							values.attributes.map((attribute, index) => {
								for (const [key, value] of Object.entries(attribute)) {
									if (key === 'attribute_id') {
										newFormData.append(
											`attributes[${index}][attribute_id]`,
											value
										)
									} else if (key === 'value') {
										newFormData.append(`attributes[${index}][value]`, value)
									} else if (key === 'description') {
										newFormData.append(
											`attributes[${index}][description]`,
											value
										)
									}
								}
							})

							dispatch(
								getCreateAdmin(
									newFormData,
									submitProps.setStatus,
									setDone,
									serviceId
								)
							)
						}}
					>
						{({ values, status, setFieldValue }) => {
							const imagesErrorFuncReturn = status => {
								let arrayImagesErrors = []
								for (let i = 0; i < 10; i++) {
									arrayImagesErrors.push(status.error[`images.${i}`])
								}
								return arrayImagesErrors
							}

							return (
								<div className={s.formContainer}>
									<Dialog
										open={openDialog}
										onClose={handleClose}
										aria-labelledby='alert-dialog-title'
										aria-describedby='alert-dialog-description'
									>
										<DialogTitle id='alert-dialog-title'>
											{'Really delete?'}
										</DialogTitle>
										<DialogActions>
											<Button onClick={handleClose}>Disagree</Button>
											<Button
												onClick={() => {
													setOpenDialog(false)
													dispatch(getDelete(deleteItemId))
													navigate('/admin_cabinet/service')
												}}
												autoFocus
											>
												Agree
											</Button>
										</DialogActions>
									</Dialog>
									<NavLink
										to={'#'}
										style={{ display: 'flex', justifyContent: 'flex-end' }}
									>
										<img
											onClick={() => {
												handleClickOpen(serviceId)
											}}
											className={s.editDelImg}
											src={delImg}
											alt='del'
										/>
									</NavLink>
									<Form>
										<div className={s.formContainer}>
											<div className={s.createBlocks}>
												<div className={s.serviceCreateItem}>
													<label>
														Service name
														{status?.error.name && (
															<div className={style.error}>
																{status.error.name}
															</div>
														)}
														<Field
															name={'name'}
															de={values.name}
															type='text'
															placeholder={'name'}
														/>
													</label>
												</div>
												{serviceId && (
													<div className={s.serviceCreateItem}>
														<label>
															{' '}
															Slug
															<Field
																disabled
																name={'slug'}
																value={values.slug}
																type={'text'}
															/>
														</label>
													</div>
												)}
												{serviceId && (
													<div className={s.serviceCreateItem}>
														<StatusSelect
															active={getActive}
															block={getBlock}
															elementInfo={adminServicesView}
														/>
													</div>
												)}

												<div className={s.serviceCreateItem}>
													<label>
														Activity in location (enter more than 3 letters)
														{status?.error.activity_location_id && (
															<div className={style.error}>
																{status.error.activity_location_id}
															</div>
														)}
														<Select
															isClearable
															isLoading={loadingActivityLocations}
															styles={{
																control: (base, state) => ({
																	...base,
																	minWidth: '250px',
																	//background: "#295673FF",
																	// Overwrites the different states of border
																	borderColor: state.isFocused
																		? '#33cccc'
																		: '#295673FF',
																	// Removes weird border around container
																	boxShadow: state.isFocused ? null : null,
																	'&:hover': {
																		// Overwrittes the different states of border
																		// borderColor: state.isFocused ? "red" : "blue"
																	},
																}),
															}}
															value={[
																{
																	value: values.activity_location_id || '',
																	label: values.activity_location_name || '',
																},
															]}
															options={activitySelectOptions}
															onInputChange={value =>
																onChangeInputSelect(value)
															}
															onChange={event => {
																setFieldValue(
																	'activity_location_id',
																	event?.value || ''
																)
																setFieldValue(
																	'activity_location_name',
																	event?.label || ''
																)
															}}
														></Select>
													</label>
												</div>
											</div>
											<div className={s.createBlocks}>
												<div className={s.serviceCreateItem}>
													<label>
														Members
														{status?.error.members_count && (
															<div className={style.error}>
																{status.error.members_count}
															</div>
														)}
														<Field
															value={values.members_count}
															name={'members_count'}
															type='number'
															min={1}
															placeholder={'Members count '}
														/>
													</label>
												</div>
												<div className={s.serviceCreateItem}>
													<label>
														Duration in minutes.
														{status?.error.duration && (
															<div className={style.error}>
																{status.error.duration}
															</div>
														)}
														<Field
															value={values.duration}
															name={'duration'}
															type='number'
															min={1}
															placeholder={'Duration'}
														/>
													</label>
												</div>
												<div className={s.serviceCreateItem}>
													<label>
														Attributes
														{status?.error.attributes && (
															<div className={style.error}>
																{status.error.attributes}
															</div>
														)}
														<FieldArray
															value={values.attributes}
															name={'attributes'}
															type='text'
															placeholder={'Attributes'}
														>
															{({ remove, push }) => (
																<div>
																	{values.attributes.length > 0 &&
																		values.attributes.map(
																			(attributes, index) => (
																				<label className='row' key={index}>
																					<select
																						onChange={event => {
																							let sendAttribute =
																								attributesList.find(
																									item =>
																										+item.id ===
																										+event.currentTarget.value
																								)
																							setFieldValue(
																								`attributes.${index}`,
																								{
																									attribute_id:
																										sendAttribute.id,
																									value: sendAttribute.name,
																									description:
																										sendAttribute.description ||
																										'',
																								}
																							)
																						}}
																						name={`attributes.${index}`}
																					>
																						<option>Select attributes</option>
																						{attributesList.map(attribute => (
																							<option
																								value={attribute.id}
																								key={attribute.id}
																							>
																								{attribute.name}
																							</option>
																						))}
																					</select>

																					<div className='col'>
																						<button
																							type='button'
																							className={s.secondary}
																							onClick={() => remove(index)}
																						>
																							X
																						</button>
																					</div>
																				</label>
																			)
																		)}
																	<button
																		type='button'
																		className={s.secondary}
																		onClick={() =>
																			push({
																				attribute_id: '',
																				value: '',
																				description: '',
																			})
																		}
																	>
																		Add attributes
																	</button>
																</div>
															)}
														</FieldArray>
													</label>
												</div>
												<div className={s.serviceCreateItem}>
													<label>
														Price
														{status && status.error.price && (
															<div className={style.error}>
																{status.error.price}
															</div>
														)}
														<Field
															min={1}
															value={values.price}
															name={'price'}
															type='number'
															placeholder={'price'}
														/>
													</label>
												</div>
												<div className={s.serviceCreateItem}>
													<label>
														Currency
														{status && status.error.currency_id && (
															<div className={style.error}>
																{status.error.currency_id}
															</div>
														)}
														<select
															defaultValue={1}
															onChange={event => {
																setFieldValue(
																	'currency_id',
																	+event.currentTarget.value
																)
															}}
														>
															<option value={1}>{'USD'}</option>
														</select>
													</label>
												</div>
												<div className={s.serviceCreateItem}>
													<label>
														Cancel info
														{status?.error.cancel_info && (
															<div className={style.error}>
																{status.error.cancel_info}
															</div>
														)}
														<Field
															name={'cancel_info'}
															value={values.cancel_info}
															placeholder={'cancel_info'}
														/>
													</label>
												</div>
												<div className={s.serviceCreateItem}>
													<label>
														Add images (max 10)
														{status?.error && (
															<div className={style.error}>
																{imagesErrorFuncReturn(status)}
															</div>
														)}
														<input
															id='images'
															multiple
															max={10}
															name='images'
															type='file'
															accept='.png,.jpeg,.jpg'
															onChange={event => {
																setNewImage(event.currentTarget.files)
																setFieldValue(
																	'images',
																	event.currentTarget.files
																)
															}}
														/>
													</label>
												</div>
												<div className={s.serviceCreateItem}>
													<label>
														Seller Tag
														<Select
															options={sellerTagOptions}
															styles={{
																control: (base, state) => ({
																	...base,
																	minWidth: '250px',
																	//background: "#295673FF",
																	// Overwrites the different states of border
																	borderColor: state.isFocused
																		? '#33cccc'
																		: '#295673FF',
																	// Removes weird border around container
																	boxShadow: state.isFocused ? null : null,
																	'&:hover': {
																		// Overwrittes the different states of border
																		// borderColor: state.isFocused ? "red" : "blue"
																	},
																}),
															}}
															value={sellerTagOptions.find(
																option => option.value == values.seller_tag
															)}
															onChange={option =>
																setFieldValue('seller_tag', option.value)
															}
														/>
													</label>
												</div>
												{/* {serviceId && (
                          <Swiper
                            pagination={{
                              type: "fraction",
                            }}
                            // slidesPerView={4}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className={s.serviceCreateItemImageSwiper}
                          >
                            {adminServicesView.images?.data.map((i) => (
                              <SwiperSlide key={i.id}>
                                <img src={i.thumb} alt="thumb" />
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        )} */}
												{serviceId && (
													<div className={styleS.dragblImageWrap}>
														<div
															className={styleS.navButton}
															onClick={handlePrev}
															disabled={offset === 0}
														>
															<IoIosArrowBack />
														</div>
														{dragblImage &&
															!!dragblImage.length &&
															dragblImage
																.slice(offset, offset + 4)
																.map((i, index) => (
																	<div
																		key={i.id}
																		className={styleS.dragblImageBlock}
																	>
																		<img
																			src={i.thumb}
																			alt='thumb'
																			className={styleS.dragblImage}
																			draggable={true}
																			onDragStart={() =>
																				handleDragStart(index + offset)
																			}
																			onDrop={() =>
																				handleDrop(i, index + offset)
																			}
																			onDragOver={e => e.preventDefault()}
																		/>
																		<div className={styleS.deleteButtonWrap}>
																			<div
																				onClick={() =>
																					handelDeleteServiceImage(
																						i.id,
																						serviceId ?? null
																					)
																				}
																			>
																				Delete
																			</div>
																		</div>
																	</div>
																))}
														<div
															className={styleS.navButton}
															onClick={handleNext}
															disabled={offset >= maxOffset}
														>
															<IoIosArrowForward />
														</div>
													</div>
												)}
											</div>
											<div className={s.createBlocks}>
												<div className={s.serviceCreateItem}>
													<label>
														Booking link
														<Field
															name={'booking_link'}
															placeholder={'Booking link '}
														/>
													</label>
												</div>
												<div className={s.serviceCreateItem}>
													<label>
														Google maps link
														<Field
															name={'maps_link'}
															placeholder={'Maps link'}
														/>
													</label>
												</div>
											</div>
											<div className={s.createBlocks}>
												<div className={s.serviceCreateItem}>
													<label>
														SEO Title
														<Field
															value={values.seo_title}
															name={'seo_title'}
															type='text'
															placeholder={'seo_title '}
														/>
													</label>
												</div>
												<div className={s.serviceCreateItem}>
													<label>
														SEO description
														<Field
															value={values.seo_description}
															name={'seo_description'}
															type='text'
															placeholder={'seo_description '}
														/>
													</label>
												</div>
												<div className={s.serviceCreateItem}>
													<label>
														Description
														{status?.error.description && (
															<div className={style.error}>
																{status.error.description}
															</div>
														)}
														<Field
															as={'textarea'}
															value={values.description}
															name={'description'}
															placeholder={'Description'}
															cols={33}
														/>
													</label>
												</div>
												<div className={style.serviceCreateItem}>
													<label>
														Choose a partner
														{status?.error.partner_id && (
															<div className={style.error}>
																{status.error.partner_id}
															</div>
														)}
														<Select
															required
															isClearable
															onChange={e => {
																setFieldValue('partner_id', e.value)
															}}
															value={updateForSelectPartner.find(
																option =>
																	option.value.toString() ===
																	values.partner_id.toString()
															)}
															options={updateForSelectPartner}
															styles={{
																control: (base, state) => ({
																	...base,
																	minWidth: '250px',
																	//background: "#295673FF",
																	// Overwrites the different states of border
																	borderColor: state.isFocused
																		? '#33cccc'
																		: '#295673FF',
																	// Removes weird border around container
																	boxShadow: state.isFocused ? null : null,
																	'&:hover': {
																		// Overwrittes the different states of border
																		// borderColor: state.isFocused ? "red" : "blue"
																	},
																}),
															}}
														></Select>
													</label>
												</div>
												<div className={style.serviceCreateItem}>
													<label>
														Choose a type service
														{status?.error.type && (
															<div className={style.error}>
																{status.error.type}
															</div>
														)}
														<Select
															required
															isClearable
															onChange={e => {
																setFieldValue('type', e.value)
															}}
															value={serviceTypesArray.find(
																option =>
																	option.value.toString() ===
																	values.type.toString()
															)}
															options={serviceTypesArray}
															styles={{
																control: (base, state) => ({
																	...base,
																	minWidth: '250px',
																	//background: "#295673FF",
																	// Overwrites the different states of border
																	borderColor: state.isFocused
																		? '#33cccc'
																		: '#295673FF',
																	// Removes weird border around container
																	boxShadow: state.isFocused ? null : null,
																	'&:hover': {
																		// Overwrittes the different states of border
																		// borderColor: state.isFocused ? "red" : "blue"
																	},
																}),
															}}
														></Select>
													</label>
												</div>
												<div className={style.serviceCreateItem}>
													<label>
														Choose an affiliate program
														{status?.error.affiliate_program_id && (
															<div className={style.error}>
																{status.error.affiliate_program_id}
															</div>
														)}
														<Select
															required
															isClearable
															onChange={(e) => {
																setFieldValue('affiliate_program_id', e.value);
															}}
															value={
																values.affiliate_program_id
																	? allAffiliateSelectOptions.find(
																		(option) =>
																			option.value.toString() === values.affiliate_program_id.toString()
																	)
																	: null
															}
															options={[
																{ value: "", label: 'None' },
																...allAffiliateSelectOptions
															]}															styles={{
																control: (base, state) => ({
																	...base,
																	minWidth: '250px',
																	//background: "#295673FF",
																	// Overwrites the different states of border
																	borderColor: state.isFocused
																		? '#33cccc'
																		: '#295673FF',
																	// Removes weird border around container
																	boxShadow: state.isFocused ? null : null,
																	'&:hover': {
																		// Overwrittes the different states of border
																		// borderColor: state.isFocused ? "red" : "blue"
																	},
																}),
															}}
														></Select>
													</label>
												</div>
											</div>
											{/*Times start*/}
											{/*<div className={s.createBlocks}>
                                        <div className={s.serviceCreateItem}>
                                            <label>Times
                                                <FieldArray value={values.times} name={"times"} type="text" placeholder={"times"}>
                                                    {({remove, push}) => (
                                                        <div>
                                                            {values.times.length > 0 &&
                                                                values.times.map((times, index) => (
                                                                    <label className="row" key={index}>
                                                                        <div className="col">
                                                                            <label>Month
                                                                                <Select name={`times.${index}.month`}
                                                                                        options={monthOptions}
                                                                                        value={[{
                                                                                            value:values.times[index]?.month || "",
                                                                                            label:values.times[index]?.month || "",
                                                                                        }]}
                                                                                        styles={{control: (base) => ({
                                                                                                ...base,
                                                                                                minWidth:"250px",
                                                                                            })}}
                                                                                        isClearable
                                                                                        onChange={(e) => {
                                                                                            setFieldValue(`times.${index}.month`, e?.value || "")
                                                                                        }}/>
                                                                                </label>


                                                                        </div>
                                                                        <div className="col">
                                                                            <label>Day of month
                                                                                <Select name={`times.${index}.day_of_month`}
                                                                                        options={dayOfMonthOptions}
                                                                                        value={[{
                                                                                            value:values.times[index]?.day_of_month || "",
                                                                                            label:values.times[index]?.day_of_month || "",
                                                                                        }]}
                                                                                        styles={{control: (base) => ({
                                                                                                ...base,
                                                                                                minWidth:"250px",
                                                                                            })}}
                                                                                        isClearable
                                                                                        onChange={(e) => {
                                                                                            setFieldValue(`times.${index}.day_of_month`, e?.value || "")
                                                                                        }}/>
                                                                               </label>
                                                                        </div>
                                                                        <div className="col">
                                                                            <label>Day of week
                                                                                <Select name={`times.${index}.day_of_week`}
                                                                                        options={weekDayOptions}
                                                                                        value={[{
                                                                                             value:values.times[index]?.day_of_week || "",
                                                                                             label:values.times[index]?.day_of_week || "",
                                                                                         }]}
                                                                                        styles={{control: (base) => ({
                                                                                                ...base,
                                                                                                minWidth:"250px",
                                                                                            })}}
                                                                                        isClearable
                                                                                        onChange={(e) => {
                                                                                            setFieldValue(`times.${index}.day_of_week`, e?.value || "")
                                                                                        }}/>
                                                                                </label>

                                                                        </div>
                                                                        <div className="col">
                                                                            <label>Date
                                                                                <Field value={values.times[index].date}
                                                                                       name={`times.${index}.date`}
                                                                                       placeholder="date"
                                                                                       type="date"/>
                                                                             </label>
                                                                        </div>
                                                                        <div className="col">
                                                                            <label>Time
                                                                                <Field value={values.times[index].time}
                                                                                    name={`times.${index}.time`}
                                                                                    placeholder="time"
                                                                                    type="time"/></label>
                                                                        </div>
                                                                        <div className="col">
                                                                            <button
                                                                                type="button"
                                                                                className={s.secondary}
                                                                                onClick={() => remove(index)}
                                                                            >Clear
                                                                            </button>
                                                                        </div>
                                                                    </label>
                                                                ))}
                                                            <button
                                                                type="button"
                                                                className={s.secondary}
                                                                onClick={() => push({
                                                                    day_of_month: '',
                                                                    month: '',
                                                                    day_of_week: '',
                                                                    date: '',
                                                                    time: ''
                                                                })}
                                                            >Add Times
                                                            </button>
                                                        </div>
                                                    )}
                                                </FieldArray>
                                            </label>
                                        </div>
                                    </div>*/}
											{/*Times end*/}
											<div className={s.createBlocks}>
												<div className={s.serviceCreateItem}>
													<Button
														sx={{ backgroundColor: '#295673', color: 'white' }}
														type='submit'
														size='medium'
													>
														{serviceId ? 'Update' : 'Create'}
													</Button>
												</div>
											</div>
										</div>
									</Form>
								</div>
							)
						}}
					</Formik>
				</div>
			</div>
		)
	}
}
export default CreateService
