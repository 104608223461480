import * as React from "react";
import {Outlet} from "react-router";





const ActivityLocation = () => {

    return <Outlet></Outlet>
}
export default ActivityLocation



