import React, { useState, useEffect } from "react";
import s from "../../../Admin/Admin.module.css";
import { Field, Form } from "formik";
import style from "../../../AuthProfiles.module.css";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import Select from "react-select";
const CreateAdvertisingVisible = ({
  values,
  status,
  setFieldValue,
  imagesErrorFuncReturn,
  advertisingId,
  setIsDeleteImage,
  setImage
}) => {
  const [visibleImage, setVisibleImage] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if(!visibleImage) {
      setVisibleImage(values.banner_image);
    }
  },[values])

  useEffect(() => {
    if(values.is_google_ads == true) {
      setVisible(true);
    }
  },[values.is_google_ads])

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setVisibleImage(imageUrl); // Встановлюємо URL зображення
      setFieldValue("banner_image", file);
      setImage(file);
    }
  };

  const handleChangeVisible = () => {
    setVisible(!visible)
    setFieldValue(
      "is_google_ads",
      values.is_google_ads === "0" ? "1" : "0"
    );
  }

  const handleDeleteImage = () => {
    setIsDeleteImage(true);
    setVisibleImage(null);
    setFieldValue('delete_exists_image',"1")
  }

  return (
    <div className={s.formContainer}>
      <Form>
        <div className={s.formContainer}>
          <div className={s.createBlocks}>
            <div className={s.serviceCreateItem}>
              <label>
                Banner name
                {status?.error.name && (
                  <div className={style.error}>{status.error.name}</div>
                )}
                <Field
                  name={"name"}
                  de={values.name}
                  type="text"
                  placeholder={"name"}
                />
              </label>
            </div>
            <div className={style.serviceCreateItem}>
              <label>
                Show globally
                <Switch
                  checked={values.is_global === "1"}
                  onChange={() => {
                    setFieldValue(
                      "is_global",
                      values.is_global === "0" ? "1" : "0"
                    );
                  }}
                  color="primary"
                  name="checkedB"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </label>
            </div>
          </div>
          <div className={s.createBlocks}>
          <div className={s.serviceCreateItem}>
              <label>
                <div className={s.serviceCreateItem}>
                  <label>
                    Banner image
                    {status?.error && (
                      <div className={style.error}>
                        {imagesErrorFuncReturn(status)}
                      </div>
                    )}
                    <input
                      id="banner_image"
                      name="banner_image"
                      type="file"
                      accept=".png,.jpeg,.jpg"
                      disabled={visible}
                      onChange={handleImageChange}
                    />
                  </label>
                </div>
              </label>
            </div>
          </div>
          <div className={s.createBlocks}>
          <div className={s.serviceCreateItem}>
              <p>Image</p>
              <img src={visibleImage}/>
              <div style={{paddingTop: '20px'}}>
              <Button
                onClick={handleDeleteImage} 
                sx={{ backgroundColor: "#295673", color: "white" }}
                size="medium"
              >
                {"Delete image"}
              </Button>
          </div>
            </div>
          </div>
          <div className={s.createBlocks}>
          <div className={style.serviceCreateItem}>
              <label>
              BannerLink
                <Switch
                  checked={visible}
                  onChange={() => handleChangeVisible()}
                  color="primary"
                  name="checkedB"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                AdSense
              </label>
            </div>
          </div>
          <div className={s.createBlocks}>
          <div className={s.serviceCreateItem}>
              <label>
                Banner code
                {status?.error.adsence_code && (
                  <div className={style.error}>{status.error.adsence_code}</div>
                )}
                <Field
                  value={values.adsence_code}
                  name={"adsence_code"}
                  type="number"
                  min={1}
                  placeholder={"Banner code"}
                  as={"textarea"}
                  className={visible ? s.inputActive : s.inputDisabled}
                  disabled={!visible}
                  style={{
                    // backgroundColor:'white',
                    width: "500px",
                    height: "200px",
                    resize: "none",
                  }}
                />
              </label>
            </div>
            <div className={s.serviceCreateItem}>
              <label>
                Banner link
                {status?.error.link && (
                  <div className={style.error}>{status.error.link}</div>
                )}
                <Field
                  value={values.link}
                  className={!visible ? s.inputActive : s.inputDisabled}
                  disabled={visible}
                  name={"link"}
                  type="text"
                  min={1}
                  placeholder={"Link "}
                />
              </label>
            </div>
          </div>
          <div>
          </div>
          <div className={s.createBlocks}>
            <div className={s.serviceCreateItem}>
              <Button
                sx={{ backgroundColor: "#295673", color: "white" }}
                type="submit"
                size="medium"
              >
                {advertisingId ? "Update" : "Create"}
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CreateAdvertisingVisible;
