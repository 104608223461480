import './App.css'
import './font-awesome.css'
/*import "./responsice.css";*/
import { useEffect } from 'react'
import { Routes } from 'react-router'
import { Navigate, Route, useNavigate } from 'react-router-dom'
import Footer from './Components/Footer/Footer'
import Page404 from './Pages/Page404/Page404'

import { useSelector } from 'react-redux'
import Header from './Components/Header/Header'
import Admin from './Profiles/Admin/Admin'
import ActivityLocation from './Profiles/Admin/AdminPages/ActivityLocation/ActivityLocation'
import ActivityLocationCreate from './Profiles/Admin/AdminPages/ActivityLocation/LocationPages/ActivityLocationCreate'
import ActivityLocationList from './Profiles/Admin/AdminPages/ActivityLocation/LocationPages/ActivityLocationList'
import AdminUsers from './Profiles/Admin/AdminPages/AdminUsers/AdminUsers'
import AdminUsersViewContainer from './Profiles/Admin/AdminPages/AdminUsers/MenuPages/AdminUsersViewContainer'
import AvailableUsers from './Profiles/Admin/AdminPages/AdminUsers/MenuPages/AvailableUsers'
import AdminAdvertising from './Profiles/Admin/AdminPages/Advertising/AdminAdvertising'
import AdvertisingAvailable from './Profiles/Admin/AdminPages/Advertising/AdvertisingAvailable'
import CreateAdvertisingActivityBottom from './Profiles/Admin/AdminPages/Advertising/CreateAdvertisingActivityBottom'
import CreateAdvertisingServiceCard from './Profiles/Admin/AdminPages/Advertising/CreateAdvertisingServiceCard'
import AffiliateCreate from './Profiles/Admin/AdminPages/AffiliatePrograms/AffiliateCreate'
import AffiliatePrograms from './Profiles/Admin/AdminPages/AffiliatePrograms/AffiliatePrograms'
import AffiliateProgramsView from './Profiles/Admin/AdminPages/AffiliatePrograms/AffiliateProgramsView'
import Attributes from './Profiles/Admin/AdminPages/Attributes/Attributes'
import AttributeCreate from './Profiles/Admin/AdminPages/Attributes/Pages/AttributeCreate'
import AttributeList from './Profiles/Admin/AdminPages/Attributes/Pages/AttributeList'
import AdminCategory from './Profiles/Admin/AdminPages/Category/AdminCategory'
import AdminCategoryAvailable from './Profiles/Admin/AdminPages/Category/CategoryPages/AdminCategoryAvailable'
import CreateCategory from './Profiles/Admin/AdminPages/Category/CategoryPages/CreateCategory'
import DashboardContainer from './Profiles/Admin/AdminPages/Dashboard/DashboardContainer'
import AdminOrders from './Profiles/Admin/AdminPages/Orders/AdminOrders'
import AdminOrderViewContainer from './Profiles/Admin/AdminPages/Orders/OrderPages/AdminOrderViewContainer'
import AvailableOrdersContainer from './Profiles/Admin/AdminPages/Orders/OrderPages/AvailableOrdersContainer'
import PartnerCreate from './Profiles/Admin/AdminPages/Partners/CreatePartner'
import PartnerViewContainer from './Profiles/Admin/AdminPages/Partners/PartnerPages/PartnerViewContainer'
import Partners from './Profiles/Admin/AdminPages/Partners/Partners'
import Locations from './Profiles/Admin/AdminPages/Region/Locations'
import GeoCitiesAvailable from './Profiles/Admin/AdminPages/Region/Pages/City/GeoCitiesAvailable'
import GeoCitiesCreate from './Profiles/Admin/AdminPages/Region/Pages/City/GeoCitiesCreate'
import GeoCountriesAvailable from './Profiles/Admin/AdminPages/Region/Pages/Countries/GeoCountriesAvailable'
import GeoCountriesCreate from './Profiles/Admin/AdminPages/Region/Pages/Countries/GeoCountriesCreate'
import GeoLocationAvailable from './Profiles/Admin/AdminPages/Region/Pages/Location/GeoLocationAvailable'
import GeoLocationCreate from './Profiles/Admin/AdminPages/Region/Pages/Location/GeoLocationCreate'
import GeoRegionsAvailable from './Profiles/Admin/AdminPages/Region/Pages/Regions/GeoRegionsAvailable'
import GeoRegionsCreate from './Profiles/Admin/AdminPages/Region/Pages/Regions/GeoRegionsCreate'
import SeoPagesListContainer from './Profiles/Admin/AdminPages/SEO/Pages/SeoPagesListContainer'
import SeoServiceSettingsContainer from './Profiles/Admin/AdminPages/SEO/Pages/SeoServiceSettingsContainer'
import SeoPages from './Profiles/Admin/AdminPages/SEO/SeoPages'
import AdminService from './Profiles/Admin/AdminPages/Service/AdminService'
import CreateService from './Profiles/Admin/AdminPages/Service/ServicePages/CreateService'
import ServiceAvailable from './Profiles/Admin/AdminPages/Service/ServicePages/ServiceAvailable'
import CreateStaticPages from './Profiles/Admin/AdminPages/StaticPages/CreateStaticPages'
import StaticPages from './Profiles/Admin/AdminPages/StaticPages/StaticPages'
import StaticPagesList from './Profiles/Admin/AdminPages/StaticPages/StaticPagesList'
import Authorization from './Profiles/Authorization'
import CreateAccount from './Profiles/CreateAccount'
import BookingSoftware from './Profiles/Admin/AdminPages/BookingSoftware/BookingSoftware'
import BookingSoftwareView from './Profiles/Admin/AdminPages/BookingSoftware/BookingSoftwareView'
import BookingSoftwareCreate from './Profiles/Admin/AdminPages/BookingSoftware/BookingSoftwareCreate'

function App() {
	const isAuth = useSelector(state => state.profilePage.isAuth)
	const nav = useNavigate()

	useEffect(() => {
		if (!isAuth) {
			nav('/authorization')
		}
	}, [isAuth])

	return (
		<>
			<div id='page-wrap' className={'headerMenu'}>
				<Header />
				<main>
					<Routes>
						<Route
							path='/'
							element={<Navigate to='/admin_cabinet/dashboard' />}
						></Route>

						<Route
							path={'admin_cabinet/*'}
							element={isAuth ? <Admin /> : <Authorization />}
						>
							{/* <Route index element={<DashboardContainer />} /> */}
							<Route path='dashboard' element={<DashboardContainer />} />
							<Route path='users/*' element={<AdminUsers />}>
								<Route index element={<AvailableUsers />} />
								<Route path=':userId' element={<AdminUsersViewContainer />} />
							</Route>
							<Route path='partners/*' element={<Partners />}>
								<Route path='partner_view' element={<PartnerViewContainer />} />
								<Route path='create' element={<PartnerCreate />} />
								<Route path='create/:itemId' element={<PartnerCreate />} />
							</Route>
							<Route path='service/*' element={<AdminService />}>
								<Route index element={<ServiceAvailable />} />
								<Route
									path='service_create/:serviceId'
									element={<CreateService />}
								/>
								<Route path='service_create' element={<CreateService />} />
							</Route>
							<Route
								path='affiliate-programs-view/*'
								element={<AffiliatePrograms />}
							>
								<Route index element={<AffiliateProgramsView />} />
								<Route
									path='affiliate_create/:affiliateId'
									element={<AffiliateCreate />}
								/>
								<Route path='affiliate_create' element={<AffiliateCreate />} />
							</Route>
							<Route path='booking-software/*' element={<BookingSoftware />}>
								<Route index element={<BookingSoftwareView />} />
								<Route
									path='booking-software_create/:softwareId'
									element={<BookingSoftwareCreate />}
								/>
								<Route
									path='booking-software_create'
									element={<BookingSoftwareCreate />}
								/>
							</Route>
							<Route path='advertising/*' element={<AdminAdvertising />}>
								<Route index element={<AdvertisingAvailable />} />
								<Route
									path='advertising_create/service_card/:itemId'
									element={<CreateAdvertisingServiceCard />}
								/>
								<Route
									path='advertising_create/activity_bottom/:itemId'
									element={<CreateAdvertisingActivityBottom />}
								/>
								<Route
									path='advertising_create/service_card'
									element={<CreateAdvertisingServiceCard />}
								/>
								<Route
									path='advertising_create/activity_bottom'
									element={<CreateAdvertisingActivityBottom />}
								/>
							</Route>
							<Route path='activity_location/*' element={<ActivityLocation />}>
								<Route index element={<ActivityLocationList />} />
								<Route
									path='activity_create/:itemId'
									element={<ActivityLocationCreate />}
								/>
								<Route
									path='activity_create'
									element={<ActivityLocationCreate />}
								/>
							</Route>
							<Route path='attributes/*' element={<Attributes />}>
								<Route index element={<AttributeList />} />
								<Route
									path='attributes_create/:itemId'
									element={<AttributeCreate />}
								/>
								<Route path='attributes_create' element={<AttributeCreate />} />
							</Route>
							<Route path='orders/*' element={<AdminOrders />}>
								<Route
									path='orders_available'
									element={<AvailableOrdersContainer />}
								/>
								<Route
									path='orders_view'
									element={<AdminOrderViewContainer />}
								/>
							</Route>
							<Route path='pages' element={<SeoPages />}>
								<Route index element={<SeoPagesListContainer />} />
								<Route
									path='pages_view/:pageId'
									element={<SeoServiceSettingsContainer />}
								/>
							</Route>
							<Route path='category' element={<AdminCategory />}>
								<Route index element={<AdminCategoryAvailable />} />
								<Route
									path='category_create/:itemId'
									element={<CreateCategory />}
								/>
								<Route path='category_create' element={<CreateCategory />} />
							</Route>

							<Route path='locations' element={<Locations />} />
							<Route path='' element={<StaticPagesList />}>
								<Route path='' element={<StaticPages />}></Route>
							</Route>

							<Route path='static_pages' element={<StaticPages />}>
								<Route index element={<StaticPagesList />} />
								<Route path='create/:id' element={<CreateStaticPages />} />
								<Route path='create' element={<CreateStaticPages />} />
							</Route>
							{/*==========================================================================================*/}
							<Route
								path='locations/countries'
								element={<GeoCountriesAvailable />}
							/>
							<Route
								path='locations/countries_create'
								element={<GeoCountriesCreate />}
							/>
							<Route
								path='locations/countries_create/:countryId'
								element={<GeoCountriesCreate />}
							/>

							{/*==========================================================================================*/}
							<Route path='locations/cities' element={<GeoCitiesAvailable />} />
							<Route
								path='locations/cities_create/:citiesId'
								element={<GeoCitiesCreate />}
							/>
							<Route
								path='locations/cities_create'
								element={<GeoCitiesCreate />}
							/>
							{/*==========================================================================================*/}
							<Route
								path='locations/places'
								element={<GeoLocationAvailable />}
							/>
							<Route
								path='locations/locations_create'
								element={<GeoLocationCreate />}
							/>
							<Route
								path='locations/locations_create/:locationId'
								element={<GeoLocationCreate />}
							/>

							{/*==========================================================================================*/}
							<Route
								path='locations/regions'
								element={<GeoRegionsAvailable />}
							/>
							<Route
								path='locations/regions_create'
								element={<GeoRegionsCreate />}
							/>
							<Route
								path='locations/regions_create/:regionId'
								element={<GeoRegionsCreate />}
							/>
						</Route>

						<Route path='authorization' element={<Authorization />} />
						<Route path='create' element={<CreateAccount />} />
						<Route path='*' element={<Page404 />} />
					</Routes>
				</main>
				<Footer />
			</div>
		</>
	)
}

export default App
