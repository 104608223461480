import React from 'react'
import { Outlet } from 'react-router-dom'

const Partners = () => {
	return (
		<div>
			<Outlet>attributes available / view</Outlet>
		</div>
	)
}

export default Partners
