import { NavLink, Navigate } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import s from './AuthProfiles.module.css'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
//import {getAuthUserData, getUsertype} from "../redux/auth_Reducer";
import { login } from '../redux/profile_Reducer'
import {
  getIndexStatus,
  getOrderPayStatus,
  getOrderStatus,
  getServiceStatus,
  getUsersType,
} from '../redux/directory_Reducer'

const Authorization = () => {
  const dispatch = useDispatch()
  const isAuth = useSelector(state => state.profilePage.isAuth)
  const role = useSelector(state => state.profilePage.role)
  const usertype = useSelector(state => state.auth.usertype)
  const loginUser = useSelector(() => login)
  const getOrderStatuses = useSelector(() => getOrderStatus)
  const getOrderPayStatuses = useSelector(() => getOrderPayStatus)
  const getIndexStatuses = useSelector(() => getIndexStatus)
  const getUsersTypes = useSelector(() => getUsersType)
  const getServiceStatuses = useSelector(() => getServiceStatus)

  if (isAuth) {
    dispatch(getOrderStatuses())
    dispatch(getOrderPayStatuses())
    dispatch(getUsersTypes())
    dispatch(getIndexStatuses())
    dispatch(getServiceStatuses())

    if (role === 'admin') {
      return <Navigate to={'/admin_cabinet'} />
    } else if (role === 'client' || role === 'partner') {
      return <Navigate to={'/myAccount/personal_info'} />
    }
  }
  return (
    <div className={s.authContainer}>
      <div
        id='authorization'
        className='white-popup mfp-with-anim mfp-hide order_popup'
      >
        <div className='popup_content'>
          <div className='h3 center'>Authorization</div>
          <p>
            Hi and welcome to the Tour2Sky personal account area.
            <br /> Please, enter your email address in order to verify you.{' '}
          </p>
          <div className='popup_form'>
            <div className='login_wrap'>
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                }}
                onSubmit={(values, submitProps) => {
                  dispatch(
                    loginUser(
                      values.email,
                      values.password,
                      submitProps.setStatus
                    )
                  )
                }}
              >
                {({ values, status }) => (
                  <Form className={s.authorizationForm}>
                    {status && status.error && (
                      <div className={s.error}>{status.error}</div>
                    )}
                    <div>
                      <Field
                        placeholder={'Your email'}
                        type={'email'}
                        name={'email'}
                        value={values.firstName}
                      />
                    </div>

                    <div>
                      <Field
                        type='password'
                        placeholder={'Password'}
                        name={'password'}
                        value={values.password}
                      />
                    </div>

                    <button type='submit'>Log In</button>
                  </Form>
                )}
              </Formik>
            </div>

            <div className='row authorization_link'>
              New here?{' '}
              <NavLink to='/create' className='create' usertype={usertype}>
                Create an account
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Authorization
