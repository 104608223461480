import React, {useEffect, useState} from 'react';
import s from "./ChangeLocationAssistent.module.css";
import style from "../../../Profiles/Admin/Admin.module.css";
import {useDispatch, useSelector} from "react-redux";
import {getCity, getCountry, getLocations, getRegion} from "../../../redux/location_Reducer";
import Select from "react-select";
import Switch from "@mui/material/Switch";

const ChangeLocationAssistant = ({
                                     location_id, location_name,
                                     status, setFieldValue, city_id,
                                     city_name, region_id, region_name, country_name, country_id,
                                     isActivityLocations, switcher_status
                                 }) => {
    const dispatch = useDispatch()
    const location = useSelector((state) => state.location);
    const changeCountry = useSelector(() => getCountry);
    const changeRegion = useSelector(() => getRegion);
    const changeCity = useSelector(() => getCity);
    const changeLocation = useSelector(() => getLocations);

    const [isLocation, setIsLocation] = useState(false)
    useEffect(() => {
        dispatch(changeCountry())
    }, []);

    useEffect(() => {
        setIsLocation(switcher_status);
    },[switcher_status])

    const locationOptions = location?.location.map(i => {
        return {value: i.id, label: i.name}
    })
    const resetSelectValue = (isCountry) => {
        if (isCountry) {
            setFieldValue("region_id", "");
            setFieldValue("region_name", "");
        }
        setFieldValue("city_id", "");
        setFieldValue("city_name", "");
        setFieldValue("location_id", "");
        setFieldValue("location_name", "");
    }
    return (
        <div className={s.changeLocationWrapper}>
            <div className={s.changeLocationItem}>
                <label>Select Country
                    <Select onChange={(event) => {
                        setFieldValue("country_id", event?.value || "");
                        setFieldValue("country_name", event?.label || "");
                        resetSelectValue(true)
                        dispatch(changeRegion(event.value));
                    }} options={location.country.map(c => {
                        return {value: c.id, label: c.name}
                    })}
                            value={[{value: country_id, label: country_name || ""}]}/>
                </label>
            </div>
            <div className={s.changeLocationItem}>
                <label>Select region
                    {status && status.error.region_id &&
                        <div className={style.error}>{status.error.region_id}</div>}
                    <Select isClearable onChange={(event) => {
                        setFieldValue("region_id", event?.value || "");
                        setFieldValue("region_name", event?.label || "");
                        resetSelectValue(false)
                        dispatch(changeCity(event.value));
                        dispatch(changeLocation(event.value));
                    }} options={location.region.map(c => {
                        return {value: c.id, label: c.name}
                    })}
                            value={[{value: region_id, label: region_name || ""}]}/>
                </label>
            </div>
            {!isLocation &&
            <div className={s.changeLocationItem}>
                <label>Select City
                    <Select isClearable onChange={(event) => {
                        setFieldValue("city_id", event?.value || "");
                        setFieldValue("city_name", event?.label || "");
                    }} options={location.city.map(c => {
                        return {value: c.id, label: c.name}
                    })}
                            value={[{value: city_id, label: city_name || ""}]}/>
                </label>
            </div>}
            {isActivityLocations && <>
                {isLocation &&
                <div className={s.changeLocationItem}>
                    <label>Select location
                        {status && status.error.location_id &&
                            <div className={style.error}>{status.error.location_id}</div>}
                        <Select isClearable onChange={(e) => {
                            setFieldValue("location_id", e?.value || "");
                            setFieldValue("location_name", e?.label || "");
                        }} options={locationOptions} value={[
                            {
                                value: location_id || "",
                                label: location_name || "",
                            }
                        ]}/>
                    </label>
                </div>}
                <div className={style.serviceCreateItem}>
                    <label>Please chose city or location
                        <Switch checked={switcher_status || isLocation}
                        
                                onChange={() => {
                                    setFieldValue("switcher_status", !isLocation);
                                    setIsLocation(!isLocation)
                                }}
                                color="primary"
                                name="checkedB"
                                inputProps={{'aria-label': 'primary checkbox'}}
                        /></label>
                </div>
            </>}
        </div>
    );
};

export default ChangeLocationAssistant;