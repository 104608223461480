import React from 'react'
import './index.css'
import './App.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './redux/redux-store'
import { hydrateRoot } from 'react-dom/client'
import { createRoot } from 'react-dom/client'

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

const container = document.getElementById('root')

if (container.hasChildNodes()) {
  const root = hydrateRoot(container)
  root.render(app)
} else {
  const root = createRoot(container)
  root.render(app)
}

/*
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
    hydrate(app, rootElement);
} else {
    render(app, rootElement);
}*/

/*const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <App/>
            </Provider>
        </BrowserRouter>

    </React.StrictMode>
);*/

reportWebVitals()
