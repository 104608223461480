import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCountriesList,
  getRegionsActivate,
  getRegionsBlock,
  getRegionsCreateUpdate,
  getRegionsView,
} from '../../../../../../redux/adminGeo_Reducer'
import React, { useEffect, useState } from 'react'
import s from '../../../../Admin.module.css'
import { Field, Form, Formik } from 'formik'
import Button from '@mui/material/Button'
import Select from 'react-select'
import StatusSelect from '../../../../../../assets/common/StatusSelect/StatusSelect'

const GeoRegionsCreate = () => {
  let params = useParams()
  let regionId = params.regionId
  const dispatch = useDispatch()
  const getCreate = useSelector(() => getRegionsCreateUpdate)
  const [done, setDone] = useState(false)
  const getRegionsInfo = useSelector(() => getRegionsView)
  const getActive = useSelector(() => getRegionsActivate)
  const getBlock = useSelector(() => getRegionsBlock)
  const getCountries = useSelector(() => getCountriesList)
  const regionView = useSelector(state => state.geo.regionsView)
  const countriesList = useSelector(state => state.geo.countriesList)

  useEffect(() => {
    if (regionId) {
      dispatch(getRegionsInfo(regionId))
    }
    dispatch(getCountries())
  }, [regionId])

  let initialValues
  if (regionId) {
    initialValues = {
      name: regionView?.name || '',
      status: regionView?.status || '',
      country_id: regionView?.country_id || '',
      country_name: regionView?.country_name || '',
      link: regionView?.link || '',
    }
  } else {
    initialValues = {
      name: '',
      status: '',
      country_id: '',
      country_name: '',
      link: '',
    }
  }
  return (
    <div className={s.formContainer}>
      <div className={s.wrapper}>
        <h4>{regionId ? 'Update' : 'Create'} region</h4>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values, submitProps) => {
            dispatch(
              getCreate(values, submitProps.setStatus, setDone, regionId)
            )
          }}
        >
          {({ values, status, setFieldValue }) => {
            if (done) {
              return <div className={s.createComplete}>Successful!</div>
            } else {
              return (
                <Form>
                  <div className={s.formContainer}>
                    <div className={s.createBlocks}>
                      <div className={s.serviceCreateItem}>
                        <label>
                          Region name
                          {status && status.error.name && (
                            <div className={s.error}>{status.error.name}</div>
                          )}
                          <Field
                            value={values.name}
                            name={'name'}
                            type='text'
                            placeholder={'name'}
                          />
                        </label>
                      </div>
                      <div className={s.serviceCreateItem}>
                        {regionId && (
                          <label>
                            Slug
                            <Field name={'slug'} value={values.link} />
                          </label>
                        )}
                      </div>
                      <div className={s.serviceCreateItem}>
                        {regionId && (
                          <StatusSelect
                            active={getActive}
                            block={getBlock}
                            elementInfo={regionView}
                          />
                        )}
                      </div>
                    </div>
                    <div className={s.createBlocks}>
                      <label>
                        Country
                        {status && status.error.country_id && (
                          <div className={s.error}>
                            {status.error.country_id}
                          </div>
                        )}
                        <Select
                          className={s.select}
                          onChange={e => {
                            setFieldValue('country_id', e.value)
                            setFieldValue('country_name', e.label)
                          }}
                          options={countriesList.map(c => {
                            return { value: c.id, label: c.name }
                          })}
                          value={[
                            {
                              value: values.country_id,
                              label: values.country_name,
                            },
                          ]}
                        />
                      </label>
                    </div>
                    <Button
                      sx={{
                        width: '20%',
                        backgroundColor: ' #295673',
                        color: ' white',
                      }}
                      type={'submit'}
                      size='small'
                    >
                      {regionId ? 'Update' : 'Create'}
                    </Button>
                  </div>
                </Form>
              )
            }
          }}
        </Formik>
      </div>
    </div>
  )
}
export default GeoRegionsCreate
