import {adminOrder} from "../api/apiAdmin";


const SET_ORDERS_DATA = "tour2sky/admin/orders/SET_ORDERS_DATA";
const SET_ORDER_VIEW = "tour2sky/admin/orders/SET_ORDER_VIEW";
const SET_DELETE_ORDER = "tour2sky/admin/orders/SET_DELETE_ORDER";



const initialState = {

    adminOrdersData:[{
        "id": 1,
        "user_id": 123,
        "service_id": 123,
        "members_count": 2,
        "service_date": "2021-12-25",
        "service_time": "09:00:00",
        "price": 30,
        "status": 1,
        "status_name":"active",
        "comment": "It's my comment",
        "created_at": "2021-12-25 09:30:25",
        "updated_at": "2021-12-25 09:30:25",
        "currency": {
            "id": 1,
            "name": "US Dollar",
            "code": "USD",
            "rate": 28.1,
            "status": 1
        },
        "service": {
            "id": 1,
            "name": "Concerts",
            "link": "concerts",
            "created_at": "2021-12-25 09:30:25",
            "price": 30,
            "duration": 120,
            "description": "Some service description",
            "rating": 4.5,
            "currency": {
                "id": 1,
                "name": "US Dollar",
                "code": "USD",
                "rate": 28.1,
                "status": 1
            },
            "images": {
                "data": [
                    {
                        "id": 1,
                        "name": "concert-1.jpeg",
                        "mime_type": "image/jpeg",
                        "link": "",
                        "thumb": ""
                    }
                ]
            }
        },
        "statusObject": {
            "id": 1,
            "name": "New"
        }}],
    adminOrdersMeta:{pagination:{total:1,current_page:1}},
    adminOrderView:{
        "id": 1,
        "user_id": 123,
        "service_id": 123,
        "members_count": 2,
        "service_date": "2021-12-25",
        "service_time": "09:00:00",
        "price": 30,
        "status": 1,
        "pay_status": 1,
        "comment": "It's my comment",
        "created_at": "2021-12-25 09:30:25",
        "updated_at": "2021-12-25 09:30:25",
        "currency": {
            "id": 1,
            "name": "US Dollar",
            "code": "USD",
            "rate": 28.1,
            "status": 1
        },
        "service": {
            "id": 1,
            "user_id": 1,
            "name": "Concerts",
            "link": "concerts",
            "created_at": "2021-12-25 09:30:25",
            "price": 30,
            "duration": 120,
            "status": 1,
            "description": "Some service description",
            "rating": 4.5,
            "seo_title": "Concerts",
            "seo_description": "Concerts",
            "index_status": 1,
            "seo_image": {
                "id": 1,
                "name": "concert-1.jpeg",
                "mime_type": "image/jpeg",
                "link": "",
                "thumb": ""
            },
            "attributes": {
                "data": [
                    {
                        "id": 1,
                        "service_id": 2,
                        "attribute_id": 3,
                        "name": "Wi-Fi",
                        "value": "exist",
                        "description": "Some description text"
                    }
                ]
            },
            "categories": {
                "data": [
                    {
                        "id": 1,
                        "service_id": 2,
                        "category_id": 3,
                        "name": "Concerts"
                    }
                ]
            },
            "images": {
                "data": [
                    {
                        "id": 1,
                        "name": "concert-1.jpeg",
                        "mime_type": "image/jpeg",
                        "link": "",
                        "thumb": ""
                    }
                ]
            },
            "country": {
                "id": 1,
                "continent_id": 1,
                "name": "Ukraine",
                "link": "ukraine",
                "status": 1,
                "created_at": "2021-12-25 09:30:25"
            },
            "region": {
                "id": 1,
                "country_id": 1,
                "name": "Одесская область",
                "link": "district-odessa",
                "status": 1,
                "created_at": "2021-12-25 09:30:25"
            },
            "city": {
                "id": 1,
                "region_id": 1,
                "name": "Одесса",
                "link": "odessa",
                "status": 1,
                "seo_title": "Concerts",
                "seo_description": "Concerts",
                "index_status": 1,
                "seo_image": {
                    "id": 1,
                    "name": "concert-1.jpeg",
                    "mime_type": "image/jpeg",
                    "link": "",
                    "thumb": ""
                },
                "created_at": "2021-12-25 09:30:25"
            },
            "location": {
                "id": 1,
                "name": "Аркадия",
                "link": "arcadia",
                "latitude": 46.430869,
                "longitude": 30.761649,
                "region_id": 1,
                "city_id": 1,
                "status": 1,
                "seo_title": "Concerts",
                "seo_description": "Concerts",
                "index_status": 1,
                "seo_image": {
                    "id": 1,
                    "name": "concert-1.jpeg",
                    "mime_type": "image/jpeg",
                    "link": "",
                    "thumb": ""
                },
                "created_at": "2021-12-25 09:30:25"
            },
            "currency": {
                "id": 1,
                "name": "US Dollar",
                "code": "USD",
                "rate": 28.1,
                "status": 1
            }
        },
        "statusObject": {
            "id": 1,
            "name": "New"
        },
        "payStatusObject": {
            "id": 1,
            "name": "Paid"
        }},
}


const adminOrders_Reducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_ORDERS_DATA: {

            return {
                ...state,
                adminOrdersData:action.data.data,
                adminOrdersMeta:action.data.meta,
                }
            }
        case SET_ORDER_VIEW: {

            return {
                ...state,
                adminOrderView:action.data,
            }
        }
        case SET_DELETE_ORDER: {

            return {
                ...state,
                adminOrderView:{},
            }
        }

        default:
            return state
    }
}

export const setOrdersData = (data) => ({type: SET_ORDERS_DATA, data});
export const setOrderView = (data) => ({type: SET_ORDER_VIEW, data});
export const setDeleteOrdersData = () => ({type: SET_DELETE_ORDER});



/*This is Thunk*/

export const getOrdersList = (page) => async (dispatch) => {
    let response = await adminOrder.getAdminOrders(page);

    dispatch(setOrdersData(response.data))
}
export const getOrderView = (ordersId) => async (dispatch) => {
    let response = await adminOrder.getOrderView(ordersId);

    dispatch(setOrdersData(response.data))
}
export const getOrderCancel = (ordersId) => async (dispatch) => {

    let response = await adminOrder.getCancelOrder(ordersId);
    dispatch(setOrdersData(response.data))
}
export const getOrderUpdate = (ordersId,updateInfo) => async (dispatch) => {

    let response = await adminOrder.getUpdateOrder(ordersId,updateInfo);
    dispatch(setOrdersData(response.data))
}
export const getOrderUpdateCustomer = (ordersId,updateInfo) => async (dispatch) => {

    let response = await adminOrder.getUpdateCustomerOrder(ordersId,updateInfo);
    dispatch(setOrdersData(response.data))
}
export const getDeleteOrder = (ordersId) => async (dispatch) => {

    let response = await adminOrder.getUpdateCustomerOrder(ordersId);
    dispatch(setDeleteOrdersData())
}

export default adminOrders_Reducer