import * as React from 'react'
import { alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import DeleteIcon from '@mui/icons-material/Delete'
import FilterListIcon from '@mui/icons-material/FilterList'
import { visuallyHidden } from '@mui/utils'
import { NavLink, useNavigate } from 'react-router-dom'
import s from '../../../Admin.module.css'
import editImg from '../../../../../assets/img/pencil.png'
import delImg from '../../../../../assets/img/delimg.png'
import { useState, useEffect } from 'react'
import TablePagination from '@mui/material/TablePagination'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import {
  getActivityLocationData,
  getAllActivity,
  getDeleteActivityLocation,
} from '../../../../../redux/adminActivityLocation_Reducer'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Select from 'react-select'

const ActivityLocationList = () => {
  const dispatch = useDispatch()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [dense, setDense] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const createNavLink = '/admin_cabinet/activity_location/activity_create';
  const adminActivity = useSelector(
    state => state.adminActivity.activityLocationList
  )
  const allActivity = useSelector(state => state.adminActivity.allActivityList)
  const pagination = useSelector(
    state => state.adminActivity.activityLocationPagination.pagination
  )
  const getActivity = useSelector(() => getActivityLocationData)
  const getDeleteActivity = useSelector(() => getDeleteActivityLocation)
  const getAllActivityList = useSelector(() => getAllActivity)

  useEffect(() => {
    dispatch(getAllActivityList())
  }, [allActivity.length])

  const headCells = [
    {
      id: 'id',
      numeric: true,
      disablePadding: true,
      label: 'ID',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },

    {
      id: 'category',
      numeric: true,
      disablePadding: false,
      label: 'category',
    },
    {
      id: 'Location',
      numeric: true,
      disablePadding: false,
      label: 'location',
    },
    {
      id: 'Status',
      numeric: true,
      disablePadding: false,
      label: 'Status',
    },
    {
      id: 'edit',
      numeric: true,
      disablePadding: false,
      label: 'Edit',
    },
  ]

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props
    const createSortHandler = property => event => {
      onRequestSort(event, property)
    }

    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding='normal'
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }
  const EnhancedTableToolbar = props => {

    const allActivityOptions = allActivity.map(c => {
      return {
        value: c.name,
        label: <NavLink to={createNavLink + `/${c.id}`}>{c.name}</NavLink>,
      }
    })
    const { numSelected } = props
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: theme =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color='inherit'
            variant='subtitle1'
            component='div'
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant='h6'
            id='tableTitle'
            component='div'
          >
            <div className={s.topPanel}>
              <div className={s.createButton}>
                <span>
                  <NavLink to={`${props.createNavLink}`}>Create</NavLink>
                </span>
              </div>
              <div className={s.select}>
                <Select
                  placeholder={'Search...'}
                  options={allActivityOptions}
                />
              </div>
            </div>
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title='Delete'>
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title='Filter list'>
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    )
  }
  const [open, setOpen] = React.useState(false)
  const [deleteItemId, setDeleteItemId] = React.useState('')

  const handleClickOpen = id => {
    setOpen(true)
    setDeleteItemId(id)
  }
  const handleClose = () => {
    setOpen(false)
  }
  useEffect(() => {
    dispatch(getActivity(page, rowsPerPage, order))
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    dispatch(getActivity(page, rowsPerPage, order))
  }
  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = adminActivity.map(n => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    dispatch(getActivity(newPage + 1, rowsPerPage, order))
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    dispatch(getActivity(page, event.target.value, order))
  }

  const handleChangeDense = event => {
    setDense(event.target.checked)
  }
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 5, mt: 2 }}>
          <EnhancedTableToolbar
            selected={selected}
            numSelected={selected.length}
            createNavLink={createNavLink}
          />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby='tableTitle'
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={adminActivity.length}
              />
              <TableBody>
                {adminActivity.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell align='right' size='small'>
                        {row.id}
                      </TableCell>
                      <TableCell
                        component='th'
                        id={labelId}
                        scope='row'
                        padding='normal'
                      >
                        <NavLink
                          to={`/${row.category_link}/${
                            row.city_link || row.location_link
                          }`}
                        >
                          {row.name}
                        </NavLink>
                      </TableCell>
                      <TableCell align='right'>{row.category_name}</TableCell>
                      <TableCell align='right'>
                        {row.location_name || row.city_name}
                      </TableCell>
                      <TableCell align='right'>
                        {row.status === 1 ? 'active' : 'block'}
                        {/*{row.status_name}*/}
                      </TableCell>
                      <TableCell align='right'>
                        <NavLink to={createNavLink + `/${row.id}`}>
                          <img
                            className={s.editDelImg}
                            src={editImg}
                            alt='edit'
                          />
                        </NavLink>
                        <NavLink to={''}>
                          <img
                            onClick={() => {
                              handleClickOpen(row.id)
                            }}
                            className={s.editDelImg}
                            src={delImg}
                            alt='del'
                          />
                        </NavLink>
                      </TableCell>
                    </TableRow>
                  )
                })}
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby='alert-dialog-title'
                  aria-describedby='alert-dialog-description'
                >
                  <DialogTitle id='alert-dialog-title'>
                    {'Really delete?'}
                  </DialogTitle>
                  <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button
                      onClick={() => {
                        setOpen(false)
                        dispatch(getDeleteActivity(deleteItemId))
                      }}
                      autoFocus
                    >
                      Agree
                    </Button>
                  </DialogActions>
                </Dialog>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            count={pagination.total}
            rowsPerPageOptions={[5, 15, 50, 100]}
            component='div'
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label='Dense padding'
        />
      </Box>
    </>
  )
}
export default ActivityLocationList
