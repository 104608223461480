import { adminBookingSoftware } from '../api/apiAdmin'

const SET_BOOKING_SOFTWARE_LIST =
	'tour2sky-project/booking-software/SET_BOOKING_SOFTWARE_LIST'
const SET_BOOKING_SOFTWARE =
	'tour2sky-project/booking-software/SET_BOOKING_SOFTWARE'
const SET_BOOKING_SOFTWARE_VIEW =
	'tour2sky-project/booking-software/SET_BOOKING_SOFTWARE_VIEW'
const SET_BOOKING_SOFTWARE_DELETE =
	'tour2sky-project/booking-software/SET_BOOKING_SOFTWARE_DELETE'
const SET_BOOKING_SOFTWARE_SELECT =
	'tour2sky-project/booking-software/SET_BOOKING_SOFTWARE_SELECT'

let initialState = {
	bookingSoftwareList: [],
	bookingSoftwareSelect: [],
	bookingSoftwareView: {}
}

export const setAllBookingSoftware = data => ({
	type: SET_BOOKING_SOFTWARE_LIST,
	data,
})
export const setBookingSoftware = data => ({
	type: SET_BOOKING_SOFTWARE,
	data,
})
export const setBookingSoftwareView = data => ({
	type: SET_BOOKING_SOFTWARE_VIEW,
	data,
})
export const setBookingSoftwareDelete = data => ({
	type: SET_BOOKING_SOFTWARE_DELETE,
	data,
})
export const setBookingSoftwareSelect = data => ({
	type: SET_BOOKING_SOFTWARE_SELECT,
	data,
})

const bookingSoftwareList = (state = initialState, action) => {
	switch (action.type) {
		case SET_BOOKING_SOFTWARE_LIST: {
			return {
				...state,
				bookingSoftwareList: action.data.data,
			}
		}
		case SET_BOOKING_SOFTWARE_VIEW: {
			return {
				...state,
				bookingSoftwareView: action.data,
			}
		}
		case SET_BOOKING_SOFTWARE_DELETE: {
			return {
				...state,
				bookingSoftwareList: state.bookingSoftwareList.filter(item => item.id !== action.data),
			}
		}
		case SET_BOOKING_SOFTWARE_SELECT: {
			return {
				...state,
				bookingSoftwareSelect: action.data,
			}
		}
		default:
			return state
	}
}

export const getBookingSoftwareList = () => async dispatch => {
	try {
		let response = await adminBookingSoftware.getAdminUserBookingSoftware()
		console.log('response: ' + response)
		dispatch(setAllBookingSoftware(response.data))
	} catch (e) {
		console.log(e)
	}
}

export const createBookingSoftware =
	(formData, setStatus, setDone, softwareId) => async dispatch => {
		try {
			let response = await adminBookingSoftware.createUpdateBookingSoftware(
				formData,
				softwareId
			)
			dispatch(setBookingSoftware(response.data))
			setStatus({})
			setDone(true)
		} catch (error) {
			let errors = error.response
			setStatus({ error: errors.data.errors })
		}
	}

export const getBookingSoftwareView = softwareId => async dispatch => {
	let response = await adminBookingSoftware.getBookingSoftwareView(softwareId)
	dispatch(setBookingSoftwareView(response.data))
}

export const getDeleteBookingSoftware = softwareId => async dispatch => {
	try {
		await adminBookingSoftware.getDelete(softwareId)
		dispatch(setBookingSoftwareDelete(softwareId))
	} catch (e) {}
}
export const getBookingSoftwareForSelect = () => async dispatch => {
	try {
		let response = await adminBookingSoftware.getForSelect()
		dispatch(setBookingSoftwareSelect(response.data.result))
	} catch (e) {}
}

export default bookingSoftwareList
