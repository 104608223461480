import {adminActivityLocationPage} from "../api/apiAdmin";

const SET_ACTIVITY_LOCATION_PAGES_LIST = "tour2sky-project/admin/activityLocation/SET_ACTIVITY_LOCATION_PAGES_LIST";
const SET_ACTIVITY_LOCATION_VIEW = "tour2sky-project/admin/activityLocation/SET_ACTIVITY_LOCATION_VIEW";
const CLEAR_ACTIVITY_LOCATION_VIEW = "tour2sky-project/admin/activityLocation/CLEAR_ACTIVITY_LOCATION_VIEW";
const SET_LOADING_ON = "tour2sky/admin/activityLocation/SET_LOADING_ON";
const SET_LOADING_OFF = "tour2sky/admin/activityLocation/SET_LOADING_OFF";
const SET_DELETE = "tour2sky/admin/activityLocation/SET_DELETE";
const SET_ALL_ACTIVITY = "tour2sky/admin/activityLocation/SET_ALL_ACTIVITY";



let initialState = {
    activityLocationList: [],
    activityLocationPagination: {
        pagination: {
            total: 30,
            count: 2,
            per_page: 15,
            current_page: 1,
            total_pages: 2,
            links: {}
        }
    },
    activityLocationView: {},
    allActivityList:[],
    isLoading: false,
}

const adminActivityLocation_Reducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_ACTIVITY_LOCATION_PAGES_LIST: {
            return {
                ...state,
                activityLocationList: action.data.data,
                activityLocationPagination: action.data.meta,
            }
        }
        case SET_ACTIVITY_LOCATION_VIEW: {
            return {
                ...state,
                activityLocationView: action.data,
            }
        }
        case CLEAR_ACTIVITY_LOCATION_VIEW: {
            return {
                ...state,
                activityLocationView: {},
            }
        }
        case SET_ALL_ACTIVITY: {

            return {
                ...state,
                allActivityList: action.data.result,
            }
        }
        case SET_LOADING_ON: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case SET_LOADING_OFF: {
            return {
                ...state,
                isLoading: false,
            }
        }
        case SET_DELETE: {
            return {
                ...state,
                activityLocationList:state.activityLocationList.filter(item=>item.id !== action.data)
            }
        }

        default:
            return state
    }
}

export const setActivityLocationData = (data) => ({type: SET_ACTIVITY_LOCATION_PAGES_LIST, data});
export const setActivityLocationView = (data) => ({type: SET_ACTIVITY_LOCATION_VIEW, data});
export const setIsLoadingOn = () => ({type: SET_LOADING_ON});
export const setIsLoadingOff = () => ({type: SET_LOADING_OFF});
export const setDeleteActivity = (data) => ({type: SET_DELETE,data});
export const setAllActivity = (data) => ({type: SET_ALL_ACTIVITY,data});


/*This is Thunk*/
export const getActivityLocationData = (page,perPage,order) => async (dispatch) => {
    try {
        dispatch(setIsLoadingOn())
        let response = await adminActivityLocationPage.getActivityLocationList(page,perPage,order);
        dispatch(setActivityLocationData(response.data));
        dispatch(setIsLoadingOff())
    } catch (error) {
        return console.log(error)
    }
}
export const getActivityLocationView = (activityLocationId) => async (dispatch) => {
    dispatch(setIsLoadingOn())
try {
    let response = await adminActivityLocationPage.getActivityLocationPageView(activityLocationId);
    dispatch(setActivityLocationView(response.data));
} catch (e) {
}
    dispatch(setIsLoadingOff())
}
export const getDeleteActivityLocation = (activityId) => async (dispatch) => {
    try {
        await adminActivityLocationPage.getDelete(activityId);
        dispatch(setDeleteActivity(activityId));
    } catch (e) {
        console.log(e)
    }
}
export const getActivateActivityLocation = (id) => async (dispatch) => {
    let response = await adminActivityLocationPage.getActivate(id);
    dispatch(setActivityLocationView(response.data));
}
export const getBlockActivityLocation = (id) => async (dispatch) => {
    let response = await adminActivityLocationPage.getBlock(id);
    dispatch(setActivityLocationView(response.data));
}


export const getCreateUpdateActivityLocation = (formData, setStatus, setDone, id) => async (dispatch) => {
    try {
        let response = await adminActivityLocationPage.getCreateUpdate(formData,id);
        dispatch(setActivityLocationView(response.data));
        setStatus(null)
        setDone(true)
    } catch (error) {
        let errors = error.response;
        if (errors.status === 422) {
            setStatus({error: errors.data.errors})
        }
    }
}
export const getAllActivity = () => async (dispatch) => {
    try {
        let response = await adminActivityLocationPage.getAllActivity();
        dispatch(setAllActivity(response.data));

    } catch (error) {
    }
}


export default adminActivityLocation_Reducer