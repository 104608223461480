import { activityLocationAPI } from '../api/api'

const SET_ACTIVITY_LOCATION_LIST =
  'tour2sky-project/activity/location/SET_ACTIVITY_LOCATION_LIST'
const SET_ACTIVITY_LOCATION_VIEW =
  'tour2sky-project/activity/location/SET_ACTIVITY_LOCATION_VIEW'
const SET_ACTIVITY_LOCATION_NO_PAGE =
  'tour2sky-project/activity/location/SET_ACTIVITY_LOCATION_NO_PAGE'

let initialState = {
  activityLocationList: [],
  activityLocationView: {},
  activityLocationViewServices: [],
  noPage: false,
}

const activityLocation_Reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVITY_LOCATION_LIST: {
      return {
        ...state,
        activityLocationList: action.data.data,
      }
    }
    case SET_ACTIVITY_LOCATION_VIEW: {
      return {
        ...state,
        activityLocationView: action.data,
        activityLocationViewServices: action.data?.services?.data.filter(
          s => s.status === 1
        ),
      }
    }
    case SET_ACTIVITY_LOCATION_NO_PAGE: {
      return {
        ...state,
        noPage: true,
      }
    }

    default:
      return state
  }
}

export const setActivityLocation = data => ({
  type: SET_ACTIVITY_LOCATION_LIST,
  data,
})
export const setActivityLocationView = data => ({
  type: SET_ACTIVITY_LOCATION_VIEW,
  data,
})
export const setActivityLocationNoPage = data => ({
  type: SET_ACTIVITY_LOCATION_NO_PAGE,
  data,
})

/*This is Thunk*/

export const getActivityLocationList =
  (inMenu, page, per_page) => async dispatch => {
    try {
      let response = await activityLocationAPI.getActivityLocation(
        inMenu,
        page,
        per_page
      )
      dispatch(setActivityLocation(response.data))
    } catch (e) {}
  }

export const findActivityLocationList = value => async dispatch => {
  try {
    let response = await activityLocationAPI.findActivityLocation(value)
    dispatch(setActivityLocation(response.data))

    return response.data
  } catch (e) {}
}

export const getActivityLocationSpecialLink =
  (categoryLink, currentCity) => async dispatch => {
    try {
      let response = await activityLocationAPI.getActivityLocationsPage(
        categoryLink,
        currentCity
      )
      dispatch(setActivityLocationView(response.data))
    } catch (e) {
      return setActivityLocationNoPage()
    }
  }

export default activityLocation_Reducer
