import SeoServiceSettings from "./SeoServiceSettings";
import {connect} from "react-redux";
import {getSeoSettings, getUpdateSeoSettings} from "../../../../../redux/seo_Reducer";
import {useParams} from "react-router";
import {useEffect} from "react";
import Preloader from "../../../../../assets/Preloader/Preloader";
import {getServiceStatus} from "../../../../../redux/directory_Reducer";

const SeoServiceSettingsContainer = ({getSeoSettings, isLoading,directory,getServiceStatus,...props}) => {
    const params = useParams();
    let pageId = params.pageId;
    let pageStatus =directory.indexStatus.find(item=> item.id === props.seoSettingsView.status);

    useEffect(() => {
        getServiceStatus()
        getSeoSettings(pageId)
    }, [pageId])
    if (isLoading) {
        return <Preloader/>
    }
    return <div>
        <SeoServiceSettings {...props} pageStatus={pageStatus}/>
    </div>
}
const mapStateToProps = (state) => ({
    seoSettingsView: state.seo.seoSettingsView,
    directory: state.directory,
    isLoading: state.seo.isLoading,
})
export default connect(mapStateToProps, {getUpdateSeoSettings,getSeoSettings,
    getServiceStatus})(SeoServiceSettingsContainer)