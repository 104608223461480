import DeleteIcon from '@mui/icons-material/Delete'
import FilterListIcon from '@mui/icons-material/FilterList'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Switch from '@mui/material/Switch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { alpha } from '@mui/material/styles'
import { visuallyHidden } from '@mui/utils'
import PropTypes from 'prop-types'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import Select from 'react-select'
import s from '../../../../Profiles/Admin/Admin.module.css'
import delImg from '../../../../assets/img/delimg.png'
import editImg from '../../../../assets/img/pencil.png'

const headCells = [
	{
		id: 'id',
		numeric: false,
		disablePadding: true,
		label: 'id',
	},
	{
		id: 'name',
		numeric: false,
		disablePadding: true,
		label: 'Name',
	},
	{},
]

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort } = props
	const createSortHandler = property => event => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow>
				{headCells.map(headCell => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'left'}
						padding='normal'
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component='span' sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
}

const EnhancedTableToolbar = props => {
	const { numSelected, isShowSearchBlock, createNavLink, allSearchList } = props
	let allSearchOptions
	if (isShowSearchBlock) {
		allSearchOptions = allSearchList.map(c => {
			return {
				value: `${c.id}; ${c.name}`,
				label: (
					<NavLink
						to={createNavLink + `/${c.id}`}
					>{`Id: ${c.id}; ${c.name}`}</NavLink>
				),
			}
		})
	}
	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: theme =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						),
				}),
			}}
		>
			{numSelected > 0 ? (
				<Typography
					sx={{ flex: '1 1 100%' }}
					color='inherit'
					variant='subtitle1'
					component='div'
				>
					{numSelected} selected
				</Typography>
			) : (
				<Typography
					sx={{ flex: '1 1 100%' }}
					variant='h6'
					id='tableTitle'
					component='div'
				>
					<div className={s.topPanel}>
						<div className={s.createButton}>
							{/*<NavLink to={""}>Available</NavLink>*/}
							<NavLink to={`${createNavLink}`}>Create</NavLink>
						</div>
						{isShowSearchBlock && (
							<div className={s.select}>
								<Select placeholder={'Search...'} options={allSearchOptions} />
							</div>
						)}
					</div>
				</Typography>
			)}

			{numSelected > 0 ? (
				<Tooltip title='Delete'>
					<IconButton>
						<DeleteIcon />
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title='Filter list'>
					<IconButton>
						<FilterListIcon />
					</IconButton>
				</Tooltip>
			)}
		</Toolbar>
	)
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
}

export default function TableAffiliatePrograms({
	rowsList,
	getServices,
	...props
}) {
	const dispatch = useDispatch()
	const [order, setOrder] = useState('desc')
	const [orderBy, setOrderBy] = useState('id')
	const [selected, setSelected] = useState([])
	const [page, setPage] = useState(0)
	const [dense, setDense] = useState(false)
	const [rowsPerPage, setRowsPerPage] = useState(15)
	const [openDialog, setOpenDialog] = useState(false)
	const [deleteItemId, setDeleteItemId] = React.useState('')

	const handleClickOpen = id => {
		setOpenDialog(true)
		setDeleteItemId(id)
	}

	const handleClose = () => {
		setOpenDialog(false)
	}

	useEffect(() => {
		dispatch(getServices(page, rowsPerPage, orderBy, order))
	}, [page, rowsPerPage, orderBy, order])

	const handleRequestSort = (event, property) => {
		console.log('property', property)
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
		dispatch(getServices(page, rowsPerPage, orderBy, order))
	}

	const handleSelectAllClick = event => {
		if (event.target.checked) {
			const newSelecteds = rowsList.map(n => n.name)
			setSelected(newSelecteds)
			return
		}
		setSelected([])
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
		dispatch(getServices(newPage + 1, rowsPerPage, orderBy, order))
	}

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(parseInt(event.target.value, 10))
		dispatch(getServices(page, event.target.value, orderBy, order))
	}

	const handleChangeDense = event => {
		setDense(event.target.checked)
	}

	const isSelected = name => selected.indexOf(name) !== -1

	return (
		<Box sx={{ width: '100%' }}>
			<Dialog
				open={openDialog}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>{'Really delete?'}</DialogTitle>
				<DialogActions>
					<Button onClick={handleClose}>Disagree</Button>
					<Button
						onClick={() => {
							setOpenDialog(false)
							dispatch(props.deleteButton(deleteItemId))
						}}
						autoFocus
					>
						Agree
					</Button>
				</DialogActions>
			</Dialog>
			<Paper sx={{ width: '100%', mb: 2, mt: 2 }}>
				<EnhancedTableToolbar
					selected={selected}
					numSelected={selected.length}
					createNavLink={props.createNavLink}
					isShowSearchBlock={props.isShowSearchBlock}
					allSearchList={props.allSearchList}
				/>
				<TableContainer>
					<Table
						sx={{ minWidth: 750 }}
						aria-labelledby='tableTitle'
						size={dense ? 'small' : 'medium'}
					>
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={rowsList?.length ?? 0}
						/>
						<TableBody>
							{(rowsList ?? []).map((row, index) => {
								const isItemSelected = isSelected(row.id)
								const labelId = `enhanced-table-checkbox-${index}`
								return (
									<TableRow
										hover
										role='checkbox'
										aria-checked={isItemSelected}
										tabIndex={-1}
										key={row.id}
										selected={isItemSelected}
									>
										<TableCell
											component='th'
											id={labelId}
											scope='row'
											padding='normal'
										>
											<NavLink to={`/${row.link}`}>{row.id}</NavLink>
										</TableCell>
										<TableCell align='left'>
											<NavLink to={props.createNavLink + `/${row.id}`}>
												{row.name}
											</NavLink>
										</TableCell>
										<TableCell align='right'>
											<NavLink to={props.createNavLink + `/${row.id}`}>
												<img
													className={s.editDelImg}
													src={editImg}
													alt='edit'
												/>
											</NavLink>
											<NavLink to={'#'}>
												<img
													onClick={() => {
														handleClickOpen(row.id)
													}}
													className={s.editDelImg}
													src={delImg}
													alt='del'
												/>
											</NavLink>
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					count={props.pageTotalCount}
					rowsPerPageOptions={[5, 15, 50, 100]}
					component='div'
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
			<FormControlLabel
				control={<Switch checked={dense} onChange={handleChangeDense} />}
				label='Dense padding'
			/>
		</Box>
	)
}
