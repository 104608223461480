import {adminSEO} from "../api/apiAdmin";


const SET_PAGES_LIST = "tour2sky/admin/seo/SET_PAGES_LIST";
const SET_SETTINGS_VIEW = "tour2sky/admin/seo/SET_SETTINGS_VIEW";

const SET_LOADING_ON = "tour2sky/admin/seo/SET_LOADING_ON";
const SET_LOADING_OFF = "tour2sky/admin/seo/SET_LOADING_OFF";






const initialState = {
    seoPagesList:[{
        "id":"",
        "name": "",
        "code": "",
        "status": ""
    }],
    seoSettingsView:{
        "id": "",
        "name": "",
        "code": "",
        "status":"",
        "title": "",
        "description": "",
        "image_id": "",
        "image": {
            "id": "",
            "name": "",
            "mime_type": "",
            "link": "",
            "thumb": ""
        },
        "variables": {
            "data": [
                {
                    "id": "",
                    "name": "",
                    "template": ""
                }
            ]
        }
    },
    isLoading:false,
}

const seo_Reducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_PAGES_LIST: {
            return {
                ...state,
                seoPagesList:action.data.data,
            }
        }
        case SET_SETTINGS_VIEW: {
            return {
                ...state,
                seoSettingsView:action.data,
            }
        }
        case SET_LOADING_ON: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case SET_LOADING_OFF: {
            return {
                ...state,
                isLoading: false,
            }
        }
        default:
            return state
    }
}

export const setSeoPagesList = (data) => ({type: SET_PAGES_LIST, data});
export const setSeoSettings = (data) => ({type: SET_SETTINGS_VIEW, data});
export const setIsLoadingOn = () => ({type: SET_LOADING_ON});
export const setIsLoadingOff = () => ({type: SET_LOADING_OFF});

/*This is Thunk*/
export const getSeoPagesList = () => async (dispatch) => {
    dispatch(setIsLoadingOn())
    try {
        let response = await adminSEO.getPagesList();
        dispatch(setSeoPagesList(response.data));
    } catch (e){}
    dispatch(setIsLoadingOff())
}

export const getSeoSettings = (id) => async (dispatch) => {
    dispatch(setIsLoadingOn())
    try {
    let response = await adminSEO.getSettings(id);
    dispatch(setSeoSettings(response.data));}
    catch (e){}
    dispatch(setIsLoadingOff())
}
export const getUpdateSeoSettings = (id,formData) => async (dispatch) => {
    let response = await adminSEO.getUpdate(id,formData);
    dispatch(setSeoSettings(response.data));
}


export default seo_Reducer