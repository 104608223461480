import { useEffect } from 'react'
import AdminTableAvailable from "../../../../AdminTableAvailable";
import {useSelector, useDispatch} from "react-redux";
import {getLocationsDelete, getLocationsList, getAllLocations} from "../../../../../../redux/adminGeo_Reducer";


const GeoLocationAvailable = () => {
  const dispatch = useDispatch()
  const editNavLink="/admin_cabinet/locations/locations_create";
  const createNavLink = "/admin_cabinet/locations/locations_create";

  const adminLocations = useSelector((state) =>state.geo.locationsList);
  const pagination = useSelector((state) =>state.geo.locationsListMeta.pagination);
  const getLocations = useSelector(() => getLocationsList);
  const getDeleteLocation = useSelector(() => getLocationsDelete);

  const allLocations = useSelector(state => state.geo.allLocationsList)
  const getAllRegionsList = useSelector(() => getAllLocations)

  useEffect(() => {
    dispatch(getAllRegionsList())
  }, [allLocations.length])
  return <div>
    <AdminTableAvailable rowsList={adminLocations}
                         current_page={pagination.current_page}
                         per_page={pagination.per_page}
                         pageTotalCount={pagination.total}
                         editNavLink={editNavLink}
                         deleteButton={getDeleteLocation}
                         createNavLink={createNavLink}
                         getServices={getLocations}
                         type={"locations"}
                         isShowSearchBlock={true}
                         allSearchList={allLocations}
    />
  </div>
}
export default GeoLocationAvailable