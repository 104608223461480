import s from '../../../Admin.module.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAttributesActivate,
  getAttributesBlock,
  getAttributesCreateUpdate,
  getAttributesView,
} from '../../../../../redux/adminAttributes_Reducer'
import React, { useEffect, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import style from '../../../Admin.module.css'
import Button from '@mui/material/Button'
import { useParams } from 'react-router'
import StatusSelect from '../../../../../assets/common/StatusSelect/StatusSelect'

const AttributeCreate = () => {
  const params = useParams()
  let attributeId = params.itemId
  const createUpdateAttribute = useSelector(() => getAttributesCreateUpdate)
  const getActive = useSelector(() => getAttributesActivate)
  const getBlock = useSelector(() => getAttributesBlock)
  const getViewAttribute = useSelector(() => getAttributesView)
  const attribute = useSelector(state => state.attributes.attributeView)
  const [done, setDone] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (attributeId) {
      dispatch(getViewAttribute(+attributeId))
    }
  }, [attributeId])

  let initialValues
  if (attributeId) {
    initialValues = {
      name: attribute.name || '',
    }
  } else {
    initialValues = {
      name: '',
    }
  }
  return (
    <div className={s.formContainer}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, submitProps) => {
          dispatch(
            createUpdateAttribute(
              attributeId,
              values,
              submitProps.setStatus,
              setDone
            )
          )
        }}
      >
        {({ status, values }) => {
          if (done) {
            return <div className={s.createComplete}>Create successful!</div>
          } else {
            return (
              <Form>
                <div className={style.createBlocks}>
                  <div className={style.serviceCreateItem}>
                    <label>
                      Attribute name
                      {status && status.error.name && (
                        <div className={style.error}>{status.error.name}</div>
                      )}
                      <Field name={'name'} type='text' value={values.name} />
                    </label>
                  </div>
                  {attributeId && (
                    <StatusSelect
                      active={getActive}
                      block={getBlock}
                      elementInfo={attribute}
                    />
                  )}
                </div>
                <div className={style.createBlocks}>
                  <div className={style.serviceCreateItem}>
                    <Button
                      sx={{
                        width: '20%',
                        backgroundColor: ' #295673',
                        color: ' white',
                      }}
                      type={'submit'}
                      size='small'
                    >
                      {attributeId ? `Update` : `Create`}
                    </Button>
                  </div>
                </div>
              </Form>
            )
          }
        }}
      </Formik>
    </div>
  )
}
export default AttributeCreate
