import * as React from "react";
import {Field, Form, Formik} from "formik";
import s from "../../AdminUsers/AdminUsers.module.css";
import {CardActions, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {useState} from "react";


const SeoServiceSettings = ({seoSettingsView,pageStatus,getUpdateSeoSettings}) => {

    const [editMode, setEditMode] = useState();

    return <div>
        <Box sx={{minWidth: 275, backgroundColor: "white", margin: "10px"}}>
            <React.Fragment>
                <Formik initialValues={{
                    title: '',
                    description: '',
                    image: null,
                }}
                        onSubmit={(values) => {
                            getUpdateSeoSettings(seoSettingsView.id, values)
                        }}>
                    {({values, setFieldValue}) => (
                        <Form className={s.userViewForm}>
                            <Typography sx={{fontSize: 14}} gutterBottom>
                                Name
                            </Typography>
                            <Typography sx={{fontSize: 18}} color="text.secondary" gutterBottom>
                                {!editMode && seoSettingsView.name}
                            </Typography>

                            {/*=========================================================================================*/}
                            <Typography sx={{fontSize: 14}} gutterBottom>
                                Code
                            </Typography>

                            {/*=========================================================================================*/}

                            <Typography sx={{mb: 1.5, fontSize: 18}} gutterBottom>
                                {!editMode && pageStatus?pageStatus.name:""}
                            </Typography>
                            {/*=========================================================================================*/}
                            <Typography sx={{fontSize: 14}} gutterBottom>
                                Title
                            </Typography>
                            <Typography sx={{mb: 1.5, fontSize: 18}} variant="body2">
                                {!editMode && seoSettingsView.title}
                            </Typography>
                            {editMode &&
                                <Field
                                    placeholder={seoSettingsView.title}
                                    type={"title"}
                                    name={"title"}
                                    value={values.title}/>}
                            {/*=========================================================================================*/}
                            <Typography sx={{fontSize: 14}} gutterBottom>
                                Description
                            </Typography>
                            <Typography sx={{mb: 1.5, fontSize: 18}} variant="body2">
                                {!editMode && seoSettingsView.description}
                            </Typography>
                            {editMode &&
                                <Field
                                    placeholder={seoSettingsView.description}
                                    type={"text"}
                                    name={"description"}
                                    value={values.description}/>}
                            {/*=========================================================================================*/}
                            <Typography sx={{fontSize: 14}} gutterBottom>
                                Seo image
                            </Typography>
                            <Typography sx={{mb: 1.5, fontSize: 18}} variant="body2">
                                <img src={seoSettingsView.image ? seoSettingsView.image.thumb : null}
                                     alt="photo"/>
                            </Typography>
                            {editMode &&
                                <input id="image"
                                       type="file"
                                       onChange={(event) => {
                                           setFieldValue("image", event.currentTarget.files[0]);
                                       }}/>}
                            <Button onClick={() => setEditMode(!editMode)}>{editMode?"Cancel":"Edit"}</Button>
                            {editMode && <Button type="submit">Save</Button>}
                        </Form>)}
                </Formik>
                <CardActions>

                </CardActions>
            </React.Fragment>
        </Box>
    </div>
}
export default SeoServiceSettings