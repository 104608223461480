import React, {useEffect} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {NavLink} from "react-router-dom";
import {getCount_stat} from "../../../../redux/location_Reducer";
import {useDispatch, useSelector} from "react-redux";
import s from "../../Admin.module.css";
import editImg from "../../../../assets/img/pencil.png";
import {Outlet} from "react-router";



const Locations = () => {
const getCounts = useSelector(()=>getCount_stat);
const dispatch = useDispatch();
const countsStat = useSelector((state)=>state.location.count_stat);

useEffect(()=>{
    dispatch(getCounts())
},[]);

    return <div>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell> </TableCell>
                        <TableCell align="center">counts</TableCell>
                        <TableCell align="right"> </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        </TableRow>
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            <NavLink to="countries">Countries</NavLink>
                        </TableCell>
                        <TableCell align="center">{countsStat.countriesCount}</TableCell>
                        <TableCell align="right">
                            <NavLink to="countries">
                                <img className={s.editDelImg} src={editImg} alt="edit"/>
                            </NavLink>
                            </TableCell>
                    </TableRow>
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                            <NavLink to="regions">Regions</NavLink>
                        </TableCell>
                        <TableCell align="center">{countsStat.regionsCount}</TableCell>
                        <TableCell align="right">
                            <NavLink to="regions">
                                <img className={s.editDelImg} src={editImg} alt="edit"/>
                            </NavLink>
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            <NavLink to="cities">Cities</NavLink>
                        </TableCell>
                        <TableCell align="center">{countsStat.citiesCount}</TableCell>
                        <TableCell align="right">
                            <NavLink to="cities">
                                <img className={s.editDelImg} src={editImg} alt="edit"/>
                            </NavLink>
                            </TableCell>
                    </TableRow>
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            <NavLink to="places">Places{/*this is former "Locations"*/}</NavLink>
                        </TableCell>
                        <TableCell align="center">{countsStat.locationsCount}</TableCell>
                        <TableCell align="right">
                            <NavLink to="places">
                                <img className={s.editDelImg} src={editImg} alt="edit"/>
                            </NavLink>
                        </TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        </TableContainer>
        <Outlet>Available, View, Create</Outlet>
    </div>
}
export default Locations