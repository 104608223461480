import { Checkbox } from '@mui/material'
import Button from '@mui/material/Button'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import Select from 'react-select'
import { DefaultEditor } from 'react-simple-wysiwyg'
import ChangeLocationAssistant from '../../../../../assets/common/ChangeLocationAssist/ChangeLocationAssistent'
import StatusSelect from '../../../../../assets/common/StatusSelect/StatusSelect'
import {
	getActivateActivityLocation,
	getActivityLocationView,
	getBlockActivityLocation,
	getCreateUpdateActivityLocation,
} from '../../../../../redux/adminActivityLocation_Reducer'
import { getCategoryList } from '../../../../../redux/adminCategory_Reducer'
import { getForSelectPartner } from '../../../../../redux/adminUsers&Partners_Reducer'
import {
	getAllAdvertisingActivityBottom,
	getAllAdvertisingCardService,
} from '../../../../../redux/advertising_Reducer'
import { getLocations } from '../../../../../redux/location_Reducer'
import style from '../../../Admin.module.css'

const ActivityLocationCreate = () => {
	let params = useParams()
	let locationId = params.itemId
	useEffect(() => {
		dispatch(getCategory())
		//dispatch(getLocationList())
		if (locationId) {
			dispatch(getActivityView(locationId))
		} else {
		}
	}, [locationId])
	const activityLocations = useSelector(
		state => state.adminActivity.activityLocationView
	)
	const [done, setDone] = useState(false)
	const dispatch = useDispatch()
	const directory = useSelector(state => state.directory)
	const category = useSelector(state => state.adminCategory.adminCategoryData)

	const getCategory = useSelector(() => getCategoryList)
	const getActivityView = useSelector(() => getActivityLocationView)
	const createActivity = useSelector(() => getCreateUpdateActivityLocation)
	const getActive = useSelector(() => getActivateActivityLocation)
	const getBlock = useSelector(() => getBlockActivityLocation)
	const getLocationList = useSelector(() => getLocations)
	const allAdvertisingListCardService = useSelector(
		state => state.adminAdvertising.allAdvertisingListCardService
	)
	const allAdvertisingListActivityBottom = useSelector(
		state => state.adminAdvertising.allAdvertisingListActivityBottom
	)
	const getAllAdvertisingListActivityBottom = useSelector(
		() => getAllAdvertisingActivityBottom
	)
	const getAllAdvertisingListCardService = useSelector(
		() => getAllAdvertisingCardService
	)


	useEffect(() => {
		dispatch(getAllAdvertisingListCardService())
		dispatch(getAllAdvertisingListActivityBottom())
	}, [])

	let initialValues
	if (locationId) {
		initialValues = {
			name: activityLocations.name || '',
			category_id: activityLocations.category_id || '',
			category_name: activityLocations.category_name || '',
			country_id: activityLocations.country_id || '',
			country_name: activityLocations.country_name || '',
			region_id: activityLocations.region_id || '',
			region_name: activityLocations?.region_name || '',
			city_id: activityLocations?.city_id || '',
			city_name: activityLocations?.city_name || '',
			location_id: activityLocations.location_id || '',
			location_name: activityLocations.location_name || '',
			seo_title: activityLocations.seo_title || '',
			seo_description: activityLocations.seo_description || '',
			seo_image: activityLocations.seo_image || '',
			index_status: activityLocations.index_status || 1,
			title: activityLocations.title || '',
			description: activityLocations.description || '',
			error_description: activityLocations.error_description || '',
			in_menu: +activityLocations.in_menu || 0,
			status: +activityLocations.status || 1,
			switcher_status: activityLocations?.switcher_status || false,
			banner_id: activityLocations?.banner_id || '',
			wide_banner_id: activityLocations?.wide_banner_id || '',
		}
	} else {
		initialValues = {
			name: '',
			category_id: '',
			category_name: '',
			country_id: '',
			country_name: '',
			region_id: '',
			city_id: '',
			location_id: '',
			seo_title: '',
			seo_description: '',
			seo_image: '',
			index_status: 1,
			title: '',
			description: '',
			error_description: '',
			in_menu: 0,
			banner_id: '',
			wide_banner_id: '',
		}
	}

	const categoryOptionForSelect = category.map(c => {
		return { value: c.id, label: c.name }
	})

	const nothing = {
		value: '',
		label: 'Nothing',
	}

	let allAdvertisingSearchOptionsCardService =
		allAdvertisingListCardService.map(c => {
			return {
				value: c.id,
				label: c.name,
				thumb: c.thumb,
			}
		})
	let allAdvertisingSearchOptionsActivityBottom =
		allAdvertisingListActivityBottom.map(c => {
			return {
				value: c.id,
				label: c.name,
				thumb: c.thumb,
			}
		})

	allAdvertisingSearchOptionsCardService.unshift(nothing)
	allAdvertisingSearchOptionsActivityBottom.unshift(nothing)

	const filterOptions = (candidate, input) => {
		if (input) {
			return candidate.data.label.toLowerCase().includes(input.toLowerCase())
		}
		return true
	}
	const filterOptionsBottom = (candidate, input) => {
		if (input) {
			return candidate.data.label.toLowerCase().includes(input.toLowerCase())
		}
		return true
	}

	return (
		<div className={style.formContainer}>
			<div className={style.wrapper}>
				<h4>{locationId ? 'Update' : 'Create'} Activity in location</h4>

				<Formik
					enableReinitialize
					initialValues={initialValues}
					onSubmit={(values, submitProps) => {
						dispatch(
							createActivity(values, submitProps.setStatus, setDone, locationId)
						)
					}}
				>
					{({ values, status, setFieldValue }) => {
						if (done) {
							return (
								<div className={style.createComplete}>
									<h3>Successful!</h3>
								</div>
							)
						} else {
							return (
								<div className={style.formContainer}>
									<Form>
										<div className={style.createBlocks}>
											<div className={style.serviceCreateItem}>
												<label>
													Name
													{status && status.error.name && (
														<div className={style.error}>
															{status.error.name}
														</div>
													)}
													<Field
														name={'name'}
														type='text'
														value={values.name}
													/>
												</label>
											</div>
											{locationId && (
												<StatusSelect
													active={getActive}
													block={getBlock}
													elementInfo={activityLocations}
												/>
											)}

											<div className={style.serviceCreateItem}>
												<label>
													Add to slider
													{status && status.error.in_menu && (
														<div className={style.error}>
															{status.error.in_menu}
														</div>
													)}
													<Checkbox
														checked={values.in_menu === 1 && true}
														onChange={(event, checked) => {
															if (checked) {
																setFieldValue('in_menu', 1)
															} else {
																setFieldValue('in_menu', 0)
															}
														}}
													/>
												</label>
											</div>
										</div>
										<div className={style.createBlocks}>
											<div className={style.serviceCreateItem}>
												<label>
													Select category
													{status && status.error.category_id && (
														<div className={style.error}>
															{status.error.category_id}
														</div>
													)}
													<Select
														required
														isClearable
														onChange={e => {
															setFieldValue('category_id', e?.value || NaN)
															setFieldValue('category_name', e?.label || '')
														}}
														value={[
															{
																value: values.category_id || '',
																label: values.category_name || '',
															},
														]}
														options={categoryOptionForSelect}
													/>
												</label>
											</div>
											<div className={style.serviceCreateItem}>
												<ChangeLocationAssistant
													status={status}
													setFieldValue={setFieldValue}
													city_id={values.city_id}
													city_name={values.city_name}
													region_id={values.region_id}
													region_name={values.region_name}
													country_id={values.country_id}
													country_name={values.country_name}
													location_id={values.location_id}
													location_name={values.location_name}
													isActivityLocations={true}
													switcher_status={values.switcher_status}
												/>
											</div>
										</div>
										<div className={style.createBlocks}>
											<div className={style.serviceCreateItem}>
												<label>
													Title
													{status && status.error.seo_title && (
														<div className={style.error}>
															{status.error.seo_title}
														</div>
													)}
													<Field
														name={'title'}
														type='text'
														placeholder={'Title'}
													/>
												</label>
											</div>
											<div className={style.serviceCreateItem}>
												<span>Description for page</span>
												{status && status.error.description && (
													<div className={style.error}>
														{status.error.description}
													</div>
												)}
												<DefaultEditor
													value={values.description}
													onChange={e => {
														setFieldValue('description', e.target.value)
													}}
												/>
											</div>
											<div className={style.serviceCreateItem}>
												<span>Description for error page</span>
												{status && status.error.error_description && (
													<div className={style.error}>
														{status.error.error_description}
													</div>
												)}
												<DefaultEditor
													value={values.error_description}
													onChange={e => {
														setFieldValue('error_description', e.target.value)
													}}
												/>
											</div>
										</div>
										<div className={style.createBlocks}>
											<div className={style.serviceCreateItem}>
												{status?.error && (
													<div className={style.error}>
														{status?.error[`images.0`]}
													</div>
												)}
												<label>
													Photo banner Image, formats png, jpeg, jpg.
													<input
														id='images'
														name='images'
														type='file'
														accept='.png,.jpeg,.jpg'
														onChange={event => {
															setFieldValue(
																'seo_image',
																event.currentTarget.files[0]
															)
														}}
													/>
												</label>
											</div>
											<div className={style.serviceCreateItem}>
												{locationId && (
													<div className={style.serviceCreateItemImg}>
														<img
															src={activityLocations?.seo_image?.thumb}
															alt='thumb'
														/>
													</div>
												)}
											</div>
										</div>
										<div className={style.createBlocks}>
											<div className={style.serviceCreateItem}>
												<span>SEO title</span>
												{status && status.error.seo_title && (
													<div className={style.error}>
														{status.error.seo_title}
													</div>
												)}
												<Field
													name={'seo_title'}
													type='text'
													value={values.seo_title}
													placeholder={'seo title'}
												/>
											</div>
											<div className={style.serviceCreateItem}>
												<label>
													SEO text
													{status && status.error.seo_description && (
														<div className={style.error}>
															{status.error.seo_description}
														</div>
													)}
													<Field
														name={'seo_description'}
														type='text'
														placeholder={'seo description'}
														value={values.seo_description}
													/>
												</label>
											</div>
										</div>
										<div className={style.createBlocks}>
											<div className={style.serviceCreateItem}>
												<label>
													Select Index Status
													{status && status.error.index_status && (
														<div className={style.error}>
															{status.error.index_status}
														</div>
													)}
													<select
														defaultValue={values.index_status}
														onChange={event => {
															setFieldValue(
																'index_status',
																event.currentTarget.value
															)
														}}
													>
														{directory.indexStatus.map(i => (
															<option key={i.id} value={i.id}>
																{i.name}
															</option>
														))}
													</select>
												</label>
											</div>
										</div>
										<div className={style.createBlocks}>
											<div className={style.serviceCreateItem}>
												<label>
													Chose banner 269 X 445
													<Select
														options={allAdvertisingSearchOptionsCardService}
														getOptionLabel={option => (
															<div
																style={{
																	display: 'flex',
																	alignItems: 'center',
																}}
															>
																{option.thumb && (
																	<img
																		src={option.thumb}
																		alt={option.label}
																		style={{
																			width: '30px',
																			height: '30px',
																			marginRight: '10px',
																		}}
																	/>
																)}
																{option.label}
															</div>
														)}
														styles={{
															control: (base, state) => ({
																...base,
																minWidth: '250px',
																borderColor: state.isFocused
																	? '#33cccc'
																	: '#295673FF',
																boxShadow: state.isFocused ? null : null,
																'&:hover': {
																	// Кастомні стилі при наведенні
																},
															}),
														}}
														value={allAdvertisingSearchOptionsCardService.find(
															option =>
																option.value.toString() ===
																values.banner_id.toString()
														)}
														filterOption={filterOptions}
														onChange={option => {
															setFieldValue('banner_id', option.value)
														}}
													/>
												</label>
											</div>
											<div className={style.serviceCreateItem}>
												<label>
													Chose banner 728 X 90
													<Select
														options={allAdvertisingSearchOptionsActivityBottom}
														getOptionLabel={option => (
															<div
																style={{
																	display: 'flex',
																	alignItems: 'center',
																}}
															>
																{option.thumb && (
																	<img
																		src={option.thumb}
																		alt={option.label}
																		style={{
																			width: '30px',
																			height: '30px',
																			marginRight: '10px',
																		}}
																	/>
																)}
																{option.label}
															</div>
														)}
														styles={{
															control: (base, state) => ({
																...base,
																minWidth: '250px',
																borderColor: state.isFocused
																	? '#33cccc'
																	: '#295673FF',
																boxShadow: state.isFocused ? null : null,
																'&:hover': {
																	// Custom hover styles
																},
															}),
														}}
														value={allAdvertisingSearchOptionsActivityBottom.find(
															option =>
																option.value.toString() ===
																values.wide_banner_id.toString()
														)}
														filterOption={filterOptionsBottom}
														onChange={option => {
															setFieldValue('wide_banner_id', option.value)
														}}
													/>
												</label>
											</div>
										</div>
										<div className={style.createBlocks}>
											<div className={style.serviceCreateItem}>
												<Button
													sx={{
														width: '20%',
														backgroundColor: ' #295673',
														color: ' white',
													}}
													type={'submit'}
													size='small'
												>
													{locationId ? `Update` : `Create`}
												</Button>
											</div>
										</div>
									</Form>
								</div>
							)
						}
					}}
				</Formik>
			</div>
		</div>
	)
}
export default ActivityLocationCreate
