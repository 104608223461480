import s from "../../../Admin/Admin.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getCreateUpdateAdvertising, getAdvertisingView } from "../../../../redux/advertising_Reducer";
import { useParams } from "react-router";
import { getAttributesList } from "../../../../redux/adminAttributes_Reducer";
import CreateAdvertisingVisible from "./CreateAdvertisingVisible";
import { Formik } from "formik";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "react-datepicker/dist/react-datepicker.css";

const CreateAdvertisingActivityBottom = () => {
  let params = useParams();
  let advertisingId = params.itemId;
  let initialValues;
  const dispatch = useDispatch();
  const activityLocation = useSelector(
    (state) => state.activityLocation.activityLocationList
  );
  const getCreateAdmin = useSelector(() => getCreateUpdateAdvertising);
  const getService = useSelector(() => getAdvertisingView);
  const getAttributes = useSelector(() => getAttributesList);
  const [done, setDone] = useState(false);
  const [isDeleteImage, setIsDeleteImage] = useState(false);
  const [image, setImage] = useState(null);
  const adminAdvertisingView = useSelector(
    (state) => state.adminAdvertising.advertisingView
  );

  
//   useEffect(() => {
//     setIsLocation(switcher_status);
// },[])

  let dayOfMonthOptions = [];

  const dayOptionsCreator = (options) => {
    for (let i = 1; i <= 31; i++) {
      options.push({ value: i, label: i });
    }
    return options;
  };
  dayOptionsCreator(dayOfMonthOptions);

  useEffect(() => {
    if (advertisingId) {
      dispatch(getService(advertisingId));
    }
    dispatch(getAttributes());

  }, []);

  if (advertisingId) {
    initialValues = {
      _method: "PATCH",
      name: adminAdvertisingView?.name || "",
      link: adminAdvertisingView?.link || "",
      adsence_code: adminAdvertisingView?.adsence_code || "",
      is_global: adminAdvertisingView?.is_global ? "1" : "0",
      banner_image: adminAdvertisingView?.banner_image || null,
      delete_exists_image: adminAdvertisingView?.delete_exists_image || "1",
      is_google_ads: adminAdvertisingView?.is_google_ads || "0",
    };
  } else {
    initialValues = {
        name: "",
        link: "",
        adsence_code: "",
        is_global: "0",
        banner_image: null,
        delete_exists_image: "1",
        is_google_ads: "0",
    };
  }

  if (done) {
    return <div className={s.createComplete}>Successful!</div>;
  } else {
    return (
      <div className={s.formContainer}>
        <div className={s.wrapper}>
          <h4>{advertisingId ? "Update" : "Create"} advertising</h4>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={(values, submitProps) => {
              const newFormData = new FormData();
              newFormData.append("name", values.name);
              newFormData.append("link", values.link);
              newFormData.append("adsence_code", values.adsence_code);
              advertisingId && newFormData.append("_method", "PATCH");
              newFormData.append("is_global", values.is_global);
              newFormData.append("type", "2");
              newFormData.append("is_google_ads", values.is_google_ads);
              // newFormData.append("is_google_ads", "0");
              image && newFormData.append(
                "banner_image",
                values.banner_image
              );
              isDeleteImage && newFormData.append("delete_exists_image", values.delete_exists_image);

              dispatch(
                getCreateAdmin(
                  newFormData,
                  submitProps.setStatus,
                  setDone,
                  advertisingId
                )
              );
            }}
          >
            {({ values, status, setFieldValue }) => {
              const imagesErrorFuncReturn = (status) => {
                let arrayImagesErrors = [];
                for (let i = 0; i < 10; i++) {
                  arrayImagesErrors.push(status.error[`images.${i}`]);
                }
                return arrayImagesErrors;
              };

              return (
                <CreateAdvertisingVisible
                values={values}
                status={status}
                setFieldValue={setFieldValue}
                imagesErrorFuncReturn={imagesErrorFuncReturn}
                advertisingId={advertisingId}
                setIsDeleteImage={setIsDeleteImage}
                setImage={setImage}/>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  }
};
export default CreateAdvertisingActivityBottom;
