import DeleteIcon from '@mui/icons-material/Delete'
import FilterListIcon from '@mui/icons-material/FilterList'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Switch from '@mui/material/Switch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { visuallyHidden } from '@mui/utils'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import Select from 'react-select'
import delImg from '../../../../assets/img/delimg.png'
import editImg from '../../../../assets/img/pencil.png'
import s from '../../../../Profiles/Admin/Admin.module.css'
import { getForSelectPartner } from '../../../../redux/adminUsers&Partners_Reducer'

const headCells = [
	{ id: 'id', numeric: false, disablePadding: false, label: 'ID' },
	{
		id: 'company_name',
		numeric: false,
		disablePadding: true,
		label: 'Company Name',
	},
	{ id: 'edit', numeric: true, disablePadding: false, label: '' },
]

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort } = props
	const createSortHandler = property => event => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow>
				{headCells.map(headCell => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component='span' sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	onRequestSort: PropTypes.func.isRequired,
}

const EnhancedTableToolbar = props => {
	const { numSelected, createNavLink, rowsList } = props

	const allPartners = rowsList.map(c => {
		return {
			value: c.name,
			label: <NavLink to={createNavLink + `/${c.id}`}>{c.name}</NavLink>,
		}
	})

	return (
		<Toolbar>
			{numSelected > 0 ? (
				<Typography
					sx={{ flex: '1 1 100%' }}
					color='inherit'
					variant='subtitle1'
					component='div'
				>
					{numSelected} selected
				</Typography>
			) : (
				<Typography
					sx={{ flex: '1 1 100%' }}
					variant='h6'
					id='tableTitle'
					component='div'
				>
					<div className={s.topPanel}>
						<div className={s.createButton}>
							{/*<NavLink to={""}>Available</NavLink>*/}
							<NavLink to={`${createNavLink}`}>Create</NavLink>
						</div>
						<div className={s.select}>
							<Select placeholder={'Search...'} options={allPartners} />
						</div>
					</div>
				</Typography>
			)}

			{numSelected > 0 ? (
				<Tooltip title='Delete'>
					<IconButton>
						<DeleteIcon />
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title='Filter list'>
					<IconButton>
						<FilterListIcon />
					</IconButton>
				</Tooltip>
			)}
		</Toolbar>
	)
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
	createNavLink: PropTypes.string.isRequired,
}

export default function AdminPartnersTable({
	rowsList,
	currentPage,
	perPage,
	deleteButton,
	createNavLink,
	pageTotalCount,
	getServices,
	order: initialOrder,
}) {
	const dispatch = useDispatch()

	const [order, setOrder] = useState(initialOrder || 'desc')
	const [orderBy, setOrderBy] = useState('id')
	const [selected, setSelected] = useState([])
	const [page, setPage] = useState(currentPage - 1) // МUI TablePagination використовує 0-індексацію
	const [dense, setDense] = useState(false)
	const [rowsPerPage, setRowsPerPage] = useState(perPage || 15)
	const [openDialog, setOpenDialog] = useState(false)
	const [deleteItemId, setDeleteItemId] = useState('')
	const selectPartners = useSelector(state => state.admin.selectPartners)

	useEffect(() => {
		dispatch(getForSelectPartner())
		dispatch(getServices(page + 1, rowsPerPage, order))
	}, [dispatch, page, rowsPerPage, orderBy, order, getServices])

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		const newOrder = isAsc ? 'desc' : 'asc'
		setOrder(newOrder)
		setOrderBy(property)
	}

	const handleSelectAllClick = event => {
		if (event.target.checked) {
			const newSelecteds = rowsList.map(n => n.company_name)
			setSelected(newSelecteds)
			return
		}
		setSelected([])
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = event => {
		const newRowsPerPage = parseInt(event.target.value, 10)
		setRowsPerPage(newRowsPerPage)
		setPage(0)
	}

	const handleChangeDense = event => {
		setDense(event.target.checked)
	}

	const handleClickOpen = id => {
		setOpenDialog(true)
		setDeleteItemId(id)
	}

	const handleClose = () => {
		setOpenDialog(false)
	}

	return (
		<Box sx={{ width: '100%' }}>
			<Dialog
				open={openDialog}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>
					{'Ви справді хочете видалити?'}
				</DialogTitle>
				<DialogActions>
					<Button onClick={handleClose}>Скасувати</Button>
					<Button
						onClick={() => {
							setOpenDialog(false)
							deleteButton(deleteItemId)
						}}
						autoFocus
					>
						Видалити
					</Button>
				</DialogActions>
			</Dialog>
			<Paper sx={{ width: '100%', mb: 2 }}>
				<EnhancedTableToolbar
					numSelected={selected.length}
					createNavLink={createNavLink}
					rowsList={selectPartners}
				/>
				<TableContainer>
					<Table
						sx={{ minWidth: 750 }}
						aria-labelledby='tableTitle'
						size={dense ? 'small' : 'medium'}
					>
						<EnhancedTableHead
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
						/>
						<TableBody>
							{rowsList?.map((row, index) => {
								const isItemSelected = selected.indexOf(row.company_name) !== -1
								const labelId = `enhanced-table-checkbox-${index}`

								return (
									<TableRow
										hover
										role='checkbox'
										aria-checked={isItemSelected}
										tabIndex={-1}
										key={row.id}
										selected={isItemSelected}
									>
										<TableCell align='left'>{row.id}</TableCell>
										<TableCell align='left'>{row.name}</TableCell>
										<TableCell align='right'>
											<NavLink to={`${createNavLink}/${row.id}`}>
												<img
													className={s.editDelImg}
													src={editImg}
													alt='edit'
												/>
											</NavLink>
											<button
												onClick={() => handleClickOpen(row.id)}
												style={{
													background: 'none',
													border: 'none',
													padding: 0,
													cursor: 'pointer',
												}}
											>
												<img className={s.editDelImg} src={delImg} alt='del' />
											</button>
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					component='div'
					count={pageTotalCount}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					rowsPerPageOptions={[5, 10, 15, 25]}
				/>
			</Paper>
			<FormControlLabel
				control={<Switch checked={dense} onChange={handleChangeDense} />}
				label='Щільний режим'
			/>
		</Box>
	)
}

AdminPartnersTable.propTypes = {
	rowsList: PropTypes.array.isRequired,
	current_page: PropTypes.number.isRequired,
	per_page: PropTypes.number.isRequired,
	deleteButton: PropTypes.func.isRequired,
	createNavLink: PropTypes.string.isRequired,
	pageTotalCount: PropTypes.number.isRequired,
	getServices: PropTypes.func.isRequired,
}

export const createdAt = createdAt => {
	const date = new Date(createdAt)

	const pad = number => {
		return number < 10 ? '0' + number : number
	}

	const day = pad(date.getDate())
	const month = pad(date.getMonth() + 1)
	const year = date.getFullYear()
	const hours = pad(date.getHours())
	const minutes = pad(date.getMinutes())

	return `${day}-${month}-${year} ${hours}:${minutes}`
}
