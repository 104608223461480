import {adminCategory} from "../api/apiAdmin";


const SET_CATEGORY_DATA = "tour2sky/admin/category/SET_CATEGORY_DATA";
const SET_CATEGORY_VIEW = "tour2sky/admin/category/SET_CATEGORY_VIEW";
const SET_DELETE_CATEGORY = "tour2sky/admin/category/SET_DELETE_CATEGORY";
const SET_LOADING_ON = "tour2sky/admin/category/SET_LOADING_ON";
const SET_LOADING_OFF = "tour2sky/admin/category/SET_LOADING_OFF";


const initialState = {

    adminCategoryData: [],
    adminCategoryMeta:{pagination:{total:1,current_page:1,per_page:15}},
    adminCategoryView:{},
    isLoading:false,
}


const adminCategory_Reducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_CATEGORY_DATA: {
            return {
                ...state,
                adminCategoryData:action.data.data,
                adminCategoryMeta:action.data.meta,
                }
            }
        case SET_CATEGORY_VIEW: {

            return {
                ...state,
                adminCategoryView:action.data,
            }
        }
        case SET_DELETE_CATEGORY: {

            return {
                ...state,
                adminCategoryData:state.adminCategoryData.filter(item=>item.id!==action.data)
            }
        }
        case SET_LOADING_ON: {
            return {
                ...state,
                isLoading:true,
            }
        }
        case SET_LOADING_OFF: {
            return {
                ...state,
                isLoading:false,
            }
        }
        default:
            return state
    }
}

export const setCategoryData = (data) => ({type: SET_CATEGORY_DATA, data});
export const setCategoryView = (data) => ({type: SET_CATEGORY_VIEW, data});
export const setDeleteCategoryData = (data) => ({type: SET_DELETE_CATEGORY,data});
export const setIsLoadingOn = () => ({type: SET_LOADING_ON});
export const setIsLoadingOff = () => ({type: SET_LOADING_OFF});



/*This is Thunk*/

export const getCategoryList = (page,perPage,order) => async (dispatch) => {
    dispatch(setIsLoadingOn())
    let response = await  adminCategory.getCategoryList(page,perPage,order);
    dispatch(setCategoryData(response.data))
    dispatch(setIsLoadingOff())
}
export const getCategoryView = (categoryId) => async (dispatch) => {
    dispatch(setIsLoadingOn())
    try {
        let response = await  adminCategory.getCategoryView(categoryId);
        dispatch(setCategoryView(response.data))
    } catch (e) {

    }
    dispatch(setIsLoadingOff())
}
export const getCategoryCreateUpdate = (formData, setStatus, setDone, categoryId) => async (dispatch) => {
    try {
        let response = await  adminCategory.getCreateUpdate(formData,categoryId);
        dispatch(setCategoryView(response.data))
        setDone(true)
        setStatus(null)
    } catch(error) {
        let errors = error.response;
        if(errors.status===422){
            setStatus({error: errors.data.errors})}
    }
}
export const getCategoryActivate = (categoryId) => async (dispatch) => {
    let response = await  adminCategory.getActivate(categoryId);
    dispatch(setCategoryView(response.data))
}
export const getCategoryBlock = (categoryId) => async (dispatch) => {
    let response = await  adminCategory.getBlock(categoryId);
    dispatch(setCategoryView(response.data))
}
export const getCategoryDelete = (categoryId) => async (dispatch) => {
    try{
         await  adminCategory.getDelete(categoryId);
        dispatch(setDeleteCategoryData(categoryId))
    } catch (error){
        console.log(error)
    }

}


export default adminCategory_Reducer