import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
	getAffiliatePrograms,
	getDeleteAffiliate,
} from '../../../../redux/affiliate-program'
import TableAffiliatePrograms from './TableAffiliatePrograms'
import CircularProgress from "@mui/material/CircularProgress";

const AffiliateProgramsView = () => {
	const dispatch = useDispatch()
	const createNavLink = '/admin_cabinet/affiliate-programs-view/affiliate_create'
	const getDelete = useSelector(() => getDeleteAffiliate)
	const allPrograms = useSelector(state => state.affiliate.programs)
	const metaPrograms = useSelector(state => state.affiliate.metaPrograms)
	const getAllPrograms = useSelector(() => getAffiliatePrograms)

	useEffect(() => {
		dispatch(getAllPrograms(1, 15, 'id', 'desc'))
	}, [dispatch])


	return (
		<div>
			{
				allPrograms.length ? (
					<TableAffiliatePrograms
						rowsList={allPrograms}
						getServices={getAllPrograms}
						deleteButton={getDelete}
						createNavLink={createNavLink}
						pageTotalCount={metaPrograms?.pagination?.total}
						type={'service'}
						isShowSearchBlock={true}
						allSearchList={allPrograms}
					/>
				) : <CircularProgress/>
			}

		</div>
	)
}
export default AffiliateProgramsView
