import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	getDeletePartner,
	getPartnersList,
} from '../../../../../redux/adminUsers&Partners_Reducer'
import AdminPartnersTable from '../AdminPartnersTable'

const PartnerViewContainer = () => {
	const dispatch = useDispatch()

	const partnersList = useSelector(state => state.admin.adminPartnerListData)
	const pagination = useSelector(
		state => state.admin.adminPartnerListMeta.pagination
	)

	const getDelete = useCallback(
		id => {
			dispatch(getDeletePartner(id))
		},
		[dispatch]
	)

	const createNavLink = '/admin_cabinet/partners/create'


	return (
		<AdminPartnersTable
			rowsList={partnersList}
			getServices={getPartnersList}
			deleteButton={getDelete}
			createNavLink={createNavLink}
			pageTotalCount={pagination?.total}
			currentPage={pagination?.current_page}
			perPage={pagination?.per_page}
			order={pagination?.order || 'desc'}
		/>
	)
}

export default PartnerViewContainer
