import React, {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import s from "../../../../Admin.module.css";
import Button from "@mui/material/Button";
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {
    getCitiesActivate, getCitiesBlock,
    getCitiesCreateUpdate,
    getCitiesView,
} from "../../../../../../redux/adminGeo_Reducer";
import {getCountry, getRegion} from "../../../../../../redux/location_Reducer";
import StatusSelect from "../../../../../../assets/common/StatusSelect/StatusSelect";
import style from "../../../../Admin.module.css";
import Select from "react-select";


const GeoCitiesCreate = () => {
    let params = useParams();
    let cityId=params.citiesId;
    const dispatch = useDispatch();
    const [done, setDone] = useState(false);
const createCityUpdate = useSelector(()=>getCitiesCreateUpdate);
const getCity = useSelector(()=>getCitiesView);
const cityInfo = useSelector((state)=>state.geo.citiesView);

    const getCountryList = useSelector(()=>getCountry);
    const getRegionList = useSelector(()=>getRegion);

    const getActive = useSelector(()=>getCitiesActivate);
    const getBlock = useSelector(()=>getCitiesBlock);

    const location = useSelector((state)=>state.location);
    const directory = useSelector((state)=>state.directory);


    useEffect(() => {
        if(cityId){
        dispatch(getCity(cityId));
        }
        dispatch(getCountryList())
    }, [cityId])

let initialValues;
    if(cityId){


        initialValues= {
            name:cityInfo?.name || "",
            region_id:cityInfo?.region_id || "",
            region_name:cityInfo?.region_name || "",
            country_id:cityInfo?.country_id || "",
            country_name: cityInfo?.country_name || "",
            seo_title: cityInfo?.seo_title ||"",
            seo_description:cityInfo?.seo_description || "",
            seo_image:cityInfo?.seo_image ||"",
            index_status:cityInfo?.index_status || 1,
        }
    }
    else {
        initialValues= {
            name: "",
            region_id: "",
            region_name:"",
            country_id:"",
            country_name:"",
            seo_title: "",
            seo_description: "",
            seo_image: "",
            index_status: 1,
        }
    }

    return <div className={s.formContainer}>
        <div className={s.wrapper}>
            <h4>{cityId?"Update":"Create"} City</h4>
    <Formik enableReinitialize initialValues={initialValues}
                   onSubmit={(values, submitProps) => {
                           dispatch(createCityUpdate(values,submitProps.setStatus,setDone,cityId));
                   }}>
        {({values,status, setFieldValue}) => {
            if(done){
                return <div className={s.createComplete}>
                    Successful!
                </div>} else {
                return (
                    <Form>
                        <div className={s.formContainer}>
                            <div className={s.createBlocks}>
                                <div className={s.serviceCreateItem}>
                            <label> City name
                            {status && status.error.name &&
                                <div className={s.error}>{status.error.name}</div>}
                            <Field value={values.name} name={"name"} type="text" placeholder={"name"}/></label>
                                </div>

                                <div className={s.serviceCreateItem}>
                                    {cityId &&
                            <label>Slug
                            {status && status.error.name &&
                                <div className={s.error}>{status.error.name}</div>}
                            <Field name={"slug"} type="text" value={cityInfo?.link} placeholder={"slug"}/></label>}
                                </div>
                                {cityId &&
                                    <StatusSelect active={getActive} block={getBlock} elementInfo={cityInfo}/>
                                }
                        </div>
                            <div className={s.createBlocks}>
                                <div className={s.serviceCreateItem}>
                                    <label>Image, formats png, jpeg, jpg.
                                    <input id="images"
                                           name="images"
                                           type="file"
                                           accept=".png,.jpeg,.jpg"
                                           onChange={(event) => {
                                               setFieldValue("seo_image", event.currentTarget.files[0]);
                                           }}/></label>
                                </div>
                                <div className={style.serviceCreateItem}>
                                    {cityId &&
                                        <div className={style.serviceCreateItemImg}>
                                            <img src={cityInfo?.seo_image?.thumb} alt="thumb"/>
                                        </div>}
                                </div>
                            </div>
                            <div className={s.createBlocks}>
                                <div className={s.serviceCreateItem}>
                                    <label>Select Country
                                        <Select isClearable onChange={(event) => {
                                            setFieldValue("country_id",  event?.value || "");
                                            setFieldValue("country_name",event?.label || "");
                                            dispatch(getRegionList(event.value));
                                        }}  options={location.country.map(c=>{
                                            return {value:c.id,label:c.name}})}
                                        value={[{value: values.country_id, label: values.country_name}]}/>
                                    </label>

                                </div>
                                <div className={s.serviceCreateItem}>
                                    <label> Select region
                                        {status && status.error.region_id &&
                                            <div className={style.error}>{status.error.region_id}</div>}
                                        <Select isClearable onChange={(event) => {
                                            setFieldValue("region_id",  event?.value || "");
                                            setFieldValue("region_name",event?.label || "");
                                        }} options={location.region.map(c=>{
                                            return {value:c.id,label:c.name}})}
                                                value={[{value:values.region_id,label:values.region_name ||values.region_id}]}/>
                                    </label>
                                    </div>
                            </div>
                            <div className={s.createBlocks}>
                                <div className={s.serviceCreateItem}>
                            <label>Seo tittle
                            {status && status.error.seo_title &&
                                <div className={s.error}>{status.error.seo_title}</div>}
                            <Field value={values.seo_title} name={"seo_title"} type="text" placeholder={"seo_title"}/></label>
                                </div>
                                <div className={s.serviceCreateItem}>
                            <label>Seo description
                            {status && status.error.seo_description &&
                                <div className={s.error}>{status.error.seo_description}</div>}
                            <Field value={values.seo_description} name={"seo_description"} type="text" placeholder={"seo_description"}/></label>
                            </div>
                        </div>
                            <div className={s.createBlocks}>
                                <div className={s.serviceCreateItem}>
                                    <label>Index Status
                                    {status && status.error.index_status &&
                                        <div className={s.error}>{status.error.index_status}</div>}
                                    <select defaultValue={values.index_status} onChange={(event) => {
                                        setFieldValue("index_status", event.currentTarget.value)
                                    }} >{directory.indexStatus.map(i =>
                                        <option key={i.id} value={i.id}>{i.name}</option>)}
                                    </select></label>
                                </div>
                            </div>
                            <div className={s.createBlocks}>
                                <div className={s.serviceCreateItem}>
                                    <Button type={"submit"} size="small"
                                            sx={{width:"20%",
                                                backgroundColor:" #295673",
                                                color:" white"}}>
                                        {cityId?"Update":"Create"}</Button>
                                </div>
                            </div>

                        </div>
                    </Form>)
            }}}
    </Formik>
        </div>
    </div>
}
export default GeoCitiesCreate